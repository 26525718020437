import React from "react";
import{ VideoIcon} from '../../assets/icons/icon-assets';
import settings from '../../Platform/settings.json';
import { Media } from '../../AVCore/Media';
import { Box } from 'native-base';
import { Pressable, Linking } from 'react-native';


const SimpleLinkPreview = ( { url } ) =>
{
    const thumbnailUrl = url?.orgFile?.trim()?.length > 0 ? url?.orgFile : null;

    return (
        <div className="link-preview-wrapper">
            <div className="link-preview-image">
                { thumbnailUrl ? (
                    <img src={ thumbnailUrl } alt={ "Preview" } />
                ) : (
                    <div className="link-icon-container">
                        <LinkIcon />
                    </div>
                ) }
            </div>
            <div className="link-preview-content">
                <Pressable onPress={ () => Linking.openURL( url?.linkURL ) }>
                    <p className="link-url">{ url?.linkURL }</p>
                </Pressable>
            </div>
        </div>
    );
};

const LinkIcon = () =>
{
    return (
        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="#888888">
            <path d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z" />
        </svg>
    );
};


const GridView = ( { item, setVideoStatus, setVideoItem } ) =>
{
    const [ posts, setPosts ] = React.useState<any>( [] );
    const multipleMedia : any = [];

    const VideoProcessing = ( { vid } ) =>
    {
        setVideoStatus( vid ); setVideoItem( item );
        return (
            <>
                { vid?.status === "Processing" ?
                    <div className='loader'></div> :
                    <Box position={ 'absolute' } top={ '47%' } left={'50%' }>
                        <VideoIcon size={ '60px' } color={ 'white' } />
                    </Box>
                }
            </>
        )
    }

    React.useEffect( () =>
    {
        let isMounted = true;
        if ( isMounted )
        {
            if ( item?.media?.storeId && ( item?.galleryPost?.length == 0 || item?.galleryPost?.length == undefined ) )
            {
                const posts = [
                    {
                        media: [ item?.media ],
                    },
                ];
                setPosts( posts );
            }
            else if ( item?.galleryPost?.length > 0 )
            {
                // Loop through gallery posts and add media
                for ( let i = 0; i < item?.galleryPost?.length; i++ )
                {
                    multipleMedia.push( item?.galleryPost[ i ].media );
                }
                const posts = [
                    {
                        media: multipleMedia,
                    },
                ];
                setPosts( posts );
            }
        }
        return () =>
        {
            isMounted = false; // Set the flag to false when the component is unmounted
        };

    }, [ item, item?.media, item?.galleryPost?.length ] );

    return (
        <div >
            { posts.map( ( post,index ) => (
                <div key={ post?.id + index } className="post-card">
                    <div className={ `media-grid grid-${ Math.min( post?.media?.length, 5 ) }`}>
                        { post.media.slice( 0, 5 ).map((media, index ) => (
                            <div key={ media?.id + index } className="media-item">
                                { ( media?.type?.startsWith( 'image' ) || media?.type == "Onedrive"  || media?.type === "dropbox" ) && (
                                    <img
                                        style={ { objectFit: item?.ptype === 'S' ? 'contain' : 'cover' } }
                                        src={ media?.type == "dropbox" ? media?.orgFile : media?.linkURL || Media.getURL( media ) }
                                        alt=""
                                    />
                                ) }
                                { media?.type.startsWith( 'application' ) && (
                                    <img src={
                                        ( media?.type === 'application/pdf' && settings.pdf ) ||
                                        ( ( media?.type === 'application/msword' || media?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ) && settings.word ) ||
                                        ( ( media?.type === 'application/vnd.ms-powerpoint' || media?.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ) && settings.powerpoint ) ||
                                        ( ( media?.type === 'application/vnd.ms-excel' || media?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ) && settings.excel ) ||
                                        ( ( media?.type === 'text/plain' || media?.type === 'text/plain' ) && settings.file ) ||
                                        settings.file
                                    } alt="" />
                                ) }
                                { media.type?.startsWith( 'video' ) && media?.linkURL && (
                                    <video controls>
                                        <source src={ media?.linkURL } type="video/mp4" />
                                    </video>
                                ) }
                                { media?.type === "iframe" && (
                                    <iframe
                                        src={ media?.linkURL }
                                        title="Embedded content"
                                        frameBorder="0"
                                        allowFullScreen
                                    ></iframe>
                                ) }
                                { media.type?.startsWith( 'video' ) && !media?.linkURL && (
                                    <div className="processing-container">
                                        <img src={ Media.getVideoPicURL( media ) } alt="Processing" />
                                        <VideoProcessing vid={ media } />
                                    </div>
                                ) }
                                { media.type === "linkPreview" && (
                                    <SimpleLinkPreview url={ media } />
                                ) }
                                { index === 4 && post?.media?.length > 5 && (
                                    <div className="media-overlay">
                                        +{ post?.media?.length - 5 }
                                    </div>
                                ) }
                            </div>
                        ) ) }
                    </div>
                </div>
            ) ) }
        </div>
    );
};


export default GridView;