import { useState, useEffect, useCallback } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { setRemainingEntities, setRemainingPosts, setFetchProcess, setFeeds } from '../Redux/Actions/feedsAction';
import { setLastFeedUpdate } from '../Redux/Actions/userActions';
import _ from 'lodash';
import * as AV from '../AVCore/AVCore';
import { store } from '../Redux/store';

interface ILastFeedUpdate {
    feed: AV.IFeed;
    lastGetFeedApiCall: Date;
    category: string;
}


// Custom hook to manage feeds
const useFeeds = (props: any) => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state: any) => state.user.user[0]);
    const userFeeds: AV.IPost[] = useSelector((state: any) => state.feeds.feeds[0]) ?? [];
    const remainingEntitiesInRedux = useSelector((state: any) => state.feeds.remainingEntities) ?? [];
    const remainingPostsInRedux = useSelector((state: any) => state.feeds.remainingPosts) ?? [];
    const fetchProcess = useSelector((state: any) => state.feeds.fetchProcess);
    const [processedAllPosts, setProcessedAllPosts] = useState(false);
    const [isFeedsLoading, setIsFeedsLoading] = useState(false);
    const [isFetchingPosts, setIsFetchingPosts] = useState(false); // New flag to track fetch process

    // Fetch posts based on the current page
    // We have 2 scenarios to fetch feeds
    // 1. Without having any cache data, first time login into the app we need to fetch posts for the current user and their entities
    // 2. With having cache data, we need get eligible posts from feeds cache based on their lastUpdated date categorize them into realtime, active, intermittent, inactive, dormant

    const fetchPosts = async () => {
        if (isFetchingPosts) {
            return; // If already fetching, exit the function
        }

        setIsFetchingPosts(true); // Set the flag to indicate fetching has started
        store.dispatch(setFetchProcess(false));
        try {
            let posts: AV.IPost[] = [];
            if (props?.page === 'Home') {
                // Scenario 1: Without having any cache data

                // check if we have any posts within last 7 days in remainingPostsInRedux
                let postsWithinLast7Days = remainingPostsInRedux.filter(post => {
                    const postDate = new Date(post.date);
                    const now = new Date();
                    return now.getTime() - postDate.getTime() <= 7 * 24 * 60 * 60 * 1000;
                });

                if (postsWithinLast7Days.length > 0) {
                    // if we have posts within last 7 days, we need to fetch posts for those entities
                    posts = await fetchFeedPosts(postsWithinLast7Days.slice(0, 20));
                    // set remainingPostsInRedux to remainingPostsInRedux - postsWithinLast7Days
                    let remainingPosts = remainingPostsInRedux.filter(post => !postsWithinLast7Days.some(p => p.id === post.id));
                    dispatch(setRemainingPosts(remainingPosts));
                }

                // if we have no posts within last 7 days, we need to fetch posts for all entities
                if (remainingEntitiesInRedux.length > 0 || !fetchProcess) {
                    posts = await CombineAllEntities(currentUser); // Combine all entities if no recent posts
                } else if (remainingPostsInRedux.length > 0) {
                    posts = await processFeedsInRedux(20, remainingPostsInRedux); // Process remaining posts
                } else {
                    store.dispatch(setFetchProcess(true));
                    setProcessedAllPosts(true); // Mark all posts as processed
                }
            }
            else if ( props?.page === 'Profile' )
            {
                const filteredEntities = remainingEntitiesInRedux.filter((entity) => entity.Entity.id === props?.id);
                const filteredPostsInRedux = remainingPostsInRedux.filter((x) => x.owner.id === props?.id);

                if (filteredEntities.length > 0) {
                    console.log('Fetch Feeds Posts -4');
                    posts = await fetchFeedPosts(filteredEntities); // Fetch posts for the filtered entities
                    const updatedEntities = remainingEntitiesInRedux.filter((entity) => entity.Entity.id !== props?.id);
                    dispatch(setRemainingEntities(updatedEntities)); // Update remaining entities in Redux
                } else if (filteredPostsInRedux.length > 0) {
                    posts = await processFeedsInRedux(20, filteredPostsInRedux); // Process filtered posts
                } else {
                    if (!isFeedsLoading) {
                        console.log('Props Detail on UseFeeds Else Part', props);
                        let uniquePosts: AV.IPost[] = _.uniqBy(userFeeds, 'id');
                        let currentEntityId = props?.id;
                        if (props?.userInfo?.series)
                            currentEntityId = props?.userInfo?.series;
                        uniquePosts = uniquePosts?.filter((x) => x?.owner?.id === currentEntityId);

                        if (uniquePosts.length === 0) {
                            let objEntity = {
                                Entity: props?.userInfo,
                                Type: props?.entityType,
                                FollowerList: [],
                            };
                            console.log('Fetch Feeds Posts -5');
                            setIsFeedsLoading(true);
                            posts = await fetchFeedPosts([objEntity as AV.IAVEntity]); // Fetch posts for the user info entity
                            if (posts.length == 0) {
                                store.dispatch(setFetchProcess(true));
                                setProcessedAllPosts(true); // Mark all posts as processed if none are found
                            }
                        } else {
                            store.dispatch(setFetchProcess(true));
                            setProcessedAllPosts(true); // Mark all posts as processed if unique posts are found
                        }
                    } else {
                        store.dispatch(setFetchProcess(true));
                        setProcessedAllPosts(true); // Mark all posts as processed
                    }
                }
            }

            let returnData = list_to_tree(posts); // Convert flat list to tree structure

            returnData = returnData.sort((a, b) => {
                const dateA = new Date(a?.createDate).getTime();
                const dateB = new Date(b?.createDate).getTime();
                return dateB - dateA; // Sort posts by creation date in descending order
            });

            // Dispatch the new posts to Redux, appending them to existing posts
            dispatch(setFeeds([...userFeeds, ...returnData.map(post => ({
                ...post,
                IsVisible: false // Set IsVisible to false by default
            }))]));

            return returnData || []; // Return the fetched posts or an empty array
        } catch (error) {
            console.warn('Error fetching posts:', error);
            return []; // Return an empty array in case of error
        } finally {
            setIsFetchingPosts(false); // Reset the flag when done
        }
    };

    // Function to load new posts
    const fetchNewPosts = async () => {
        if (isFetchingPosts) {
            return; // If already fetching, exit the function
        }

        setIsFetchingPosts(true); // Set the flag to indicate fetching has started
        let posts: AV.IPost[] = [];
        //@ts-ignore - todo: fix this type error for lastUpdateFeed
        const lastUpdateFeed = store.getState().user?.lastUpdateFeed ?? [];
        try {
            // Get last feed update from redux  
            const feedToFetch: ILastFeedUpdate[] = [];
            // Rules for which feeds to be applied for all feeds

            //Realtime feeds: post in the last hour 
            //Refresh on demand 

            let realtimeFeedsToFetch = lastUpdateFeed.filter(x => x.category === 'realtime');
            feedToFetch.push(...realtimeFeedsToFetch);

            //Active feeds: post in the last day
            //Get on refresh no more than once per hour without alert

            let activeFeedsToFetch = lastUpdateFeed.filter(x => x.category === 'active' && new Date(x.lastGetFeedApiCall).getTime() > new Date().getTime() - 1 * 60 * 60 * 1000); // get feeds in the last hour
            feedToFetch.push(...activeFeedsToFetch);
            //intermittent feeds: post more than a day but less than a week  
            //Get on refresh no more than once per 2 hours without alert   

            let intermittentFeedsToFetch = lastUpdateFeed.filter(x => x.category === 'intermittent' && new Date(x.lastGetFeedApiCall).getTime() > new Date().getTime() - 2 * 60 * 60 * 1000); // get feeds in the last 2 hours
            feedToFetch.push(...intermittentFeedsToFetch);
            //Inactive feeds: post more than a week ago and less than a month.   
            //Get on refresh no more than once or 24 hours 

            let inactiveFeedsToFetch = lastUpdateFeed.filter(x => x.category === 'inactive' && new Date(x.lastGetFeedApiCall).getTime() > new Date().getTime() - 24 * 60 * 60 * 1000); // get feeds in the last 24 hours
            feedToFetch.push(...inactiveFeedsToFetch);

            //Dormant feeds: post more than 1 month ago  
            //Do not refresh from home feed, only on entity page, unless alert 


            // Fetch posts based on the categorized feeds
            let posts = await fetchFeedPosts(feedToFetch, true);

            return posts; // Return the fetched posts   

        } catch (error) {
            console.warn('Error fetching new posts:', error);
            return []; // Return an empty array in case of error
        } finally {
            setIsFetchingPosts(false); // Reset the flag when done
        }
    };

    // Function to combine all entities for the current user
    const CombineAllEntities = async (currentUser: AV.IPerson) => {
        let updatedEntitiesInRedux = store.getState().feeds as { remainingEntities?: any[] };
        let combinedEntities = _.cloneDeep(updatedEntitiesInRedux?.remainingEntities) || [];

        if (combinedEntities.length === 0) {
            console.log('Combining all entities...');

            // Add entities in the specified order
            const eventLinks: AV.IEventLink[] = currentUser?.events || [];
            const now = new Date();
            const fiveDaysFromNow = new Date(now);
            fiveDaysFromNow.setDate(now.getDate() - 5); // past 5 days from now

            // Filter event links to include only those with an end date within the last 5 days
            const filteredEventLinks = eventLinks.filter(event => {
                const endDate = new Date(event.end); // Assuming 'end' is the property for the end date
                return endDate >= fiveDaysFromNow; // Include only if the end date is after 5 days
            });

            // Convert filtered event links to AV entities
            const avEntities: AV.IAVEntity[] = filteredEventLinks.map(convertEventLinkToAVEntity);
            addEntitiesToCombined(avEntities, 'Events', combinedEntities); // Add event entities to combined list

            // Add favorites to combined entities
            const favs = currentUser?.favs || []; // Get favorites from currentUser
            const favEntities: AV.IAVEntity[] = favs.map(fav => ({ id: fav.id, status: fav.status || undefined })); // Assuming fav has id and status
            addEntitiesToCombined(favEntities, 'Favorites', combinedEntities); // Add favorite entities to combined list

            // Add members to combined entities
            const members = currentUser?.members?.map(member => ({ ...member, status: member.status || undefined })) || [];
            addEntitiesToCombined(members, 'Members', combinedEntities);

            // Add organizations to combined entities
            const orgs = currentUser?.orgs?.map(org => ({ ...org, status: org.status || undefined })) || [];
            addEntitiesToCombined(orgs, 'Orgs', combinedEntities);

            // Add the current user as a person entity
            combinedEntities.push(createEntityObject(currentUser, 'Person'));
            combinedEntities = _.uniqBy(combinedEntities, (entity: { Entity?: { id: string } }) => entity.Entity?.id); // Ensure unique entities

            // Add follows to combined entities
            const follows = currentUser?.follows?.map(follow => ({ ...follow, status: follow.status || undefined })) || [];
            addEntitiesToCombined(follows, 'Follows', combinedEntities);

            // Add auto-follows to combined entities
            const autoFollows = currentUser?.autoFollows?.map(follow => ({ ...follow, status: follow.status || undefined })) || [];
            addEntitiesToCombined(autoFollows, 'AutoFollows', combinedEntities);

            // Ensure unique entities by ID at the end
            combinedEntities = _.uniqBy(combinedEntities, (entity: { Entity?: { id: string } }) => entity.Entity?.id); // Ensure unique entities

            // Fetch posts based on the combined entities
            try {
                console.log('Fetch Feeds Posts -1');
                const posts = await fetchFeedPosts(combinedEntities.slice(0, 20)); // Fetch the first 20 posts
                const remainingEntities = combinedEntities.slice(20); // Get remaining entities
                store.dispatch(setRemainingEntities(remainingEntities)); // Update Redux state with remaining entities
                store.dispatch(setFetchProcess(false)); // Set fetch process to true

                return posts; // Return fetched posts
            } catch (error) {
                console.warn('Error fetching feed posts:', error);
                store.dispatch(setFetchProcess(false)); // Set fetch process to false on error
                return [];
            }
        }

        // Fetch posts if combinedEntities already exists
        try {
            console.log('Fetch Feeds Posts -2');
            const posts = await fetchFeedPosts(combinedEntities.slice(0, 20)); // Fetch the first 20 posts
            const remainingEntities = combinedEntities.slice(20); // Get remaining entities
            store.dispatch(setRemainingEntities(remainingEntities)); // Update Redux state with remaining entities

            return posts; // Return fetched posts
        } catch (error) {
            console.warn('Error fetching feed posts:', error);
            return []; // Return an empty array on error
        }
    };

    // Function to fetch posts for the given entities
    const fetchFeedPosts = async (entities: any, isCacheData: boolean = false) => {
        let feedParams = [];

        if (!isCacheData) {
            feedParams = entities.map((entity: any) => {
                return {
                    feed: {
                        id: entity?.Entity?.id ? entity?.Entity?.id : entity.owner?.id,
                        pk: entity?.Entity?.id ? entity?.Entity?.id : entity.owner?.id,
                        type: 'Feed'
                    },
                    startDate: new Date(0),
                    forward: true,
                    checkArchive: false,
                };
            }).filter(param => param !== null);
        }
        else { // if cache data is true, we need to fetch feeds based on lastUpdated date
            feedParams = entities.map((entity: any) => {
                return {
                    feed: {
                        id: entity.feed.id,
                        pk: entity.feed.id,
                        type: 'Feed'
                    },
                    startDate: entity.feed.lastUpdated,
                    forward: true,
                    checkArchive: false,
                };
            }).filter(param => param !== null);
        }

        try {
            console.log('Get Feed from UseFeeds.tsx -1');
            let feeds = await AV.Feed.getAllFeeds(feedParams);


            console.log('Fetched feeds:', feeds);

            // always categorize feeds once we get feeds from api
            let categorizedFeeds = categorizeFeeds(feeds);

            // Determine if we should include dormant feeds based on the current page
            const includeDormant = props?.page === 'Profile';
            const allFeeds = getCategorizedFeeds(categorizedFeeds, includeDormant);

            // Collect all post IDs from all feeds
            let allPosts: { id: string; date: string; owner: AV.IFK; ownerType: string | null }[] = [];

            allFeeds.forEach(feed => {
                const feedFK = AV.AVEntity.getFk(feed);
                const currentEntityPostIds = _.uniqBy([
                    ...(feed?.followIds || []),
                    ...(feed?.privateIds || []),
                    ...(feed?.publicIds || [])
                ], 'id');

                // Find the corresponding ownerType from entities
                const ownerType = isCacheData ? entities.find(entity => entity.feed.id === feedFK.id)?.feed.type : entities.find(entity => entity.Entity.id === feedFK.id)?.type;

                currentEntityPostIds.forEach(postId => {
                    if (postId.id && postId.date) {
                        allPosts.push({
                            id: postId.id,
                            date: new Date(postId.date ?? new Date()).toISOString(),
                            owner: feedFK,
                            ownerType: ownerType || null
                        });
                    } else {
                        console.warn('Post ID or date is missing:', postId);
                    }
                });
            });

            // Call the common function to process grouped postids by owner
            const groupedPostIds = await groupPosts(allPosts);

            // Call prioritizePosts with the correctly formatted input
            // this will return only 20 postids, whether 20 in same feed or different feed based on post date in last 7 days
            // remaining postids are set in redux for next time use 
            const prioritizedPosts = await prioritizePosts(groupedPostIds);

            // Prepare posts to fetch based on prioritized posts
            let postsToFetch: AV.IPostListParam[] = [];
            for (const ownerId in prioritizedPosts) {
                const { owner, postIds } = prioritizedPosts[ownerId];
                postsToFetch.push({ feed: owner, ids: postIds.map(post => post.id) });
            }

            if ( postsToFetch?.length > 0 )
            {
                // Fetch all posts based on the prepared list
                const posts = await AV.Feed.getAllPosts( postsToFetch ); // Get remaining posts           

                return posts; // Return the fetched posts
            }
            else
            {
                return [];
            }
        } catch (error) {
            console.warn('Error fetching feed for entities:', entities, error);
            return [];
        }
    };

    // Process feeds for a specific owner and return the posts
    const processFeedsInRedux = async (count: number, remainingPostsInRedux: any[]) => {
        console.log("Processing remaining posts...");

        // Sort remaining posts by date in descending order
        const sortedRemainingPostsInRedux = remainingPostsInRedux.sort((a, b) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime(); // Ensure sorting by date descending
        });

        // Get the top posts based on the count
        const topPosts = sortedRemainingPostsInRedux.slice(0, count);

        // Group posts using the common function
        const groupedPosts = await groupPosts(topPosts);

        // Prepare posts to fetch based on groupedPosts
        let postsToFetch: AV.IPostListParam[] = [];
        for (const ownerId in groupedPosts) {
            const { owner, postIds } = groupedPosts[ownerId];
            postsToFetch.push({ feed: owner, ids: postIds.map(post => post.id) });
        }

        // Log the postsToFetch before making the API call
        console.log('Posts to fetch:', postsToFetch);

        // Fetch all posts based on the prepared list
        try
        {
            if ( postsToFetch?.length > 0 )
            {
                const posts = await AV.Feed.getAllPosts( postsToFetch );
                console.log( 'Fetched posts:', posts );
                const remainingPosts = remainingPostsInRedux.filter( post => !posts.some( p => p.id === post.id ) ); // Get remaining posts
                store.dispatch( setRemainingPosts( remainingPosts ) );

                return posts; // Return the fetched posts
            }
            else
            {
                return [];
            }

        } catch (error) {
            console.warn('Error fetching posts:', error);
            return []; // Return an empty array in case of error
        }
    };

    // Function to create an entity object
    const createEntityObject = (entity: AV.IPerson, type: string) => ({
        Entity: entity,
        type,
        LastPostProcessed: new Date(0),
        IsProcessed: false,
    });

    // Function to add unique entities to the combined list
    const addEntitiesToCombined = (entities: AV.IAVEntity[], type: string, combinedEntities: AV.IAVEntity[]) => {
        const uniqueEntities = _.uniqBy(entities, 'id');
        uniqueEntities.forEach((entity) => {
            combinedEntities.push(createEntityObject(entity, type));
        });
    };

    // Function to categorize feeds based on lastUpdated
    const categorizeFeeds = (feeds: AV.IFeed[]) => {
        const now = new Date();
        //@ts-ignore - todo: fix this type error for lastUpdateFeed
        const lastUpdateFeed = store.getState().user?.lastUpdateFeed ?? [];
        let feedLastFetchDataArray: ILastFeedUpdate[] = [];
        const categorizedFeeds = {
            realtime: [],
            active: [],
            intermittent: [],
            inactive: [],
            dormant: [],
        };

        feedLastFetchDataArray.push(...lastUpdateFeed);

        feeds.forEach(feed => {
            if (feed && feed.id) { // Check if feed is defined and has an id
                let category = '';           
                const lastUpdated = new Date(feed.lastUpdated ?? new Date());
                const timeDiff = now.getTime() - lastUpdated.getTime(); // Calculate the time difference in milliseconds
                const hoursDiff = timeDiff / (1000 * 60 * 60); // Convert time difference to hours
                const daysDiff = timeDiff / (1000 * 60 * 60 * 24); // Convert time difference to days

                // Categorize feeds based on the time difference
                if (hoursDiff < 1) {
                    category = 'realtime';
                    (categorizedFeeds.realtime as AV.IFeed[]).push(feed); // Feeds updated within the last hour
                } else if (hoursDiff < 24) {
                    category = 'active';
                        (categorizedFeeds.active as AV.IFeed[]) .push(feed); // Feeds updated within the last 24 hours
                } else if (daysDiff < 7) {
                    category = 'intermittent';
                    (categorizedFeeds.intermittent as AV.IFeed[]) .push(feed); // Feeds updated within the last 7 days
                } else if (daysDiff < 30) {
                    category = 'inactive';
                    (categorizedFeeds.inactive as AV.IFeed[]) .push(feed); // Feeds updated within the last 30 days
                } else {
                    category = 'dormant';
                    (categorizedFeeds.dormant as AV.IFeed[]) .push(feed); // Feeds not updated for more than 30 days
                }

                // Set last feed update in redux
                let lastFeedFetchDate = new Date();
                let feedLastFetchData: ILastFeedUpdate = { feed: feed, lastGetFeedApiCall: lastFeedFetchDate, category: category };
                // Check if feedLastFetchData is already in feedLastFetchDataArray
                const index = feedLastFetchDataArray.findIndex(x => x.feed && x.feed.id === feed.id); // Check if x.feed is defined
                if (index !== -1) {
                    feedLastFetchDataArray[index] = feedLastFetchData;
                } else {
                    feedLastFetchDataArray.push(feedLastFetchData);
                }
            } else {
                console.warn('Feed is undefined or missing ID:', feed);
            }
        });

        // Set last feed update in redux
        store.dispatch(setLastFeedUpdate(feedLastFetchDataArray));

        return categorizedFeeds; // Return the categorized feeds
    };

    const groupPosts = async (allPosts: { id: string; date: string; owner: AV.IFK; ownerType: string | null }[]) => {
        let groupedPostIds: { [key: string]: { owner: any; postIds: { id: string; date: string }[]; ownerType: string } } = {};
        let previousOwnerId = "";

        // Sort all posts by date in descending order
        allPosts.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        allPosts.forEach(post => {
            const { owner, ownerType } = post;
            const ownerId = owner.id;

            // If the ownerId changes, create a new entry in allPostIds
            if (ownerId !== previousOwnerId) {
                if (ownerId && !groupedPostIds[ownerId]) {
                    groupedPostIds[ownerId] = { owner: owner, postIds: [], ownerType: ownerType || '' }; // Set owner to feedFK
                }
                if (ownerId && groupedPostIds[ownerId]) {
                    groupedPostIds[ownerId].postIds.push({ id: post.id, date: post.date });
                    previousOwnerId = ownerId; // Update the previousOwnerId
                }
            } else {
                // If the ownerId is the same, just add the postId to the existing owner
                if (ownerId && groupedPostIds[ownerId]) {
                    groupedPostIds[ownerId].postIds.push({ id: post.id, date: post.date });
                }
            }
        });

        console.log('Collected allPostIds:', groupedPostIds);
        return groupedPostIds;
    }

    // Function to prioritize posts based on the specified criteria

    // Function to prioritize posts based on the specified criteria
    const prioritizePosts = async ( allPostIds : { [ key : string ] : { owner : any; postIds : { id : string; date : string }[]; ownerType : string } } ) =>
    {
        const prioritizedPosts : { [ key : string ] : { owner : any; postIds : { id : string; date : string }[] } } = {};
        const nonPriorityPosts : { id : string; date : string; owner : any }[] = [];
        let foundPriorityPosts = false;

        console.log( 'Input to prioritizePosts:', allPostIds );

        // Iterate through each owner in allPostIds
        for ( const ownerId in allPostIds )
        {
            const { owner, postIds } = allPostIds[ ownerId ];

            // if we got prioritizedPosts.postids.length==20, need to set remainingPostsInRedux
            if ( Object.values( prioritizedPosts ).some( x => x.postIds.length === 20 ) )
            {
                dispatch( setRemainingPosts( [ ...remainingPostsInRedux, ...postIds.map( post => ( { id: post.id, date: post.date, owner } ) ) ] ) );
                if ( props?.page === 'Profile' )
                {
                    return
                }
                else
                {

                }
                return prioritizedPosts ;
            }

            // Check if postIds is an array
            if ( !Array.isArray( postIds ) )
            {
                console.warn( `postIds for owner ${ ownerId } is not an array or is undefined:`, postIds );
                continue; // Skip to the next owner if postIds is not valid
            }

            // Sort postIds based on the date in descending order
            const sortedPostIds = postIds.sort( ( a, b ) => new Date( b.date ).getTime() - new Date( a.date ).getTime() );

            // Filter postIds based on prioritization criteria using ownerType
            const filteredPostIds : { id : string; date : string }[] = [];

            sortedPostIds.forEach( post =>
            {


                const postDate = new Date( post.date );
                const now = new Date();

                // Define prioritization logic based on ownerType and date
                let isPrioritized = false;

                if ( props?.page === 'Profile' )
                {
                    // Profile page: Always include
                    isPrioritized = true;
                } else
                {
                    // Activity in the last 1 week
                    isPrioritized = now.getTime() - postDate.getTime() <= 1 * 7 * 24 * 60 * 60 * 1000;
                }

                if ( isPrioritized )
                {
                    filteredPostIds.push( post ); // Add to filtered posts if prioritized
                } else
                {
                    // If not prioritized, add to non-priority posts
                    nonPriorityPosts.push( { id: post.id, date: post.date, owner } );
                }
            } );

            // Log filtered post IDs for debugging
            console.log( `Filtered post IDs for owner ${ ownerId }:`, filteredPostIds );

            // If there are filtered post IDs, add them to prioritizedPosts
            if ( filteredPostIds.length > 0 )
            {
                foundPriorityPosts = true; // Mark that we found priority posts

                // If filteredPostIds length is less than or equal to 20, add directly
                if ( filteredPostIds.length <= 20 )
                {
                    prioritizedPosts[ ownerId ] = { owner, postIds: filteredPostIds }; // Directly assign filtered post IDs


                } else
                {
                    // If more than 20, assign the first 20 to prioritizedPosts and move the rest to nonPriorityPosts
                    prioritizedPosts[ ownerId ] = { owner, postIds: filteredPostIds.slice( 0, 20 ) }; // Assign first 20
                    const remainingPosts = filteredPostIds.slice( 20 ); // Get remaining posts
                    nonPriorityPosts.push( ...remainingPosts.map( post => ( { id: post.id, date: post.date, owner } ) ) ); // Add remaining to nonPriorityPosts
                }
            }
        }

        // If there are non-priority posts, set them in Redux
        if ( nonPriorityPosts.length > 0 )
        {
            console.log( 'No priority posts found. Setting remaining posts in Redux.' );
            dispatch( setRemainingPosts( [ ...remainingPostsInRedux, ...nonPriorityPosts ] ) ); // Combine remaining posts with non-priority posts
        }

        return prioritizedPosts; // Return the prioritized posts
    };

    // Function to get feeds based on the current page
    const getCategorizedFeeds = (categorizedFeeds: any, includeDormant: boolean) => {
        if (includeDormant) {
            // Include dormant feeds
            return [
                ...categorizedFeeds.realtime,
                ...categorizedFeeds.active,
                ...categorizedFeeds.intermittent,
                ...categorizedFeeds.inactive,
                ...categorizedFeeds.dormant,
            ];
        } else {
            // Exclude dormant feeds
            return [
                ...categorizedFeeds.realtime,
                ...categorizedFeeds.active,
                ...categorizedFeeds.intermittent,
                ...categorizedFeeds.inactive,
            ];
        }
    };

    // Function to convert IEventLink to IAVEntity
    const convertEventLinkToAVEntity = (eventLink: AV.IEventLink): AV.IAVEntity => {
        return {
            id: eventLink.id,
            status: eventLink.status ?? undefined,
        };
    };

    // Convert a flat list to a tree structure
    const list_to_tree = (list) => {
        var map = {}, node: any, roots: any = [], i;

        for (i = 0; i < list?.length; i += 1) {
            map[list[i]?.id] = i; // initialize the map
            if (list[i]?.children?.length === 0 || !list[i]?.children)
                list[i]["children"] = []; // initialize the children
            if (list[i]?.galleryPost?.length === 0 || !list[i]?.galleryPost)
                list[i]["galleryPost"] = [];
        }

        for (i = 0; i < list?.length; i += 1) {
            node = list[i];
            if (node.parentPost !== undefined) {
                if (list[map[node.parentPost]]?.gallery?.items?.filter((x) => x === node.id)?.length > 0 && node.gallery == undefined) {
                    let excitingpost = list[map[node.parentPost]]?.galleryPost?.filter(x => x?.id === node?.id);
                    if (excitingpost?.length === 0 || excitingpost === undefined)
                        list[map[node.parentPost]]?.galleryPost.push(node);
                } else {
                    let excitingcomment = list[map[node.parentPost]]?.children?.filter(x => x?.id === node?.id);
                    if (excitingcomment?.length === 0 || excitingcomment === undefined)
                        list[map[node.parentPost]]?.children.push(node);
                }
            } else {
                roots.push(node);
            }
        }
        return roots; // Return the tree structure
    };

    return {
        fetchPosts,
        fetchNewPosts,
        processedAllPosts,
    };
};

export default useFeeds; // Export the custom hook for use in other components
