import React, { useEffect } from 'react';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { store } from '../Redux/store';
import { setNewAlerts } from '../Redux/Actions/alertActions';
import { SSE } from '../Hooks/SSE';
import { RootStateOrAny, useSelector } from 'react-redux';
import { setDeviceFcmToken, setIsNotificationEnabled } from '../Redux/Actions/userActions';
import ToastMessage from '../Hooks/useAlertToast';
import useConvoChat from '../Hooks/useConvoChat';
import { Server } from '../Platform/Server';

const firebaseConfig = {
	apiKey: "AIzaSyBUHhHXN-RIWVqo5s9SbHglTRaVjR2c6TQ",
	authDomain: "airy-shuttle-353318.firebaseapp.com",
	projectId: "airy-shuttle-353318",
	storageBucket: "airy-shuttle-353318.appspot.com",
	messagingSenderId: "148950580333",
	appId: "1:148950580333:web:b2158811c67657fcee9364",
	measurementId: "G-L4S58JWF1P",
};

const app = initializeApp( firebaseConfig );
const messaging = getMessaging( app );

// Fetch alerts on focus
export async function fetchAlerts ( isFBNotificationEnabled : boolean )
{
	await ( await SSE() ).fetchAlertsOnfocus( isFBNotificationEnabled );
}

// Fetch alerts every 30 seconds
async function fetchAlertsOnEvery30secs ( isFBNotificationEnabled : boolean )
{
	await ( await SSE() ).fetchAlertsOnEvery30secs( isFBNotificationEnabled );
}

// Add FCM token to Redux
async function addFCMTokenToRedux ( currentToken : string | undefined )
{
	store.dispatch( setDeviceFcmToken( currentToken ) );
}

const FirebaseNotifications : React.FC = React.memo( () =>
{
	const { handleNewDMAlert } = useConvoChat();
	const isFBNotificationEnabled = useSelector( ( state : RootStateOrAny ) => state.user.notificationEnabled );
	let processedAlertIds = new Set<string>();

	useEffect( () =>
	{
		try
		{
			if ( "Notification" in window )
			{
				Notification.requestPermission()
					.then( ( permission ) =>
					{
						if ( permission === 'granted' )
						{
							console.log( 'Notification permission granted.' );
							store.dispatch( setIsNotificationEnabled( true ) );
							return getToken( messaging, {
								vapidKey: 'BGEZ7msQaDou3feR7yaaWDS-0cUdaLbgSXaLvUqASYSwAeFgpNHTG95ku7Kq9MH9yChxvZKDkvKZWJ6JMea3Xe4',
							} );
						} else
						{
							console.log( 'Unable to get permission to notify.' );
							store.dispatch( setIsNotificationEnabled( false ) );
							addFCMTokenToRedux( undefined );
							fetchAlertsOnEvery30secs( isFBNotificationEnabled );
						}
					} )
					.then( ( currentToken ) =>
					{
						if ( currentToken )
						{
							console.log( 'FCM Token:', currentToken );
							store.dispatch( setIsNotificationEnabled( true ) );
							addFCMTokenToRedux( currentToken );
							fetchAlerts( isFBNotificationEnabled );
						} else
						{
							console.log( 'No registration token available. Request permission to generate one.' );
							store.dispatch( setIsNotificationEnabled( false ) );
							addFCMTokenToRedux( undefined );
						}
					} )
					.catch( ( err ) =>
					{
						console.log( 'An error occurred while retrieving token.', err );
						store.dispatch( setIsNotificationEnabled( false ) );
						addFCMTokenToRedux( undefined );
					} );
			} else
			{
				console.log( 'Notification not enabled.' );
				store.dispatch( setIsNotificationEnabled( false ) );
				addFCMTokenToRedux( undefined );
			}
		} catch
		{
			console.log( 'An error occurred while checking notification permission.' );
			store.dispatch( setIsNotificationEnabled( false ) );
			addFCMTokenToRedux( undefined );
		}

		const unsubscribe = onMessage( messaging, ( payload : any ) =>
		{
			console.log( 'Message received.', payload );

			let alertData = payload?.data?.data ? JSON.parse( payload.data.data ) : null;
			if ( alertData?.alertType )
			{
				if ( !processedAlertIds.has( alertData.id ) )
				{
					const reduxState : RootStateOrAny = store.getState();
					const { alerts, user, convo } = reduxState;

					let UserData = user?.user?.[ 0 ];
					let ResultofBlocked = UserData?.blocked?.find( ( x ) => x?.id === alertData?.data?.person?.id );
					if ( ResultofBlocked )
					{
						return;
					}

					let alertsVal = alerts.alert[ 0 ];
					const existingAlertIds = new Set( alertsVal?.map( ( alert ) => alert?.id ) );
					if ( !existingAlertIds.has( alertData.id ) )
					{
						let ToastAlert = {
							type: alertData?.alertType,
							message: payload?.notification?.body?.slice( 0, 100 ) + '...',
						};

						if ( user.isConvoScreenOpen.convoId !== alertData.target?.pk && alertData.data?.source ? alertData.data?.source?.id !== user.user[ 0 ].id : alertData.data?.person?.id !== user.user[ 0 ].id )
						{
							ToastMessage( { ToastAlert } );
						}
						store.dispatch( setNewAlerts( [ alertData ] ) );
						fetchAlerts( isFBNotificationEnabled );
					}
					processedAlertIds.add( alertData.id );

					if ( alertData?.alertType === 'NewDM' || alertData?.alertType === 'ReplyDM' )
					{
						handleNewDMAlert( alertData );
					}
				}
			} else
			{
				fetchAlerts( isFBNotificationEnabled );
			}
		} );

		return () => unsubscribe();
	}, [] );

	return null;
} );

export default FirebaseNotifications;