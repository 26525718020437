import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

function ToastMessage ({ToastAlert})
{
    if ( ToastAlert.type !== 'EntityUpdate' && ToastAlert.type !== 'ConvoUpdate' && ToastAlert.type !== 'VisChange' )
	{
		 
    toast(ToastAlert.message, {
        theme: "dark",
        hideProgressBar: true,
    } );
    }

	//switch (ToastAlert?.type)
	//{
	//    case "NewDM":
	//        toast( "You Have a New Message", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );
	//        break
	//    case "NewPost":
	//        toast( "You Have a New Post", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );
	//        break;
	//    case "UpdatePost":
	//        toast( "You Have a Update Post", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );
	//        break;
	//    case "UpdatePost":
	//        toast( "A post you're following has been updated.", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );
	//        break;

	//    case "Impression":
	//        toast( "Someone add Impression!", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } ); // Or a more specific message based on context (e.g., "Your post reached X people!")
	//        break;

	//    case "PostReview":
	//        toast( "Your post received a review!", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );
	//        break;

	//    case "PostReply":
	//        toast( "Someone replied to your post!", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );
	//        break;

	//    case "NewEvent":
	//        toast( "A new event has been created!", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );
	//        break;

	//    case "UpdateEvent":
	//        toast( "An event you're attending has been updated.", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );
	//        break;

	//    case "CancelEvent":
	//        toast( "An event you're attending has been canceled.", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );
	//        break;

	//    case "NewResource":
	//        toast( "A new resource is now available!", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );
	//        break;

	//    case "DelResource":
	//        toast( "A resource you were using has been deleted.", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );
	//        break;

	//    case "UpdResource":
	//        toast( "A resource you're using has been updated.", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );
	//        break;

	//    case "ReplyDM-Ward":
	//        toast( "You received a reply to a direct message in your ward.", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );
	//        break;

	//    case "NewDM-Ward":
	//        toast( "You have a new direct message in your ward.", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );
	//        break;

	//    case "NewDM":
	//        toast( "You have a new direct message from [sender name]!", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );  // Consider including sender name if available
	//        break;

	//    case "ReplyDM":
	//        toast( "[sender name] replied to your direct message!", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );  // Consider including sender name if available
	//        break;

	//    case "UpdateDM":
	//        toast( "A direct message you're involved in has been updated.", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );
	//        break;

	//    case "DeleteDM":
	//        toast( "A direct message you were involved in has been deleted.", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );
	//        break;

	//    case "NewConvo":
	//        toast( "A new conversation has started!", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );
	//        break;

	//    case "Block":
	//        toast( "You have blocked [username].", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );  // Consider including username if available
	//        break;

	//    case "UnBlock":
	//        toast( "You have unblocked [username].", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );  // Consider including username if available
	//        break;

	//    case "NewRequest":
	//        toast( "You have a new request!", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );  // Consider adding details about the request type if possible
	//        break;

	//    case "Follow":
	//        toast( "Someone send Follow Request", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );  // Consider including username if available
	//        break;

	//    case "NewRequest-Ward":
	//        toast( "A new request has been submitted to your ward.", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );
	//        break;

	//    case "NewRequestToStaff":
	//        toast( "A new request has been submitted to the staff.", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );
	//        break;

	//    case "Dependant":
	//        toast( "Dependant information has been updated.", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );  // Be more specific if possible (e.g., "A dependant's name has been changed")
	//        break;

	//    case "Rejected":
	//        toast( "Your request has been rejected.", {
	//            theme: "dark",
	//            hideProgressBar: true,
	//        } );  // Consider adding details about the rejected request if possible
	//        break;

	//}

	return (
		<div>
			<ToastContainer />
		</div>
	);
}

export default ToastMessage;
