import React, { useState, useEffect, useRef } from 'react';
import {
	VStack,
	HStack,
	Input,
	Text,
	Box
} from 'native-base';
import { Dimensions } from 'react-native';
import { Person } from '../../AVCore/AVCore';
import Moment from 'moment';
import { SearchIcon } from '../../assets/icons/icon-assets';
import AVBody from 'components/common/AVBody';
import { useSelector, RootStateOrAny } from 'react-redux';
import RenderEvents from './RenderEvents';
import { Loc } from '../../App/Loc';
import { EventsCardLoader } from '../../components/loaders';
import Filter from '../../components/common/AVFilter';
import AVSelectDropdown from '../../components/common/AVSelectDropdown';
import _ from 'lodash';
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { CalendarIcon } from "@mui/x-date-pickers";
import { AVCalendarView } from "../../components/common/calendar/AVCalendarView";


function MyEvents() {
	const user = useSelector((state: RootStateOrAny) => state?.user?.user[0]);
	const [showCreateNewPage, setShowCreateNewPage] = useState<any>(false);
	const [createType, setCreateType] = useState < any > ("");
	const [showEdit, setIsShowEdit] = useState(false);
	const [isOpenConvo, setIsOpenConvo] = useState(false);
	const [isConvoData, setIsConvoData] = useState('')

	return (
		<AVBody
			setIsShowEdit={setIsShowEdit}
			isGeneral={undefined}
			setIsGeneral={undefined}
			isRole={undefined}
			setIsRole={undefined}
			Entity={undefined}
			setIsEntity={undefined}
			type={undefined}
			isHomepage={undefined}
			setShowCreateNewPage={setShowCreateNewPage}
			showCreateNewPage={showCreateNewPage}
			setCreateType={setCreateType}
			createType={createType}
			passEntity={ undefined }
			isOpenConvo={ isOpenConvo }
			setIsConvoData={ setIsConvoData } isConvoData={ isConvoData }
			setPassEntity={ undefined } setIsOpenConvo={ setIsOpenConvo } 
			setIshuddleData={undefined}>
			<VStack flex={[1, 1, 1, 3]}>
				<EventsContent user={user} />
			</VStack>
		</AVBody>
	);
}

const EventsContent = ({ user }) => {
    const [searchValue, setSearchValue] = useState('');
    var Status = "ALL";
    const [eventType, SetEventType] = useState(Loc.currentLang.menu.current);
    const [isLoading, setIsLoading] = useState(true);
    const dimensions = Dimensions.get("window");
    const isMobile = dimensions?.width <= 500 || false;
    const [data, setEventData] = React.useState < any > ([]);
    const [eventsList, setEventsList] = React.useState < any > ();
    const [event, setEvent] = React.useState(eventsList);
    const [isActives, setIsActives] = useState(false);
    const [currentstatus, setcurrentStatus] = useState(Loc.currentLang.menu.current);
    const [isAll, setIsAll] = useState(true);
    const [isPariticipant, setIsParticipant] = useState(false);
    const [isGames, setIsGames] = useState(false);
    const [isMeeting, setIsMeeting] = useState(false);
    const [isOther, setIsOther] = useState(false);
    const [isPast, setIsPast] = useState(false);
    const [isCanceled, setIsCanceled] = useState(false);

    const [datass, setDatass] = useState < any > ("");
    var Status = "ALL";
    const [showCalendarView, setShowCalenderView] = useState(false);
    var searchvaluedata = '';

    const setSeriesEventsAsEvents = user.events.filter(x => x.series && x.seriesEvents.length > 0);
    //let person = Person.getSelf(true);

    console.log('Series Events :::::::::::::', setSeriesEventsAsEvents);

    const setAll = () => {
        setIsAll(true);
        setIsParticipant(false);
        setIsGames(false);
        setIsMeeting(false);
        setIsOther(false);
        Status = "ALL";
        setDatass("ALL");
        SetEventType(Loc.currentLang.menu.current);
        //if (currentstatus === Loc.currentLang.menu.current) {
        HandleCurrent("ALL");
        //} else if (currentstatus === Loc.currentLang.menu.all) {
        //	HandleAll("ALL");
        //} else if (currentstatus === Loc.currentLang.menu.past) {
        //	HandlePast("ALL");
        //} else if (currentstatus === Loc.currentLang.menu.canceled) {
        //	HandleDelete("ALL");
        //}
        setSearchValue('');
    };

    const setGames = () => {
        Status = "GAME";
        setDatass("GAME");
        setIsAll(false);
        setIsParticipant(false);
        setIsGames(true);
        setIsMeeting(false);
        setIsOther(false);
        setIsPast(false);
        setIsCanceled(false);
        SetEventType(Loc.currentLang.menu.gamesTxt);
        //if (currentstatus === Loc.currentLang.menu.current) {
        HandleCurrent("GAME");
        //} else if (currentstatus === Loc.currentLang.menu.all) {
        //	HandleAll("GAME");
        //} else if (currentstatus === Loc.currentLang.menu.past) {
        //	HandlePast("GAME");
        //} else if (currentstatus === Loc.currentLang.menu.canceled) {
        //	HandleDelete("GAME");
        //}
        setSearchValue('');
    };

    const setSocial = () => {
        Status = "SOCIAL";
        setDatass("SOCIAL");
        setIsAll(false);
        setIsParticipant(false);
        setIsGames(false);
        setIsMeeting(false);
        setIsOther(true);
        setIsPast(false);
        setIsCanceled(false);
        SetEventType(Loc.currentLang.menu.socialText);
        //if (currentstatus === Loc.currentLang.menu.current) {
        HandleCurrent("SOCIAL");
        //} else if (currentstatus === Loc.currentLang.menu.all) {
        //	HandleAll("SOCIAL");
        //} else if (currentstatus === Loc.currentLang.menu.past) {
        //	HandlePast("SOCIAL");
        //} else if (currentstatus === Loc.currentLang.menu.canceled) {
        //	HandleDelete("SOCIAL");
        //}
        setSearchValue('');
    };

    const setMeeting = () => {
        Status = "MEETING";
        setDatass("MEETING");
        setIsAll(false);
        setIsParticipant(false);
        setIsGames(false);
        setIsMeeting(true);
        setIsOther(false);
        setIsPast(false);
        setIsCanceled(false);
        SetEventType(Loc.currentLang.menu.meeting);
        //if (currentstatus === Loc.currentLang.menu.current) {
        HandleCurrent("MEETING");
        //} else if (currentstatus === Loc.currentLang.menu.all) {
        //	HandleAll("MEETING");
        //} else if (currentstatus === Loc.currentLang.menu.past) {
        //	HandlePast("MEETING");
        //} else if (currentstatus === Loc.currentLang.menu.canceled) {
        //	HandleDelete("MEETING");
        //}
        setSearchValue('');
    };

    const setPractices = () => {
        Status = "PRACTICE";
        setDatass("PRACTICE");
        SetEventType(Loc.currentLang.menu.practice);
        //if (currentstatus === Loc.currentLang.menu.current) {
        HandleCurrent("PRACTICE");
        //} else if (currentstatus === Loc.currentLang.menu.all) {
        //	HandleAll("PRACTICE");
        //} else if (currentstatus === Loc.currentLang.menu.past) {
        //	HandlePast("PRACTICE");
        //} else if (currentstatus === Loc.currentLang.menu.canceled) {
        //	HandleDelete("PRACTICE");
        //}
        setIsAll(false);
        setIsParticipant(true);
        setIsGames(false);
        setIsMeeting(false);
        setIsOther(false);
        setIsPast(false);
        setIsCanceled(false);
        setSearchValue('');
    };

    const setPast = () => {
        Status = "PAST";
        setDatass(Loc.currentLang.menu.past);
        setIsAll(false);
        setIsParticipant(false);
        setIsGames(false);
        setIsMeeting(false);
        setIsOther(false);
        setIsPast(true);
        setIsCanceled(false);
        SetEventType(Loc.currentLang.menu.past);
        HandlePasts();
        setSearchValue('');
    };

    const setCanceled = () => {
        Status = "CANCELED";
        setDatass(Loc.currentLang.menu.canceled);
        setIsAll(false);
        setIsParticipant(false);
        setIsGames(false);
        setIsMeeting(false);
        setIsOther(false);
        setIsPast(false);
        setIsCanceled(true);
        SetEventType(Loc.currentLang.menu.canceled);
        HandleCanceled();
        setSearchValue('');
    };

    const HandlePast = type => {
        const CurrentDateTime = new Date();

        let EventDataList = type == "ALL" ? user?.events : type == "PRACTICE" ? user?.events?.filter((x) => (x.linkType === 'Practice' || x.eventType === 'Practice') && x.status !== "Canceled") : type == "GAME" ? user?.events?.filter((x) => (x.linkType === 'Game' || x.eventType === 'Game') && x.status !== "Canceled") : type == "MEETING" ? user?.events?.filter((x) => (x.linkType === 'Meeting' || x.eventType === 'Meeting') && x.status !== "Canceled") :
            user?.events?.filter((x) => (x.linkType === 'Social' || x.eventType === 'Social') && x.status !== "Canceled")

        if (searchValue !== null && searchValue !== undefined && searchValue !== '' && searchvaluedata !== '')
            EventDataList = EventDataList?.filter((x) => (x?.title !== undefined && x?.title?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())));

        var PastEventsList: any = EventDataList
            .filter(event => {
                const originalStartDate = new Date(event.end);
                const formattedEndDate = originalStartDate.toISOString();
                return formattedEndDate < CurrentDateTime.toISOString();
            })
            .map(event => {
                return {
                    ...event,
                };
            });
        PastEventsList.sort((a: any, b: any) => {
            const startA: any = new Date(a.start);
            const startB: any = new Date(b.start);
            return startA - startB;
        });
        PastEventsList = PastEventsList.filter(x => x.data !== "Canceled" && x.status !== "Canceled")
        setEventData(PastEventsList);
        setEvent(PastEventsList);
    };

    const HandleCurrent = (type) => {
        const CurrentDateTime = new Date();
        let EventDataList = type === "ALL" ? user?.events?.filter(x => x.status !== "Canceled") :
            type === "PRACTICE" ? eventsList?.filter(x => (x.linkType === 'Practice' || x.eventType === 'Practice') && x.status !== "Canceled") :
            type === "GAME" ? eventsList?.filter(x => (x.linkType === 'Game' || x.eventType === 'Game') && x.status !== "Canceled") :
            type === "MEETING" ? eventsList?.filter(x => (x.linkType === 'Meeting' || x.eventType === 'Meeting') && x.status !== "Canceled") :
            eventsList?.filter(x => (x.linkType === 'Social' || x.eventType === 'Social') && x.status !== "Canceled");

        if (searchValue !== null && searchValue !== undefined && searchValue !== '' && searchvaluedata !== '')
            EventDataList = EventDataList?.filter((x) => (x?.title !== undefined && x?.title?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())));

        var currentEventsList = _(EventDataList)
            .filter(event => {
                const originalStartDate = new Date(event.end);
                const formattedStartDate = originalStartDate.toISOString();
                return formattedStartDate >= CurrentDateTime.toISOString();
            })
            .map(event => ({
                ...event
            }))
            .sortBy(event => {
                const parsedStartDate = new Date(event.end);
                return parsedStartDate.getTime();
            })
            .uniqBy('id') // Ensure uniqueness by 'id'
            .value();
        var currentEventsList = _(EventDataList)
            .filter(event => {
                const originalStartDate = new Date(event.end);
                const formattedStartDate = originalStartDate.toISOString();
                return formattedStartDate >= CurrentDateTime.toISOString();
            })
            .map(event => ({
                ...event
            }))
            .sortBy(event => {
                const parsedStartDate = new Date(event.end);
                return parsedStartDate.getTime();
            })
            .uniqBy('id') // Ensure uniqueness by 'id'
            .value();

        currentEventsList.sort((a: any, b: any) => {
            const startA: any = new Date(a.start);
            const startB: any = new Date(b.start);
            return startA - startB;
        });

        currentEventsList = currentEventsList.filter(x => x.data !== "Canceled" && x.status !== "Canceled");
        setEventData(currentEventsList);
        setEvent(currentEventsList);
    };

    const HandleAll = (type) => {
        const CurrentDateTime = new Date();
        type = type?.trim()?.length == 0 ? "ALL" : type;
        let EventDataList = type === "ALL" ? user?.events?.filter(x => x.status !== "Canceled") :
            type === "PRACTICE" ? user?.events?.filter(x => (x.linkType === 'Practice' || x.eventType === 'Practice') && x.status !== "Canceled") :
            type === "GAME" ? user?.events?.filter(x => (x.linkType === 'Game' || x.eventType === 'Game') && x.status !== "Canceled") :
            type === "MEETING" ? user?.events?.filter(x => (x.linkType === 'Meeting' || x.eventType === 'Meeting') && x.status !== "Canceled") :
            user?.events?.filter(x => (x.linkType === 'Social' || x.eventType === 'Social') && x.status !== "Canceled");

        EventDataList.sort((a: any, b: any) => {
            const startA: any = new Date(a.start);
            const startB: any = new Date(b.start);
            return startA - startB;
        });

        EventDataList = EventDataList.filter(x => x.data !== "Canceled" && x.status !== "Canceled");
        if (searchValue !== null && searchValue !== undefined && searchValue !== '' && searchvaluedata !== '')
            EventDataList = EventDataList?.filter((x) => (x?.title !== undefined && x?.title?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())));
        setEventData(EventDataList);
        setEvent(EventDataList);
    };

    const HandleDelete = (type) => {
        let EventDataList = type == "ALL" ? user?.events?.filter((x) => x.status === "Canceled") : type == "PRACTICE" ? eventsList?.filter((x) => x.linkType === 'Practice' && x.status === "Canceled") : type == "GAME" ? eventsList?.filter((x) => x.linkType === 'Game' && x.status === "Canceled") : type == "MEETING" ? eventsList?.filter((x) => x.linkType === 'Meeting' && x.status === "Canceled") :
            eventsList?.filter((x) => x.linkType === 'Social' && x.status === "Canceled")
        EventDataList.sort((a: any, b: any) => {
            const startA: any = new Date(a.start);
            const startB: any = new Date(b.start);
            return startA - startB;
        });

        if (searchValue !== null && searchValue !== undefined && searchValue !== '' && searchvaluedata !== '')
            EventDataList = EventDataList?.filter((x) => (x?.title !== undefined && x?.title?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())));

        setEventData(EventDataList);
        setEvent(EventDataList);
    };

    const HandleAlls = () => {
        setcurrentStatus(Loc.currentLang.menu.all)
        setIsActives(!isActives);
        HandleAll(datass)
    }

    const HandleCurrents = () => {
    setcurrentStatus(Loc.currentLang.menu.current)
    setIsActives(!isActives);
    HandleCurrent(datass);
}

    const HandlePasts = () => {
        setcurrentStatus(Loc.currentLang.menu.past)
        setIsActives(!isActives);
        HandlePast(datass);
    }

    const HandleCanceled = () => {
        setcurrentStatus(Loc.currentLang.menu.canceled)
        setIsActives(!isActives);
        HandleDelete(datass);
    }

    const dropdownValues = [{
            id: 1,
            name: Loc.currentLang.menu.all,
            flag: data,
            fn: HandleAlls
        },
        {
            id: 2,
            name: Loc.currentLang.menu.current,
            flag: data,
            fn: HandleCurrents
        },
        {
            id: 3,
            name: Loc.currentLang.menu.past,
            flag: data,
            fn: HandlePasts
        },
        {
            id: 4,
            name: Loc.currentLang.menu.canceled,
            flag: data,
            fn: HandleCanceled
        },
    ]

    const menuRefs = useRef(null);

    useOutsideAlerter(menuRefs);

    function useOutsideAlerter(ref) {
        React.useEffect(() => {
            let isMounted = true;
            if (isMounted) {
                function handleClickOutside(event) {
                    if (ref.current && !ref.current.contains(event.target)) {
                        setIsActives(false);
                    }
                }
                document.addEventListener('mousedown', handleClickOutside);
                return () => {
                    document.removeEventListener('mousedown', handleClickOutside);
                };
            }
            return () => {
                isMounted = false; // Set the flag to false when the component is unmounted
                // Cancel subscriptions or perform any cleanup tasks here
            };
        }, [ref]);
    }

    const GetEvents = () => {
        let allEvents = _.uniqBy([...(user?.events || []), (user?.ownedEvents || [])], 'id');
        allEvents = allEvents?.sort((a, b) => Moment(b.start).valueOf() - Moment(a.start).valueOf());
        let todatevnts = allEvents?.filter(x => Moment(x.start).format('YYYY-MM-DD') === Moment().format('YYYY-MM-DD'));
        allEvents = allEvents?.filter(x => Moment(x.start).format('YYYY-MM-DD') !== Moment().format('YYYY-MM-DD'))
        todatevnts?.forEach(event => {
            allEvents.unshift(event)
        });
        setEventData(allEvents);
        setEventsList(allEvents);
        setIsLoading(false);
        HandleCurrent("ALL");
        setDatass("ALL")
        setcurrentStatus(Loc.currentLang.menu.current)
    };

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            GetEvents();
        }
        return () => {
            isMounted = false; // Set the flag to false when the component is unmounted
            // Cancel subscriptions or perform any cleanup tasks here
        };
    }, [user]);

    const handleSearch = async (item) => {
        setSearchValue(item);

        if (item) {
            let searchData = eventsList?.filter((x) => {
                const titleIncludesItem = x?.title?.toLowerCase()?.includes(item.toLowerCase());

                if (eventType?.toLowerCase() === "current") {
                    return (
                        new Date(x.end) >= new Date() &&
                        x?.data?.toLowerCase() !== "canceled" &&
                        x?.status?.toLowerCase() !== "canceled" &&
                        titleIncludesItem
                    );
                } else if (eventType?.toLowerCase() === "past") {
                    return (
                        new Date(x.end) < new Date() &&
                        x?.data?.toLowerCase() !== "canceled" &&
                        x?.status?.toLowerCase() !== "canceled" &&
                        titleIncludesItem
                    );
                } else if (eventType?.toLowerCase() === "canceled") {
                    return (
                        (x?.data?.toLowerCase() === "canceled" || x?.status?.toLowerCase() === "canceled") &&
                        titleIncludesItem
                    );
                } else {
                    return (
                        x.linkType?.toLowerCase() === eventType?.toLowerCase() &&
                        x?.data?.toLowerCase() !== "canceled" &&
                        x?.status?.toLowerCase() !== "canceled" &&
                        new Date(x.end) >= new Date() &&
                        titleIncludesItem
                    );
                }
            });

            setEventData(searchData);
            //setEventsList(searchData);
        } else {
            console.log('Enter into the else part of the filter ', currentstatus);
            searchvaluedata = '';
            setSearchValue('');
            setEventData(event);
            //setEventsList( event )
            //if ( currentstatus == "All" )
            //HandleCurrent( datass );
            //else if ( currentstatus == "Past" )
            //    HandlePast( datass );
            //else if ( currentstatus == "Current" )
            //    HandleCurrent( datass );
            //else if ( currentstatus == "Canceled" )
            //    HandleDelete(datass);
        }
    };

	return (
		<>
			<Box bgColor="#fff" shadow="1" borderRadius={5} height={isMobile ? "160px" : ""}>
				<VStack px={3} pt={2}>
					<HStack alignItems={"center"} space={3} flex={1} justifyContent={"space-between"}>
						{!isMobile && (
							<Text
								fontSize={"20px"}
								fontWeight={"bold"}
								width={isMobile ? "25%" : "40%"}
								text-overflow={"ellipsis"}
								numberOfLines={1}>
								{Loc.currentLang.menu.hello},
								<Text
									color={"AVColor.primary"}
									width={"100px"}
									text-overflow={"ellipsis"}
									fontSize={"17px"}
									numberOfLines={1}>
									{Person.getLink(Person.self())?.title}!
								</Text>
							</Text>
						)}
						<HStack
							alignItems={"center"}
							justifyContent={isMobile ? "flex-start" : "flex-end"}
							space={2}
							width={isMobile ? "100%" : "61%"}>
							{!isMobile && (
								<Text fontSize={[12, 14]} color="AVColor.textLite">
									{Moment().format("MMMM D, Y")}
								</Text>
							)}

							{
								!showCalendarView &&
								<Input
									width={isMobile ? "97%" : "251px"}
									borderWidth={0}
									placeholder={Loc.currentLang.menu.searchEvents}
									variant='AVSearchInput'
									placeholderTextColor='AVColor.textLite'
									backgroundColor={'#F5F5F5'}
									borderRadius={'22px'}
									fontSize={'16px'}
									value={searchValue}
									InputLeftElement={
										<SearchIcon ml='2' size='24px' color='#000' left={'10px'} p={'7px'} />
									}
									onChangeText={(e) => handleSearch(e)}
								/>
							}
						</HStack>
					</HStack>

					{/* menu */}
					<div className="custom-scrollbar">
						<HStack width={"100%"}>
							<HStack
								marginY={"24px"}
								overflowX={isMobile ? "scroll" : undefined}
								backgroundColor={"rgba(0,0,0,0.05)"}
								borderRadius={"18px"}
								width={isMobile ? "322px" : "auto"}
								right={isMobile ? "10px" : ""}
								height={"36px"}>
								{!showCalendarView && (
									<>
										{eventsList?.length > 0 && (
										<Filter Type={eventType} SetType={setAll} Name={Loc.currentLang.menu.current} />
										)}
										{eventsList?.filter(x => new Date(x.end) > new Date() && (x?.linkType === "Game" || x?.eventType === "Game"))?.length > 0 && (
										<Filter Type={eventType} SetType={setGames} Name={Loc.currentLang.menu.gamesTxt} />
										)}
										{eventsList?.filter(x => new Date(x.end) > new Date() && (x?.linkType === "Practice" || x?.eventType === "Practice"))?.length > 0 && (
										<Filter Type={eventType} SetType={setPractices} Name={Loc.currentLang.menu.practice} />
										)}
										{eventsList?.filter(x => new Date(x.end) > new Date() && (x?.linkType === "Meeting" || x?.eventType === "Meeting"))?.length > 0 && (
										<Filter Type={eventType} SetType={setMeeting} Name={Loc.currentLang.menu.meeting} />
										)}
										{eventsList?.filter(x => new Date(x.end) > new Date() && (x?.linkType === "Social" || x?.eventType === "Social"))?.length > 0 && (
										<Filter Type={eventType} SetType={setSocial} Name={Loc.currentLang.menu.socialText} />
										)}
										{eventsList?.filter(x => new Date(x.end) < new Date())?.length > 0 && (
										<Filter Type={eventType} SetType={setPast} Name={Loc.currentLang.menu.past} />
										)}
										{eventsList?.filter(x => x?.status === Loc.currentLang.menu.canceled || x?.data === Loc.currentLang.menu.canceled)?.length > 0 && (
										<Filter Type={eventType} SetType={setCanceled} Name={Loc.currentLang.menu.canceled} />
										)}
									</>
								)}
							</HStack> 
							{eventsList?.length > 0 && (
								<HStack
									position={"absolute"}
									ref={menuRefs}
									right={0}
									top={isMobile ? "68px" : "30px"}
									zIndex={999}
									alignItems={"center"}
									space={ 1 }>
									{/*{!showCalendarView && (*/}
									{/*	<AVSelectDropdown*/}
									{/*		dropdownValues={dropdownValues}*/}
									{/*		currentstatus={currentstatus}*/}
									{/*		setIsActives={setIsActives}*/}
									{/*		isActives={isActives}*/}
									{/*	/>*/}
									{/*) }*/}
									{ showCalendarView &&
										<FormatListBulletedIcon
											style={ { color: "#00A7BC" } }
											onClick={ () => setShowCalenderView( false ) }
											cursor={ "pointer" }
										/> }
									{ !showCalendarView &&
										<CalendarIcon
											style={ { color: "#00A7BC" } }
											onClick={ () => setShowCalenderView( true ) }
											cursor={ "pointer" }
										/>
									}
								</HStack>
							)}
						</HStack>
					</div>
				</VStack>
			</Box>
			<VStack my={3} zIndex={-3}>
				<HStack>
					<Text fontSize={"24px"} color={"AVColor.primary"}>
						{Loc.currentLang.menu.events}
					</Text>
				</HStack>

				<HStack
					backgroundColor={"AVColor.white"}
					space={1}
					fontWeight={"500"}
					flexWrap={"wrap"}
					height={"auto"}
					padding={"8px"}
					paddingLeft={"10px"}
					borderRadius={10}
					width={"100%"}
					marginTop={data?.length === 0 ? "10px" : "8px"}
					justifyContent={data?.length === 0 ? "center" : isMobile ? "center" : ""}>
					{isLoading && (
						<HStack width={"100%"} flexWrap={"wrap"} justifyContent={"space-around"}>
							{[...Array(9)].map((i, index) => (
								<Box key={index} width={"1/3"}>
									<EventsCardLoader />
								</Box>
							))}
						</HStack>
					)}

					{showCalendarView && <AVCalendarView data={user?.events?.filter(x => x?.data?.toLowerCase() !== "canceled" && x?.status?.toLowerCase() !== "canceled")} eventType={Loc.currentLang.menu.current} />}
					{data?.length > 0 && !showCalendarView && <RenderEvents data={data} />}
					{data?.length === 0 && !showCalendarView && (
						<VStack>
							<HStack padding={"10px"} alignItems={"center"} zIndex={-3}>
								<Text>{Loc.currentLang.menu.noEventsFound}</Text>
							</HStack>
						</VStack>
					)}
				</HStack>
			</VStack>
		</>
	);
};

export default React.memo(MyEvents);