import { Divider, HStack, Text, VStack, Box, Tooltip } from "native-base";
import React, { useEffect, useState, useRef } from "react";
import { Loc } from "../../App/Loc";
import { RootStateOrAny, useSelector } from "react-redux";
import helping from "../../assets/images/helping.png";
import { useWindowDimensions } from "react-native";
import { Dimensions } from "react-native";
import AVSelectDropdown from "../../components/common/AVSelectDropdown";
import AVComDropdonwn from "../../components/common/AVComDropdown";
import { CalendarIcon } from "@mui/x-date-pickers";
import { AVCalendarView } from "../../components/common/calendar/AVCalendarView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import RenderEvents from "../Events/RenderEvents";
import inform from "../../assets/images/inform.png";
import _ from 'lodash';
import Filter from '../../components/common/AVFilter';

const ShowEvents = ( { handleEvents, userData, isOwner } ) =>
{
    const [data, setEventData] = React.useState(userData?.ownedEvents);
    const [eventType, setEventType] = useState(Loc.currentLang.menu.current);
    const [eventsList, setEventsList] = React.useState([]);
    const dimensions = Dimensions.get('window');
    const isMobile = dimensions?.width <= 500 || false;
    const [showCalendarView, setShowCalenderView] = useState(false);

    const GetEvents = () => {
        const allEvents = userData?.ownedEvents || [];
        HandleCurrent("ALL");
        setEventData(allEvents);
        setEventType(Loc.currentLang.menu.current);

        setEventsList(
            allEvents.filter(
                (x) =>
                new Date(x.end) >= new Date() &&
                !isCanceled(x)
            )
        );
    };

    const isCanceled = (event: any) =>
        event?.data?.toLowerCase() === "canceled" || event?.status?.toLowerCase() === "canceled";

    const HandleCurrent = (type: string) => {
        const CurrentDateTime = new Date();
        const EventDataList = type === "ALL" ? userData?.ownedEvents : [];

        const currentEventsList = _(EventDataList)
            .filter((event: any) => new Date(event.end) >= CurrentDateTime)
            .sortBy((event: any) => new Date(event.start))
            .uniqBy("id")
            .filter((event: any) => !isCanceled(event))
            .value();

        setEventData(currentEventsList);
    };

    const filterByType = (type: string, linkType: string | null = null) => {
        const filteredEvents = data?.filter((x: any) => {
            if (type === "past") return new Date(x.end) < new Date() && !isCanceled(x);
            if (type === "canceled") return isCanceled(x);
            return (
                (!linkType || x.linkType?.toLowerCase() === linkType) &&
                !isCanceled(x) &&
                new Date(x.end) >= new Date()
            );
        });
        
        const key = linkType || type;
        setEventType(menuMapping[key] || "");
        setEventsList(filteredEvents);
    };

    const menuMapping: { [key: string]: string } = {
        current: Loc.currentLang.menu.current,
        game: Loc.currentLang.menu.gamesTxt,
        practice: Loc.currentLang.menu.practice,
        meeting: Loc.currentLang.menu.meeting,
        social: Loc.currentLang.menu.socialText,
        past: Loc.currentLang.menu.past,
        canceled: Loc.currentLang.menu.canceled,
    };

    // Event Setter Functions
    const setAlls = () => filterByType("current");
    const setGames = () => filterByType("current", "game");
    const setSocial = () => filterByType("current", "social");
    const setMeeting = () => filterByType("current", "meeting");
    const setPractices = () => filterByType("current", "practice");
    const setPast = () => filterByType("past");
    const setCanceled = () => filterByType("canceled");

    useEffect(() => {
        GetEvents();
    }, [showCalendarView]);

    return (
        <>
            <VStack p={ 2 } backgroundColor={ "AVColor.white" } minHeight={ "340px" } width={ "100%" }>
                <VStack space={ 5 }>
                    <EventsMenu
                        handleEvents={ handleEvents }
                        eventType={ eventType }
                        eventsList={ eventsList }
                        setEventType={ setEventType }
                        setEventData={ setEventData }
                        isOwner={ isOwner }
                        data={ data }
                        userData={ userData }
                        HandleCurrent={ HandleCurrent }
                        setShowCalenderView={ setShowCalenderView }
                        showCalendarView={ showCalendarView }
                    />
                </VStack>
                <Divider my={ 2 } zIndex={ -3 } />
                { !showCalendarView &&
                    data?.length > 0 &&
                    < HStack
                        marginY={ "24px" }
                        overflowX={ isMobile ? "scroll" : undefined }
                        backgroundColor={ "rgba(0,0,0,0.05)" }
                        borderRadius={ "18px" }
                        minWidth={ isMobile ? "322px" : "" }
                        maxWidth={ isMobile ? "322px" : "max-content" }
                        left={ "10px" }
                        height={ "36px" }>
                        { data?.length > 0 && <Filter Type={ eventType } SetType={ setAlls } Name={ Loc.currentLang.menu.current } /> }
                        { data?.length > 0 && data?.filter( ( x : any ) => new Date( x?.end ) > new Date() && ( x?.linkType == "Game" || x?.eventType == "Game" ) )?.length > 0 && (
                            <Filter Type={ eventType } SetType={ setGames } Name={ Loc.currentLang.menu.gamesTxt } />
                        ) }
                        { data?.length > 0 && data?.filter( ( x : any ) => new Date( x?.end ) > new Date() && ( x?.linkType == "Practice" || x?.eventType == "Practice" ) )?.length > 0 && (
                            <Filter Type={ eventType } SetType={ setPractices } Name={ Loc.currentLang.menu.practice } />
                        ) }
                        { data?.length > 0 && data?.filter( ( x : any ) => new Date( x?.end ) > new Date() && ( x?.linkType == "Meeting" || x?.eventType == "Meeting" ) )?.length > 0 && (
                            <Filter Type={ eventType } SetType={ setMeeting } Name={ Loc.currentLang.menu.meeting } />
                        ) }
                        { data?.length > 0 && data?.filter( ( x : any ) => new Date( x?.end ) > new Date() && ( x?.linkType == "Social" || x?.eventType == "Social" ) )?.length > 0 && (
                            <Filter Type={ eventType } SetType={ setSocial } Name={ Loc.currentLang.menu.socialText } />
                        ) }
                        { data?.length > 0 && data?.filter( ( x : any ) => new Date( x?.end ) < new Date() )?.length > 0 && (
                            <Filter Type={ eventType } SetType={ setPast } Name={ Loc.currentLang.menu.past } />
                        ) }
                        { data?.length > 0 && data?.filter( ( x : any ) => x?.status == Loc.currentLang.menu.canceled || x?.data == Loc.currentLang.menu.canceled )?.length > 0 && (
                            <Filter Type={ eventType } SetType={ setCanceled } Name={ Loc.currentLang.menu.canceled } />
                        ) }
                    </HStack> }
                { !showCalendarView && eventsList?.length == 0 &&
                    <VStack width={ "100%" } justifyContent={ "center" } alignItems={ "center" }>
                        { userData?.ownedEvents?.length !== 0 && (<HStack padding={ "10px" } alignItems={ "center" } zIndex={ -3 }>
                            <Text>{ Loc.currentLang.menu.noEventsFound }</Text>
                        </HStack>)}
                    </VStack>
                }
          <HStack
                    zIndex={ -3 }
                    space={ 1 }
                    fontWeight={ "500" }
                    flexWrap={ "wrap" }
                    height={ "auto" }
                    paddingY={ "5px" }
                    borderRadius={ 10 }
                    width={ "100%" }
                    marginTop={ data?.length === 0 ? "10px" : "" }
                    justifyContent={ data?.length === 0 ? "center" : isMobile ? "center" : "" }>
                    { showCalendarView ? <AVCalendarView data={ data?.filter(x => x?.data?.toLowerCase() !== "canceled" && x?.status?.toLowerCase() !== "canceled") } eventType={ Loc.currentLang.menu.current } /> : <RenderEvents data={ eventsList } /> }
                </HStack>
                { !showCalendarView && eventsList?.length == 0 && data?.length == 0 &&
                    <HStack
                        width={ "100%" }
                        top={ isMobile ? "20px" : "0px" }
                        padding={ isMobile ? "10px" : "10px" }
                        marginTop={ isMobile ? "-10px" : "0px" }
                        space={ isMobile ? 2 : 2 }
                        zIndex={ -3 }>
                        <img src={ inform } style={ { width: "20px", height: "20px" } } />
                        { Loc.currentLang.helpText.person.eventTab }
                    </HStack>
                }
            </VStack>
        </>
    );
};

const EventsMenu = ({
    handleEvents,
    eventType,
    eventsList,
    setEventType,
    userData,
    setEventData,
    isOwner,
    data,
    HandleCurrent,
    setShowCalenderView,
    showCalendarView
}) => {
    const dimensions = useWindowDimensions();
    const isMobile = dimensions?.width <= 600 || false;
    const [isActives, setIsActives] = useState(false);
    const [filterevent, setFilterEvent] = useState(true);
    const [currentstatus, setcurrentStatus] = useState(setEventType)
    const currentUser = useSelector((state: RootStateOrAny) => state?.user?.user[0]);

    const HandlePast = (type) => {
        const CurrentDateTime = new Date();

        let EventDataList: any = type == "ALL" ? eventsList : '';
        var PastEventsList: any = EventDataList
            .filter(event => {
                const originalStartDate = new Date(event.end);
                const formattedEndDate = originalStartDate.toISOString();

                return formattedEndDate < CurrentDateTime.toISOString();
            })
            .map(event => {
                return {
                    ...event,
                };
            })
            .sort((a: any, b: any) => {
                const startA: any = new Date(a.start);
                const startB: any = new Date(b.start);
                return startA - startB;
            });

        PastEventsList = PastEventsList.filter(x => x.status !== "Canceled" && x.data !== "Canceled");
        setEventData(PastEventsList);
    };

    const HandleAll = (type) => {
        const CurrentDateTime = new Date();
        let EventDataList: any = type == "ALL" ? eventsList : '';

        EventDataList?.sort((a: any, b: any) => {
            const startA: any = new Date(a.start);
            const startB: any = new Date(b.start);
            return startA - startB;
        });
        EventDataList = EventDataList?.filter(x => x.status !== "Canceled" && x.data !== "Canceled");
        setEventData(EventDataList);
    };

    const HandleDelete = (type) => {
        let EventDataList: any = type == "ALL" ? eventsList.filter((x) => x.data === "Canceled") : '';
        EventDataList.sort((a: any, b: any) => {
            const startA: any = new Date(a.start);
            const startB: any = new Date(b.start);
            return startA - startB;
        });
        setEventData(EventDataList);
    };

    const HandleAlls = () => {
        setcurrentStatus(Loc.currentLang.menu.current)
        setFilterEvent(!filterevent);
        HandleAll("ALL")
    }

    const HandleCurrents = () => {
        setcurrentStatus(Loc.currentLang.menu.current)
        HandleCurrent("ALL")
        setFilterEvent(!filterevent);
    }

    const HandlePasts = () => {
        setcurrentStatus(Loc.currentLang.menu.past)
        setFilterEvent(!filterevent);
        HandlePast("ALL")
    }

    const HandleCanceled = () => {
        setcurrentStatus(Loc.currentLang.menu.canceled)
        HandleDelete("ALL")
        setFilterEvent(!filterevent);
    }

    const HandleSocial = () => {
        handleEvents('Social');
        setIsActives(!isActives);
    }

    const HandleMeeting = () => {
        handleEvents('Meeting');
        setIsActives(!isActives);
    }

    const menuRefs = useRef(null);
    useOutsideAlerter(menuRefs);

    function useOutsideAlerter(ref) {
        React.useEffect(() => {
            let isMounted = true;
            if (isMounted) {
                function handleClickOutside(event) {
                    if (ref.current && !ref.current.contains(event.target)) {
                        setIsActives(false);
                    }
                }
                document.addEventListener('mousedown', handleClickOutside);
                return () => {
                    document.removeEventListener('mousedown', handleClickOutside);
                };
            }
            return () => {
                isMounted = false; // Set the flag to false when the component is unmounted
                // Cancel subscriptions or perform any cleanup tasks here
            };
        }, [ref]);
    }

    const dropdownValues = [{
            id: 1,
            name: Loc.currentLang.menu.all,
            flag: data,
            fn: HandleAlls
        },
        {
            id: 2,
            name: Loc.currentLang.menu.current,
            flag: data,
            fn: HandleCurrents
        },
        {
            id: 3,
            name: Loc.currentLang.menu.past,
            flag: data,
            fn: HandlePasts
        },
        {
            id: 4,
            name: Loc.currentLang.menu.canceled,
            flag: data,
            fn: HandleCanceled
        },
    ]

    const dropdownEventValues = [{
            id: 3,
            name: Loc.currentLang.menu.socialText,
            flag: data,
            fn: HandleSocial
        },
        {
            id: 4,
            name: Loc.currentLang.menu.meeting,
            flag: data,
            fn: HandleMeeting
        },
    ]

    return (
        <>
            <HStack justifyContent={ 'space-between' } marginY={ '11px' } marginX={ '10px' } alignItems={ 'center' } space={ 2 } width={ '100%' } flexWrap={ isMobile ? 'wrap' : 'nowrap' }>
                <HStack width={ isMobile ? '96%' : '100%' } justifyContent={ 'space-between' } marginTop={ isMobile ? '14px' : '' } marginLeft={ '-15px' } height={ '38px' } alignItems={ 'center' } >
                    <Text
                        fontWeight={ '500' }
                        marginLeft={ '15px' }
                        fontSize={ '22px' }
                        color={ 'AVColor.primary' }
                        py={ '12px' }
                    >
                        { Loc.currentLang.menu.events }
                    </Text>

                    <HStack space={ isMobile ? 2 : 2 }>
                        { (
                            <HStack space={ 2 } alignItems={ "center" } left={ isMobile ? "10px" : "0px" }>
                                { showCalendarView &&
                                    <FormatListBulletedIcon
                                        style={ { color: "#00A7BC" } }
                                        onClick={ () => setShowCalenderView( false ) }
                                        cursor={ "pointer" }
                                    /> }
                                { !showCalendarView &&
                                    <CalendarIcon
                                        style={ { color: "#00A7BC" } }
                                        onClick={ () => setShowCalenderView( true ) }
                                        cursor={ "pointer" }
                                    />
                                }
                                {/*{!showCalendarView && (*/ }
                                {/*    <AVSelectDropdown dropdownValues={dropdownValues} currentstatus={currentstatus} setIsActives={setFilterEvent} isActives={filterevent} />*/ }
                                {/*)}*/ }
                            </HStack>
                        ) }
                        {userData?.ownedEvents?.length !== 0 && ( <Tooltip label={ Loc.currentLang.helpText.person.eventTab } w={ 200 } left={ isMobile ? "10px" : undefined }>
                            <Box
                                marginRight={ "10px" }
                                p={ "9px" }
                                left={ isMobile ? "10px" : undefined }
                                display={ showCalendarView ? "" : data?.length == 0 && userData?.events?.length == 0 ? "none" : "" }
                                shadow={ 1 }
                                zIndex={ -3 }
                                height={ "33px" }
                                backgroundColor={ "AVColor.liteBg" }
                                rounded={ "full" }>
                                <img src={ helping } height={ "17px" } width={ "17px" } alt="Help" />
                            </Box>
                        </Tooltip>)}
                        { isOwner && (
                            <>
                                <HStack top={ isMobile ? "0px" : "0px" } right={ isMobile ? "0px" : "0px" }>
                                    <AVComDropdonwn dropdownValues={ dropdownEventValues } />
                                </HStack>
                            </>
                        ) }
                    </HStack>
                </HStack>
            </HStack>
        </>
    );
};

export default ShowEvents;