import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Button, HStack } from "native-base";
import { HomeIcon, EventsIcon, NetworkIcon, FavouriteIcon, HuddleDefaultIcon, HuddleActiveIcon } from "../../assets/icons/icon-assets";
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { setFeedsScrollUpdate } from '../../Redux/Actions/feedsAction';

function Menu ( props : any )
{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [ isHome, setIsHome ] = React.useState( window.location.pathname === "/" );
    const [ isEvent, setIsEvent ] = React.useState( window.location.pathname === "/my-events" );
    const [ isTeams, setIsTeams ] = React.useState( window.location.pathname === "/my-teams" );
    const [ isFavourites, setIsFavourites ] = React.useState( window.location.pathname === "/favorites" );
    const [ isHuddles, setIsHuddles ] = React.useState( window.location.pathname.startsWith("/huddels") );
    const currentUser = useSelector( ( state : RootStateOrAny ) => state.user.user[ 0 ] );

    const [ isMounted, setIsMounted ] = React.useState( true ); // Flag to check if component is mounted

    // Cleanup function to set isMounted to false when the component unmounts
    React.useEffect( () =>
    {
        setIsMounted( true );
        return () => setIsMounted( false );
    }, [] );

    const handleNavigation = ( path : string, setState : React.Dispatch<React.SetStateAction<boolean>> ) =>
    {
        // Check if component is still mounted before updating state
        if ( !isMounted ) return;
        
        // Special handling for huddles navigation
        if ( path === '/huddels' ) {
            // Get the first channel from user's orgs or use a default
            const defaultHuddle = currentUser?.orgs?.filter(x => x.type === "Channel")?.reverse()[0];
            if ( defaultHuddle?.id ) {
                navigate( `/huddels/${defaultHuddle.id}` );
            } else {
                navigate( '/huddels/1' );
            }
        } else {
            navigate( path );
        }

        setIsHome( window.location.pathname === "/" ? true : false );
        setIsEvent( window.location.pathname === "/my-events" ? true : false );
        setIsTeams( window.location.pathname === "/my-teams" ? true : false );
        setIsFavourites( window.location.pathname === "/favorites" ? true : false );
        setIsHuddles( path === "/huddels" );
    };

    return (
        <HStack space={ '10' } justifyContent={ 'center' }>
            <Button
                borderRadius={ 'full' }
                paddingTop={ '10px' }
                paddingBottom={ '12px' }
                paddingLeft={ '11px' }
                bgColor={ isHome ? '#00A7BC' : 'none' }
                _hover={ !isHome ? { bgColor: '#EEEEEE' } : { bgColor: '#00A7BC' } }
                onPress={ () => { handleNavigation( '/', setIsHome ) } }
            >
                <HomeIcon size={ '34px' } color={ isHome ? 'white' : '#000' } />
            </Button>

            <Button
                borderRadius={ 'full' }
                paddingTop={ '10px' }
                paddingBottom={ '12px' }
                paddingLeft={ '11px' }
                bgColor={ isEvent ? '#00A7BC' : 'none' }
                _hover={ !isEvent ? { bgColor: '#EEEEEE' } : { bgColor: '#00A7BC' } }
                onPress={ () => handleNavigation( '/my-events', setIsEvent ) }
            >
                <EventsIcon size={ '34px' } color={ isEvent ? 'white' : '#000' } />
            </Button>

            <Button
                borderRadius={ 'full' }
                paddingTop={ '10px' }
                paddingBottom={ '12px' }
                paddingLeft={ '11px' }
                bgColor={ isTeams ? '#00A7BC' : 'none' }
                _hover={ !isTeams ? { bgColor: '#EEEEEE' } : { bgColor: '#00A7BC' } }
                onPress={ () => handleNavigation( '/my-teams', setIsTeams ) }
            >
                <NetworkIcon height='34px' width='34px' color={ isTeams ? 'white' : '#000' } stroke={ isTeams ? 'white' : '#000' } strokeWidth={ isTeams ? '4px' : '4px' } />
            </Button>

            <Button
                borderRadius={ 'full' }
                paddingTop={ '10px' }
                paddingBottom={ '12px' }
                paddingLeft={ '11px' }
                bgColor={ isFavourites ? '#00A7BC' : 'none' }
                _hover={ !isFavourites ? { bgColor: '#EEEEEE' } : { bgColor: '#00A7BC' } }
                onPress={ () => handleNavigation( '/favorites', setIsFavourites ) }
            >
                <FavouriteIcon size={ '34px' } color={ isFavourites ? 'white' : '#000' } />
            </Button>

            <Button
              borderRadius="full"
              paddingTop="10px"
              paddingBottom="12px"
              paddingLeft="11px"
              bgColor={isHuddles ? '#00A7BC' : 'none'}
              _hover={{
                bgColor: !isHuddles ? '#EEEEEE' : '#00A7BC',
              }}
              onPress={() => handleNavigation('/huddels', setIsHuddles)}
            >
              {isHuddles ? (
                <HuddleActiveIcon height="34px" width="34px" />
              ) : (
                <HuddleDefaultIcon height="34px" width="34px" />
              )}
            </Button>
        </HStack>
    );
}

export default React.memo( Menu );
