import { EditIcon, GroupIcon } from "assets/icons/icon-assets";
import { AVButtonRoundedFull } from "components/common/AVButtons";
import * as AV from "../../AVCore/AVCore";
import AVCancel from "components/common/AVCancel";
import { HStack, Text, VStack, Pressable, Box, Hidden, Input, Button, TextArea, Tooltip } from "native-base";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setUser, setEntityToken, setEntitydata } from "Redux/Actions/userActions";
import { Person } from "../../AVCore/Person";
import { name } from "@azure/msal-react/dist/packageMetadata";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import inform from "../../assets/images/inform.png";
import AVInputtext from "../../components/AVInputtext";
import { Loc } from "../../App/Loc";
import validator from "validator";
import CustomToast from "../../Hooks/useCustomToast";
import AddandEditLinks from "../common/AddandEditLinks";

function RenderUserInfo({
	data,
	isOwner,
	userID,
	isMobile,
	setEditProfile,
	setUserInfo,
	editProfile,
	setIsMyPage,
	setIsEventPage,
	setIsTeamsPage,
	setIsPhotoPage,
	setIsDocumentPage,
	setisMembersPage,
	setisDependantspage,
	setisGuardianspage,
}) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isProfileSaving, setIsProfileSaving] = useState(false);
	const [Name, setName] = useState();
	const [editdata, setEditData] = useState<any>();
	const currentUser = useSelector((state: RootStateOrAny) => state.user.user[0]);
	const entitydatalist: any = useSelector((state: RootStateOrAny) => state.user.entitydatalist);
	const entityToken = useSelector((state: RootStateOrAny) => state?.user.entityToken);
	const [isUpdate, setIsUpdate] = useState(false);
	const [fullname, setfullname] = useState("");
	const [url, setUrl] = useState("");
	const [intro, setIntro] = useState<any>();
	const [isValidUrl, setIsValidUrl] = useState(false);
	const [message, setMessage] = useState("");
	const [addLinkPopup, setAddLinkPopup] = useState(false);
	const [linkList, setLinksList] = useState<any>([]);
	const [address, setAddress] = useState<any>({
		normalized: null,
		city: null,
		region: null,
		zip: null,
		lat: null,
		lng: null,
	});
	const [profile, setProfile] = useState({
		background: null,
		pic: null,
		thumb: null,
		gallery: null,
		previousPic: null,
		text: null,
	});
	const urlRegex = /^(https?|ftp):\/\/[a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})?(:\d+)?(\/\S*)?$/;

	//Save profile
	const handleSaveProfile = async e => {
		if (url?.trim()?.length > 0) {
			if (!urlRegex.test(url)) {
				let clientError = { message: Loc.currentLang.menu.invalidExternalLink };
				CustomToast({ clientError });
				return;
			}
		}
		let fk = AV.AVEntity.getFk(data);
		if (fk?.id) {
			e.preventDefault();
			setIsProfileSaving(true);
			const p: any = await AV.Person.get(AV.AVEntity.getFk(data));
			p.address ??= {};
			p.address = address;
			p.address.city = "";
			p.address.normalized = "";
			p.address.region = "";
			p.address.placeId = null;
			p.name = fullname;
			p.Preferences = {
				IsPrivate: false,
			}
			p.links = linkList;
			p.profile ??= {};
			p.profile.text = intro;
			let res = await AV.AVEntity.save(p);
			setIsProfileSaving(false);
			if (res) {
				setUserInfo(res);
				if (currentUser?.id === res?.id) dispatch(setUser(res));
				let index = entitydatalist?.findIndex(x => x?.id === res?.id);
				if (index !== -1) {
					entitydatalist[index] = res;
					dispatch(setEntitydata(entitydatalist));
				}
				setUserInfo(entitydatalist[index]);
				setEditProfile(false);
			}
			setIsUpdate(true);
			if (entityToken?.type) {
				entityToken?.type == "Person"
					? navigate(`/profile/${entityToken?.id}/${entityToken?.region}`, {
							state: {
								user: {
									id: entityToken?.id,
									pk: entityToken?.pk,
									region: entityToken?.region,
									type: entityToken?.type,
									name: entityToken?.title,
								},
							},
						})
					: entityToken?.type == "LeagueTeam"
						? navigate(`/season/${entityToken?.id}/${entityToken?.region}`, {
								state: { isCreate: false, team: entityToken },
							})
						: entityToken?.type == "Team"
							? navigate(`/teams/${entityToken?.id}/${entityToken?.region}`, {
									state: { isCreate: false, team: entityToken },
								})
							: navigate(`/organizations/${entityToken?.id}/${entityToken?.region}/${entityToken?.type}`, {
									state: { isCreate: false, orgType: entityToken?.type, org: entityToken },
								});
			} else {
				navigate(`/profile/${res?.id}/${res?.region}`, { state: { user: Person.getLink(res) } });
			}
			handlePageClick();
			setIsProfileSaving(false);
			dispatch(setEntityToken([]));
		}
	};
	const handlePageClick = () => {
		setEditProfile(false);
		setIsMyPage(true);
		setIsEventPage(false);
		setIsTeamsPage(false);
		setIsPhotoPage(false);
		setIsDocumentPage(false);
		setisMembersPage(false);
		setisDependantspage(false);
		setisGuardianspage(false);
	};
	//Edit profile
	const handleEditProfile = () => {
		setEditProfile(true);
		setIsMyPage(false);
		setIsEventPage(false);
		setIsTeamsPage(false);
		setIsPhotoPage(false);
		setIsDocumentPage(false);
		setisMembersPage(false);
		setisDependantspage(false);
		setisGuardianspage(false);
		setAddress(editdata?.address);
	};
	//Restore Account
	const handleRestore = async () => {
		if (data?.status === "Deleted") await AV.AVEntity.unDelete(data);
		else await AV.AVEntity.unSuspend(data);
		window.location.href = window.location.origin;
	};
	//Validation url
	const validateUrl = e => {
		let url = e.target.value;
		setUrl(e.target.value);
		let options = { require_tld: true, require_protocol: false, require_host: true };
		if (validator.isURL(url, options)) {
			setIsValidUrl(true);
			setMessage("");
		} else {
			setIsValidUrl(false);
			setMessage(`${Loc.currentLang.helpText.invalidUrlMessage}`);
		}
	};

	const openUrl = (url: string) => {
		try {
			const websiteURL = new URL(url);
			if (websiteURL.protocol === "http:" || websiteURL.protocol === "https:") {
				window.open(url);
				return;
			}
		} catch (error) {
			console.log("invalid url");
			url = "http://" + url;
		}
		window.open(url);
	};
	//Useeffect
	useEffect(() => {
		if (currentUser?.status === "NewUser" && data?.id === currentUser?.id) setEditProfile(true);
		setIsUpdate(true);
		async function getUpdatedAddress() {
			if (data?.length !== 0) {
				const p = data; //const p = await AV.Person.get(AV.AVEntity.getFk(data));
				setAddress(p?.address);
				setName(p?.name);
				setfullname(p?.name);
				setIntro(p?.profile?.text);
				setUrl(p?.url);
				setEditData(p);
			}
		}
		setIsUpdate(false);
		getUpdatedAddress();
	}, [isUpdate, data]);

    useEffect( () =>
    {
        setAddress( currentUser?.address );
        setName( currentUser?.name );
        setfullname( currentUser?.name );
        setIntro( currentUser?.profile?.text );
        setUrl( currentUser?.url );
        setLinksList( currentUser?.links )
        setEditData( currentUser );
        setIsEventPage( false );
        setIsTeamsPage( false );
        setIsPhotoPage( false );
        setIsDocumentPage( false );
        setisMembersPage( false );
        setisDependantspage( false );
        setisGuardianspage( false );
    }, [ editProfile ] )
    return (
        <VStack width={ '100%' } >
            <>
                { editProfile && isOwner && (
                    <HStack>
                        <>
                            <VStack width={ '90%' } justifyContent={ 'center' } margin={ 'auto' } marginBottom={ 3 }>
                                <HStack
                                    justifyContent={ isMobile ? 'space-between' : 'flex-end' }
                                    alignItems={ 'center' }
                                    space={ 5 }
                                    marginBottom={ '10px' }
                                    marginTop={ '-27px' }
                                >

                                    { data?.status !== "NewUser" &&
                                        <Button
                                            background={ '#EEEEEE' }
                                            width={ isMobile ? '100px' : '120px' }
                                            height={ '40px' }
                                            left={ isMobile ? '120px' : '' }
                                            rounded='full'
                                            onPress={ () =>
                                            {
                                                setEditProfile( false );
                                                setIsMyPage( true );
                                                setIsEventPage( false );
                                                setIsTeamsPage( false );
                                                setIsPhotoPage( false );
                                                setIsDocumentPage( false );
                                                setisMembersPage( false );
                                                setisDependantspage( false );
                                                setisGuardianspage( false );
                                            } }
                                        >
                                            <Text color={ 'rgba(0, 0, 0, 0.50)' } fontWeight={ 500 }>{ Loc.currentLang.menu.cancel }</Text>
                                        </Button> }

									<AVButtonRoundedFull
										btnText={Loc.currentLang.menu.save}
										isDisabled={
											fullname?.trim()?.length === 0 || address?.zip?.trim()?.length === 0 || address?.zip === undefined
										}
										callback={handleSaveProfile}
										height={"40px"}
										loading={isProfileSaving}
										px={undefined}
										isLoadingText={undefined}
										isLoading={undefined}
									/>
								</HStack>
								<HStack>
									<img src={inform} style={{ width: "20px", height: "20px" }} />
									<Text style={{ marginBottom: "10px", marginLeft: "10px" }}>
										{Loc.currentLang.helpText.editProfile}
									</Text>
								</HStack>
								<VStack space={3} width={"100%"}>
									<HStack space={3} width={"100%"}>
										<Box flex={1} width={"100%"}>
											<AVInputtext
												type={"text"}
												label={Loc.currentLang.menu.name}
												onChange={e => setfullname(e.target.value)}
												value={fullname}
												height={"15px"}
												maxLength={50}
												defaultValue={undefined}
												width={undefined}
												minRows={undefined}
												multiline={undefined}
												size={undefined}
												placeholder={undefined}
											/>
										</Box>
									</HStack>
									<HStack>
										<Box width={"100%"}>
											<AVInputtext
												type={"text"}
												label={Loc.currentLang.placeholderText.personalDescription}
												onChange={e => setIntro(e.target.value)}
												value={intro}
												multiline={true}
												minRows="3"
												maxLength={500}
												size="medium"
												width={"100%"}
												defaultValue={undefined}
												height={undefined}
												placeholder={undefined}
											/>
										</Box>
									</HStack>
									<HStack>
										<Box width={"100%"}>
											<AVInputtext
												type={"text"}
												label={Loc.currentLang.placeholderText.streetAddress}
												onChange={e => setAddress({ ...address, normalized: e.target?.value })}
												value={address?.normalized}
												height={"15px"}
												width={"100%"}
												defaultValue={undefined}
												minRows={undefined}
												multiline={undefined}
												maxLength={undefined}
												size={undefined}
												placeholder={undefined}
											/>
										</Box>
									</HStack>
									<HStack space={3} width={"100%"}>
										<Box flex={1} width={"100%"}>
											<AVInputtext
												type={"text"}
												label={Loc.currentLang.placeholderText.city}
												onChange={e => setAddress({ ...address, city: e.target?.value })}
												value={address?.city}
												height={"15px"}
												defaultValue={undefined}
												width={undefined}
												minRows={undefined}
												multiline={undefined}
												maxLength={undefined}
												size={undefined}
												placeholder={undefined}
											/>
										</Box>
										<Box flex={1} width={"100%"}>
											<AVInputtext
												type={"text"}
												label={Loc.currentLang.placeholderText.state}
												onChange={e => setAddress({ ...address, region: e.target?.value })}
												value={address?.region}
												height={"15px"}
												defaultValue={undefined}
												width={undefined}
												minRows={undefined}
												multiline={undefined}
												maxLength={undefined}
												size={undefined}
												placeholder={undefined}
											/>
										</Box>
										<Box flex={1} width={"100%"}>
											<AVInputtext
												type={"text"}
												label={Loc.currentLang.placeholderText.zip}
												onChange={e => setAddress({ ...address, zip: e.target.value })}
												value={address?.zip}
												height={"15px"}
												defaultValue={undefined}
												width={undefined}
												minRows={undefined}
												multiline={undefined}
												maxLength={undefined}
												size={undefined}
												placeholder={undefined}
											/>
										</Box>
									</HStack>
									<HStack>
										{/*Profile Url*/}
										<Pressable
											width={"100%"}
											zIndex={-3}
											onPress={() => {
												setAddLinkPopup(true);
											}}>
											<HStack>
												<Text color={"#00A7BC"} fontWeight={500}>
													+
												</Text>
												<Text color={"#00A7BC"} fontWeight={500}>
													{Loc.currentLang.menu.addLinks}
												</Text>
											</HStack>
										</Pressable>
										{addLinkPopup && (
											<AddandEditLinks
												setAddLinkPopup={setAddLinkPopup}
												setLinksList={setLinksList}
												linkList={linkList}
												isCreateevent={undefined}
											/>
										)}
									</HStack>
								</VStack>
							</VStack>
						</>
					</HStack>
				)}
			</>
		</VStack>
	);
}

export default React.memo(RenderUserInfo);
