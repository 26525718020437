import React, { FC } from "react";
import AVPostPopup from "../../components/common/AVPostPopup";

interface Props {
    visibilityPermission: string;
    setShowCreatePost: any;
    Postdata: any;
    setFeedChunks: any;
    feedChunks: any;
}

const CreatePostpopup: FC<Props> = ({ setShowCreatePost, Postdata, visibilityPermission, setFeedChunks, feedChunks }) => {

    return (
        <AVPostPopup
            visibilityPermission={visibilityPermission}
            Postdata={Postdata}
            setShowCreatePost={setShowCreatePost}
            type={'Create'}
            setFeedChunks={setFeedChunks}
            feedChunks={feedChunks}
        />
    );
};

export default CreatePostpopup;
