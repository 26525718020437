import React, { useState } from "react";
import { sanitizedText } from "./utils/util";
import { Box, Stack, Text } from "native-base";
import { TouchableOpacity } from "react-native";
import UrlPreview from "./UrlPreview";

const PostText = ({ item }) => {
	const [more, setMore] = useState(false);

	if (!item?.text) return <></>;
	let extracted = sanitizedText(item?.text);
	let text = extracted?.outputString;

	const words = text ? text?.trim().split(/\s+/) : "";
	const wordCount = words.length;
	const truncatedText = wordCount > 70 ? words.slice(0, 66).join(" ") : "";
	if (item.ptype == "H") {

		//const handleLoad = (e) => {
		//	//e.target.style.height = e.target.contentWindow.document.body.scrollHeight + 'px';

		//}
		return (<div className="htmlPost" style={{ maxHeight: '500px',  width: '100%', overflow: 'hidden' }} title="post" dangerouslySetInnerHTML={{ __html: item.text }}></div>);
		//return (<iframe className="htmlPost" width={"100%"} onLoad={handleLoad} title="post" src={"data:text/html," + encodeURIComponent(item.text)}></iframe>);
	}
	return (
		<Stack justifyContent={"center"} width={"100%"}>
			<Text>
				{!more && wordCount > 70 ? `${truncatedText}... ` : text}
				{wordCount > 70 && (
					<TouchableOpacity onPress={() => setMore(!more)}>
						<Text color="#32AE78">{more ? " less" : " more"}</Text>
					</TouchableOpacity>
				)}
			</Text>
			{extracted?.urls?.length > 0 &&
				extracted?.urls?.map((url, i) => {
					return (
						<Box key={i}>
							<UrlPreview url={url} />
						</Box>
					);
				})}
		</Stack>
	);
};

export default PostText;
