import React, { useState } from 'react';
import
{
    VStack, Text, Box, Pressable, Button, CloseIcon, AddIcon, HStack, Spinner, Heading,
} from 'native-base';
import PdfIcon from '../../assets/images/PdfIcon.png';
import docs from '../../assets/images/docs.png';
import video from '../../assets/images/video.png';
import * as AV from 'AVCore/AVCore';
import PreviewAlbums from 'components/common/PreviewAlbums';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import MP3Icon from '../../assets/images/MP3ICON.png';
import AVConfirmationModal from '../../components/common/AVConfirmationModal';
import
{
 VideoIcon
} from '../../assets/icons/icon-assets';
import settings from '../../Platform/settings.json';
import { setMediaList } from '../../Redux/Actions/userActions'

function PhotosAlbum ({ isOwner, data, setText, setImages, Parentdata, setfilesList, setalbumlist, albumlist, filesList, setvideos, setMedia, isEdit, albumdata, setalbumdata, albumdatamedia, setalbumdatamedia, isPhotoPage, isDocumentPage, popup, setpopup, setIsDone, setisEdit
}) {
    const [ pdfUrl, setPdfUrl ] = useState('');
    const [ isOpening, setIsOpening ] = useState(false);
    const [ isConfirmDelete, setisConfirmDelete ] = useState(false);
    const [ Confirmationtext, setConfirmationtext ] = React.useState("Are you sure ?");
    const [ selectType, setselectType ] = React.useState<any>();
    const [ itemSelect, setItemSelect ] = React.useState<any>('');
    const [ isLoader, setLoader ] = useState(false);
    const [ showPreview, setShowPreview ] = useState(false);
    const [ singleImage, setSingleImage ] = useState();
    const [ allImages, setShowAllImage ] = useState([]);
    const dispatch = useDispatch();
    const MediaListItem = useSelector(( state : RootStateOrAny) => state.user.mediaListItem)

    // Function to handle item removal
    const handleRemove = async () => {
        setLoader(true)
        let mediaparam = {
            source: AV.AVEntity.getFk(albumdata),
            item: itemSelect,
        };
        let remove = await AV.Media.removeFromList(mediaparam);
        let MediaData = MediaListItem;
        MediaData = MediaListItem?.filter(( x : any ) => x?.id !== remove?.id);
        console.log("MediaData", MediaData)
        dispatch(setMediaList(MediaData))
        if (remove) {
            setalbumdata(remove);
            const targetList = isDocumentPage ? filesList : albumlist;
            let excitingpost = targetList?.filter((x) => x?.id === remove?.id);
            targetList[ targetList.indexOf(excitingpost[ 0 ]) ] = remove;
            isDocumentPage ? setfilesList([ ...targetList ]) : setalbumlist([ ...targetList ]);
            setalbumdatamedia(remove?.media?.filter((x) => x?.deleted !== true));
        }
        setLoader(false)
        setisConfirmDelete(false);
    };

    const HandleRemoveDocs = (item) => {
        setisConfirmDelete(true)
        setConfirmationtext('Are You Sure? You Want to Delete Your Document');
        setItemSelect(item);
        setselectType('DeleteDocument')
    }
    // Function to handle document press
    const handledocument = async (item) => {
        if (item?.type.startsWith('image') || item?.type.startsWith('video')) {
            const mediaItems = albumdatamedia.filter(
                (item) => item?.type.startsWith('image') || item?.type.startsWith('video')
            );
            openPreview(item, mediaItems);
            return;
        }
        setIsOpening(true);
        let pk = AV.AVEntity.getFk(Parentdata).id;
        let val : any = await AV.Media.getFile(albumdata?.id, item?.id, item?.storeId);
        if (val) {
            setPdfUrl(val);
            var blobUrl = val;
            var fileName = item?.orgFile;
            var a = document.createElement('a');
            a.href = blobUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            //    window.open(val);
        }
        setIsOpening(false);
    };
    // Function to render media items
    const openPreview = (item, items) => {
        setShowPreview(true);
        setSingleImage(item);
        setShowAllImage(items);
    };

    const renderMediaItems = (items, isPhotoPage) => {
        return items?.map((item, i) => (
            <>
                <VStack
                    key={ item.id }
                    bgColor='#fff'
                    marginLeft={ 3 }
                    marginBottom={ 5 }
                    overflow='hidden'
                    width={ 140 }
                    height={ 160 }
                    rounded='lg'
                >
                    { !isPhotoPage ? (
                        <Pressable onPress={ () => handledocument(item) }>
                            <VStack flex={ 1 } justifyContent='center' top='2px'>
                                { item?.type.startsWith('image') ? (
                                    <img
                                        src={ item ? AV.Media.getURL(item) : undefined }
                                        alt=''
                                        style={ {
                                            height: '100px',
                                            width: '100px',
                                            borderRadius: '6px',
                                            objectFit: 'cover',
                                        } }
                                    />
                                ) :
                                    item?.type.startsWith('video') ? (
                                        <Pressable onPress={ () => openPreview(item, items) }>
                                            <HStack position={ 'absolute' } top={ '30%' } left={ '25%' }>
                                                <VideoIcon size={ '35px' } />
                                            </HStack>
                                            <img
                                                src={ item ? AV.Media.getVideoPicURL(item) : undefined }
                                                alt=''
                                                style={ {
                                                    height: '100px',
                                                    width: '100px',
                                                    borderRadius: '6px',
                                                    objectFit: 'cover',
                                                } }
                                            />
                                        </Pressable>
                                    ) : (
                                        <img
                                            src={
                                                item?.type === 'application/pdf'
                                                    ? PdfIcon
                                                    : item?.type === 'application/msword'
                                                        ? docs
                                                        : item?.type === 'audio/wav'
                                                            ? MP3Icon
                                                            : item?.type ===
                                                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                                ? docs
                                                                : item?.type === 'image/jpeg'
                                                                    ? AV.Media.getURL(item)
                                                                    : item?.type === 'video/mp4'
                                                                        ? video :
                                                                        item.type === 'text/plain' ? settings?.file
                                                                            : settings?.file
                                            }
                                            alt=''
                                            style={ {
                                                height: '100px',
                                                width: '100px',
                                                borderRadius: '6px',
                                                objectFit: 'cover',
                                            } }
                                        />
                                    ) }
                                <Text
                                    ellipsizeMode='tail'
                                    numberOfLines={ 1 }
                                    paddingBottom='2px'
                                    paddingLeft='5px'
                                    width={ '110px' }
                                    paddingTop={ '4px' }
                                    fontSize={ 16 }
                                >
                                    { item?.orgFile }
                                </Text>
                            </VStack>
                        </Pressable>
                    ) : (
                        <Pressable onPress={ () => handledocument(item) }>
                            <VStack flex={ 1 } justifyContent='center' top='2px'>
                                { item?.type.startsWith('image') ? (
                                    <img
                                        src={ item ? AV.Media.getURL(item) : undefined }
                                        alt=''
                                        style={ {
                                            height: '100px',
                                            width: '100px',
                                            borderRadius: '6px',
                                            objectFit: 'cover',
                                        } }
                                    />
                                ) :
                                    item?.type.startsWith('video') ? (
                                        <Pressable onPress={ () => openPreview(item, items) }>
                                            <HStack position={ 'absolute' } top={ '30%' } left={ '25%' }>
                                                <VideoIcon size={ '35px' } />
                                            </HStack>
                                            <img
                                                src={ item ? AV.Media.getVideoPicURL(item) : undefined }
                                                alt=''
                                                style={ {
                                                    height: '100px',
                                                    width: '100px',
                                                    borderRadius: '6px',
                                                    objectFit: 'cover',
                                                } }
                                            />
                                        </Pressable>
                                    ) : (
                                        <img
                                            src={
                                                item?.type === 'application/pdf'
                                                    ? PdfIcon
                                                    : item?.type === 'application/msword'
                                                        ? docs
                                                        : item?.type === 'audio/wav'
                                                            ? MP3Icon
                                                            : item?.type ===
                                                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                                ? docs
                                                                : item?.type === 'image/jpeg'
                                                                    ? AV.Media.getURL(item)
                                                                    : item?.type === 'video/mp4'
                                                                        ? video :
                                                                        item.type === 'text/plain' ? settings?.file
                                                                            : settings?.file
                                            }
                                            alt=''
                                            style={ {
                                                height: '100px',
                                                width: '100px',
                                                borderRadius: '6px',
                                                objectFit: 'cover',
                                            } }
                                        />
                                    ) }
                                <Text
                                    ellipsizeMode='tail'
                                    numberOfLines={ 1 }
                                    paddingBottom='2px'
                                    paddingLeft='5px'
                                    width={ '110px' }
                                    paddingTop={ '4px' }
                                    fontSize={ 16 }
                                >
                                    { item?.orgFile }
                                </Text>
                            </VStack>
                        </Pressable>
                    ) }
                    { isEdit && (
                        <Pressable
                            padding={ 1.5 }
                            rounded='full'
                            position='absolute'
                            right={ -3 }
                            top={ -4 }
                            zIndex={ 999 }
                            _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                            backgroundColor='AVColor.buttonBg'
                            onPress={ () => HandleRemoveDocs(item) }
                        >
                            <CloseIcon size='12px' color='AVColor.secondaryText' />
                        </Pressable>
                    ) }
                </VStack>
                { showPreview && (
                    <PreviewAlbums item={ singleImage } items={ allImages } setShowPreview={ setShowPreview } setShowAllImage={ setShowAllImage } />
                ) }
            </>
        ));
    };

    if (isOpening) {
        return (
            <HStack space={ 2 } alignItems='center' justifyContent={ 'center' } w={ '100%' } height={ '300px' }>
                <Spinner accessibilityLabel='Opening docuemnt...' />
                <Heading color='primary.500' fontSize='md'>
                    Opening file...
                </Heading>
            </HStack>
        );
    }

    return (
        <>

            { isConfirmDelete && <AVConfirmationModal showconfirmationPopup={ isConfirmDelete } setshowconfirmationPopup={ setisConfirmDelete }
                Confirmationtext={ Confirmationtext } type={ selectType } item={ itemSelect } function={ handleRemove } isLoader={ isLoader } /> }
            { renderMediaItems(albumdatamedia, isPhotoPage) }
            { isOwner && (
                <Box
                    bgColor='#fff'
                    borderWidth='1px'
                    shadow={ 1 }
                    borderColor='AVColor.hovercolor2'
                    marginLeft={ 3 }
                    marginBottom={ 5 }
                    overflow='hidden'
                    width={ isPhotoPage ? 140 : 130 }
                    height={ isPhotoPage ? 160 : 130 }
                    rounded='lg'
                >
                    <VStack position='absolute' top={ 50 } left='50px'>
                        <Button
                            rounded='full'
                            backgroundColor='#F8F8F8'
                            _hover={ { backgroundColor: 'AVColor.hovercolor' } }
                            shadow={ 1 }
                            p={ 2 }
                            onPress={ () => {
                                setpopup(true);
                                setText(albumdata?.title ? albumdata?.title : albumdata?.name);
                                setImages([]);
                                setvideos([]);
                                setMedia([]);
                                setIsDone(false);
                                setisEdit(false);
                            } }
                        >
                            <AddIcon size='20px' color='#32AE78' />
                        </Button>
                    </VStack>
                </Box>
            ) }
        </>
    );
}
export default PhotosAlbum;
