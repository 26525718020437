import React, { useState, useRef, useLayoutEffect, useCallback } from 'react';
import { VStack } from 'native-base';
import AVFeeds from '../Feeds/AVFeeds';
import { Person } from '../../AVCore/AVCore';
import AVBody from 'components/common/AVBody';
import { useWindowDimension } from 'Utility/WindowDimensions';
import { useLocation } from 'react-router-dom';

function Home ()
{
    const location : any = useLocation();
    const middleRef = useRef<any>( null );
    const [ fixedWidth, setFixedWidth ] = useState( 0 );
    const person = Person.self();
    const dimensions = useWindowDimension();
    const [ showCreateNewPage, setShowCreateNewPage ] = useState( false );
    const [ createType, setCreateType ] = useState( '' );
    const [ isOpenConvo, setIsOpenConvo ] = useState( false );
    const [ isConvoData, setIsConvoData ] = useState( '' );
    const [ showEdit, setShowEdit ] = useState( false );

    // Set the fixed width of the container when the window size changes
    useLayoutEffect( () =>
    {
        if ( middleRef.current )
        {
            const newWidth = middleRef.current.offsetWidth - 2;
            if ( newWidth !== fixedWidth )
            {
                setFixedWidth( newWidth );
            }
        }
    }, [ dimensions.width, fixedWidth ] );

    return (
        <AVBody
            setShowCreateNewPage={ setShowCreateNewPage }
            setIsShowEdit={ setShowEdit }
            showCreateNewPage={ showCreateNewPage }
            setCreateType={ setCreateType }
            createType={ createType }
            passEntity={ undefined }
            setPassEntity={ undefined }
            isOpenConvo={ isOpenConvo }
            setIsOpenConvo={ setIsOpenConvo }
            setIsConvoData={ setIsConvoData }
            isConvoData={ isConvoData }
            setIshuddleData={undefined}
        >
            <VStack flex={ [ 1, 1, 1, 3 ] } ref={ middleRef }>
                <AVFeeds
                    page={ 'Home' }
                    id={ person?.id }
                    offW={ fixedWidth }
                    previewdata={ location?.state?.previewdata }
                />
            </VStack>
        </AVBody>
    );
}

export default React.memo( Home );
