import { Box, HStack, Image, Link, Stack, Text, VStack } from "native-base";
import React, { useEffect, useMemo, useState } from "react";
import { extractDomain } from "./utils/util";

interface UrlPreviewProps {
	url: string; // Define type for 'url' prop
}

interface PreviewData {
	url: string | null;
	title: string | null;
	description: string | null;
	image: string | null;
}

const UrlPreview: React.FC<UrlPreviewProps> = ({ url }) => {
	const domainInfo: any = extractDomain(url);

	const [previewData, setPreviewData] = useState<PreviewData>({
		url: url,
		title: domainInfo?.domain,
		description: domainInfo?.host,
		image: require("../../../assets/icons/ph_link.png"),
	});

	// useEffect(() => {
	// 	fetchPreview();
	// }, []);

	const fetchPreview = useMemo(async () => {
		try {
			//const apiKey = "pk_5d2d790f23ba9d43f239a8d0ff4621e0640918c5";
			//const apiUrl = `https://jsonlink.io/api/extract?url=${url}&api_key=${apiKey}`;

			//const response = await fetch(apiUrl);
			//const result = await response.json();

			//if (!response.ok) {
			//	throw new Error("Failed to fetch URL");
			//}
			//setPreviewData({
			//	url: url,
			//	title: '',
			//	description:'',
			//	image: require("../../../assets/icons/ph_link.png"),
			//});
		} catch (error) {
			console.error("Error fetching preview:", error);
		}
	}, [url]);

	return (
		<Stack p={3}>
			{previewData.title && (
				// @ts-ignore
				<Link href={previewData?.url} isExternal>
					<HStack space={2} borderWidth={1} borderColor={"#e5e5e5"} width="100%" rounded="lg" overflow={"hidden"}>
						<Stack width={120} height={120} bgColor={"#E5E5E5"} alignItems={"center"} justifyContent={"center"}>
							<Image
								source={{
									uri: `${previewData?.image}`,
								}}
								alt="Alternate Text"
								width={120}
								height={120}
								resizeMode="cover"
							/>
						</Stack>
						<VStack space={1} py={2} flex={1}>
							<Text fontWeight={600} fontSize={"lg"} noOfLines={1}>
								{previewData?.title}
							</Text>
							<Text noOfLines={2}>{previewData?.description}</Text>
							<Text noOfLines={1}>{previewData?.url}</Text>
						</VStack>
					</HStack>
				</Link>
			)}
		</Stack>
	);
};

export default UrlPreview;
