import React, { useState, useRef } from "react";
import {
  Box,
  VStack,
  Text,
  HStack,
  Pressable,
  Menu,
  Button,
} from "native-base";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import AVBody from "../../components/common/AVBody";
import Messages from "../../components/header/Messages";
import AVFeeds from "../Feeds/AVFeeds";
import Createalbumpopup from "../Home/Createalbumpopup";
import { setConvo } from "../../Redux/Actions/convoActions";
import _ from "lodash";
import * as AV from "../../AVCore/AVCore";
import { Linking } from "react-native";
import { Media } from "../../AVCore/Media";
import useConvoChat from "../../Hooks/useConvoChat";
import { setEntitydata } from "../../Redux/Actions/userActions";
import AVCancel from "../../components/common/AVCancel";
import AVInputtext from "../../components/AVInputtext";
import { AVBase } from "../../AVCore/AVBase";
import { useLocation } from "react-router-dom";

type TabType = "Chat" | "Posts" | "Files";

const AVHuddles = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(
    (state: RootStateOrAny) => state?.user?.user[0]
  );
  const userconvo = useSelector(
    (state: RootStateOrAny) => state.convo.convo[0]
  );
  const userID = useParams()?.userId;
  const location: any = useLocation();

  const [isHuddleData, setIshuddleData] = useState(
    location?.state?.HuddleData?.id
      ? location?.state?.HuddleData
      : currentUser?.orgs?.filter((x) => x.type === "Channel")?.reverse()[0] ??
          []
  );
  const [activeTab, setActiveTab] = useState<TabType>(
    location?.state?.type ? location?.state?.type : "Chat"
  );
  const [currentHuddleData, setCurrentHuddleData] = useState(null);
  const [isNewChat, setIsNewChat] = useState(false);

  // Add required states for Messages component
  const [showEmoji, setShowEmoji] = useState(false);

  const [EntityData, setEntityData] = useState<any>();
  const [albumlist, setalbumlist] = React.useState([]);
  const [filesList, setfilesList] = React.useState([]);
  const [isSetChatData, setChatData] = useState(false);
  const [allImages, setShowAllImage] = useState([]);
  const [itemHeights, setItemHeights] = useState([]);
  const [singleImage, setSingleImage] = useState();
  const [isHeader, setHeader] = useState(false);
  const [setConvoImage] = React.useState();
  const [showPreview, setShowPreview] = useState(false);
  const [IsOpenConvo, setIsOpenConvo] = useState(false);
  const [convoData, setIsConvoData] = useState(false);
  const [createType, setCreateType] = useState<any>("");
  const [showCreateNewPage, setShowCreateNewPage] = useState<any>(false);
  const [showEdit, setIsShowEdit] = useState(false);

  const entitydatalist = useSelector(
    (state: RootStateOrAny) => state.user.entitydatalist
  );

  // Handle edit popup
  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [showEditPopup, setShowEditPopup] = useState(false);

  const handleCancel = () => {
    setEditTitle(isHuddleData?.title || "Huddle Title");
    setEditDescription("");
    setShowEditPopup(false);
  };

  const handleUpdate = async () => {
    const updatedConvo: any = { ...AVBase.getFk(convo), subject: editTitle };
    const finalConvo = await AV.Convo.update(updatedConvo);
    const index = userconvo?.findIndex((x) => x?.id === finalConvo?.id);

    if (index !== -1) {
      userconvo[index] = finalConvo;
      dispatch(setConvo(userconvo));
    }
  };

  const handleEdit = () => {
    setEditTitle(isHuddleData?.title || "Huddle Title");
    setShowEditPopup(true);
  };

  const {
    textSearchRef,
    mdlCreateConvoVisible,
    setMdlCreateConvoVisible,
    toFK,
    searchInput,
    filteredResults,
    showSearch,
    showFilePicker,
    handleCreateModel,
    searchItems,
    handleSentToProfile,
    handleSendMessage,
    setConvoLoading,
    isLoading,
    handleSubject,
    subject,
    handleMessage,
    handleRemoveItem,
    getImageGalleryFromFilePickerCreateConvo,
    mdlConvoVisible,
    convoSubject,
    setMdlConvoVisible,
    convoPost,
    convo,
    showConvoFilePicker,
    handleGetConvo,
    handleSendConvoMessage,
    replyValue,
    setreplyValue,
    handleChatMessage,
    media,
    value,
    profile,
    setValue,
    Loading,
    images,
    setImages,
    videoUrls,
    setvideos,
    handleImages,
    handleFilePicker,
    onRefresh,
    setSubject,
    settoFK,
    handlePublish,
    setIsHide,
    isHide,
    isImageProcessing,
    setMedia,
    refConvoList,
    setShowConvoFilePicker,
    HandleEditSet,
    handleUpdatePost,
    itemEdit,
    editingImage,
    setEditingImage,
    setConvoPost,
    scrollToMessageWithDelay,
    fetchloading,
    setSearchInput,
    setShowSearch,
    imageInputRef,
    isTyping,
    setConvoSubject,
    setConvos,
    typingUserName,
    setIsOpenBox,
    setIsOpenCemera,
    handleOpenPicker,
    setExternalLink,
    HandleAddOneDrive,
    setShowExternalLinkInput,
    showExternalLinkInput,
    HandleAddDropBoxLink,
    isOpenBox,
    handleCamera,
    isOpenCemera,
    HandleAddExternalUrl,
    handleExternalLink,
    externalLink,
    camera,
    isSetText,
    TriggerOpenConvo,
    setIsTriggerOpenConvo,
    isYoutube,
      setIsYouTube,
      setChatMessage
  } = useConvoChat();

  const handleuserRedirect = async (profile) => {
    navigate(`/profile/${profile?.id}/${profile?.region}`, {
      state: { user: profile },
    });
    setMdlCreateConvoVisible(!mdlCreateConvoVisible);
  };

  const Convoupdate = (convovalue, ConvovalList) => {
    if (
      ConvovalList?.length === 0 ||
      ConvovalList === undefined ||
      ConvovalList === null
    )
      ConvovalList = [];
    for (let i = 0; i < convovalue?.length; i++) {
      let excitingconvo = ConvovalList?.filter(
        (x) => x?.id == convovalue[i]?.id
      );
      if (excitingconvo?.length > 0) {
        for (let j = 0; j < convovalue[i]?.posts?.length; j++) {
          let excitingconvopost = excitingconvo[0]?.posts?.filter(
            (x) => x?.id === convovalue[i]?.posts[j]?.id
          );
          if (excitingconvopost?.length === 0) {
            excitingconvo[0]?.posts.push(convovalue[i]?.posts[j]);
          }
        }
      } else ConvovalList.unshift(convovalue[i]);
    }
    ConvovalList = _.uniqBy(ConvovalList, "id");
    dispatch(setConvo(ConvovalList));
  };

  // Add this handler
  const onEmojiClick = (event, emojiObject) => {
      // Handle emoji click if needed
      const newVal = value + emojiObject.emoji;
      setValue( newVal );
      setChatMessage( newVal )
  };

  const SimpleLinkPreview = ({ url }) => {
    return (
      <div className="link-preview-wrapper">
        <div className="link-preview-image">
          <img src={url?.orgFile} alt="images" />
        </div>
        <div className="link-preview-content">
          <Pressable onPress={() => Linking.openURL(url?.linkURL)}>
            <p className="link-url">{url?.linkURL}</p>
          </Pressable>
        </div>
      </div>
    );
  };

  const RenderImages = ({
    images,
    setImages,
    media,
    setMedia,
    isImageProcessing,
    editingImage,
    setEditingImage,
  }) => {
    React.useEffect(() => {}, [editingImage]);

    let imgmedia: any = [];
    let vidmedia: any = [];

    images?.forEach((img) => {
      if (editingImage) {
        if (images?.length > 0) {
          if (img) {
            imgmedia?.push(img);
          } else {
            vidmedia.push(img);
          }
        }
      } else {
        if (images?.length > 0) {
          if (img) {
            imgmedia?.push(img);
          }
        } else vidmedia.push(img);
      }
    });

    const deleteImage = (index) => {
      const filterImages = images.filter((item, i) => i !== index);
      setImages(filterImages);
      const filterMedia = media.filter((item, i) => i !== index);
      setMedia(filterMedia);
      setEditingImage(false);
    };

    return (
      <VStack px={1}>
        <HStack space={1} alignItems={"center"}></HStack>
        <HStack overflow="scroll" maxWidth={"400px"}>
          {images.map((item, i) => {
            return (
              <Box m={1} width={"120px"} height={"120px"}>
                <Box
                  position={"absolute"}
                  top={1}
                  right={1}
                  bgColor={"white"}
                  rounded={"full"}
                  zIndex={100}
                >
                  {!isImageProcessing && (
                    <AVCancel
                      size={10}
                      callback={() => deleteImage(i)}
                      color={"#000000"}
                    />
                  )}
                </Box>
                {item?.type?.startsWith("image") ||
                (!item?.id &&
                  typeof item === "string" &&
                  item.startsWith("data:image")) ||
                item?.type == "Onedrive" ||
                item?.type == "dropbox" ? (
                  <img
                    src={
                      item?.type === "dropbox"
                        ? item?.orgFile
                        : item?.linkURL
                        ? item?.linkURL
                        : !item?.id &&
                          typeof item === "string" &&
                          item.startsWith("data:image")
                        ? item
                        : Media.getURL(item)
                    }
                    alt=""
                    className="Image"
                    width="100%"
                    height="100%"
                    style={{ objectFit: "cover" }}
                  />
                ) : item?.type === "iframe" ? (
                  <iframe
                    src={item?.linkURL}
                    width="100%"
                    height="100%"
                  ></iframe>
                ) : item?.type == "linkPreview" ? (
                  <SimpleLinkPreview url={item} />
                ) : (
                  <video
                    controls
                    src={item?.linkURL ? item?.linkURL : item}
                    key={i}
                    width={"100%"}
                    height={"100%"}
                  />
                )}
              </Box>
            );
          })}
        </HStack>
      </VStack>
    );
  };

  const getItemLayout = (data, index) => ({
    length: itemHeights[index] || 0, // Use itemHeights[index], if available, otherwise default to 0
    offset: itemHeights
      .slice(0, index)
      .reduce((acc, height) => acc + height, 0),
    index,
  });

  const ReplyLoadMedia = ({ item, isDetail = false }) => {
    const [imageUrls, setImagesUrl] = React.useState<any>([]);
    const [videoUrls, setVideoUrl] = React.useState<any>([]);
    const [media, setMedia] = React.useState<any>([]);
    let videomedia: any = [];
    let imagemedia: any = [];

    React.useEffect(() => {
      setMedia([]);
      if (
        item?.media?.storeId &&
        (item?.galleryPost?.length == 0 ||
          item?.galleryPost?.length == undefined)
      ) {
        imageUrls.push(item?.media);
        setMedia(imageUrls);
      } else if (item?.galleryPost?.length > 0) {
        // Loop through gallery posts and add media
        for (let i = 0; i < item?.galleryPost?.length; i++) {
          imageUrls.push(item?.galleryPost[i].media);
        }
        setMedia(imageUrls);
      }
    }, []);

    return (
      <>
        <VStack px={1}>
          <HStack overflow="scroll" maxWidth={"400px"}>
            {media.map((item, i) => {
              return (
                <Box m={1} width={"100px"} height={"72px"}>
                  <Box
                    position={"absolute"}
                    top={1}
                    right={1}
                    bgColor={"white"}
                    rounded={"full"}
                    zIndex={100}
                  ></Box>
                  {item?.type?.startsWith("image") ||
                  (!item?.id &&
                    typeof item === "string" &&
                    item.startsWith("data:image")) ||
                  item?.type == "Onedrive" ||
                  item?.type == "dropbox" ? (
                    <img
                      src={
                        item?.type === "dropbox"
                          ? item?.orgFile
                          : item?.linkURL
                          ? item?.linkURL
                          : !item?.id &&
                            typeof item === "string" &&
                            item.startsWith("data:image")
                          ? item
                          : Media.getURL(item)
                      }
                      alt=""
                      className="Image"
                      width="100%"
                      height="100%"
                      style={{ objectFit: "cover" }}
                    />
                  ) : item?.type === "iframe" ? (
                    <iframe
                      src={item?.linkURL}
                      width="100%"
                      height="100%"
                    ></iframe>
                  ) : item?.type == "linkPreview" ? (
                    <SimpleLinkPreview url={item} />
                  ) : (
                    <video
                      controls
                      src={item?.linkURL ? item?.linkURL : item}
                      key={i}
                      width={"100%"}
                      height={"100%"}
                    />
                  )}
                </Box>
              );
            })}
          </HStack>
        </VStack>
      </>
    );
  };

  React.useEffect(() => {
    const fetchHuddleData = async () => {
      setConvos([]);
      setConvoPost([]);

      const response: any = await AV.AVEntity.get({
        id: isHuddleData.id,
        type: "Channel",
      });
      setCurrentHuddleData(response);
    };
    fetchHuddleData();
  }, [isHuddleData]);

  React.useEffect(() => {
    console.log("huddleItem", isHuddleData);
    if (isHuddleData?.id?.trim().length > 0) {
      GetEntity();

      if (isHuddleData?.data?.trim().length > 0) {
        let postData = userconvo?.filter((x) => x.id == isHuddleData.data);
        if (postData?.length > 0) {
          handleGetConvo(postData[0]);
        } else {
          getConvo();
        }
      }
    }

    setActiveTab("Chat");
  }, [isHuddleData]);

  const GetEntity = async () => {
    let EntityItem = entitydatalist.filter((x: any) => x.id == isHuddleData.id);

    if (EntityItem?.length == 0) {
      let EntityData: any = await AV.AVEntity.get(isHuddleData);
      setEntityData(EntityData);
      entitydatalist.push(EntityData);
      dispatch(setEntitydata(entitydatalist));
      setfilesList(EntityData?.files);
    } else {
      console.log("EntityItem@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@", EntityItem);
      setEntityData(EntityItem[0]);
      let EntityData: any = await AV.AVEntity.get(isHuddleData);
      entitydatalist[
        entitydatalist.indexOf(
          entitydatalist?.find((x: any) => x?.id === EntityData?.id)
        )
      ] = EntityData;
      dispatch(setEntitydata(entitydatalist));
      setEntityData(EntityData);
      setfilesList(EntityData?.files);
    }
  };

  const getConvo = async () => {
    const convoFeed: AV.IFK = {
      id: isHuddleData?.data,
      pk: isHuddleData?.data,
      region: isHuddleData?.region,
      type: "GroupConvo",
    };

    const param: AV.IFeedParam = {
      feed: convoFeed,
      checkArchive: false,
      startDate: new Date(0),
      forward: true,
    };

    const feedParams: AV.IFeedParam[] = [param];
    const feedsResponse: any = (await AV.Feed.getAllFeeds(
      feedParams
    )) as AV.IConversation[];

    const postList = await AV.Feed.getPostList(feedsResponse[0]);
    if (postList?.length > 0) {
      const posts = await AV.Feed.getPosts(
        AV.AVEntity.getFk(feedsResponse[0]),
        postList?.slice(0, 40)
      );
      feedsResponse[0].posts = posts;
    }

    userconvo?.push(feedsResponse[0]);
    handleGetConvo(feedsResponse[0]);
    dispatch(setConvo(userconvo));
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Chat":
        return (
          <Box flex={1}>
            {isHuddleData?.id?.trim().length > 0 ? (
              <Messages
                camera={camera}
                isSetText={isSetText}
                isHeader={false}
                isNewChat={isNewChat}
                setIsNewChat={setIsNewChat}
                isGroupChat={props?.isGroupChat}
                setIsGroupChat={props?.setIsGroupChat}
                isConvoData={props?.isConvoData}
                setIsConvoData={props?.setIsConvoData}
                setShowEmoji={setShowEmoji}
                RenderImages={RenderImages}
                showEmoji={showEmoji}
                imageInputRef={imageInputRef}
                onEmojiClick={onEmojiClick}
                handleuserRedirect={handleuserRedirect}
                getItemLayout={getItemLayout}
                ReplyLoadMedia={ReplyLoadMedia}
                setHeader={setHeader}
                setShowAllImage={setShowAllImage}
                setSingleImage={setSingleImage}
                setShowPreview={setShowPreview}
                setConvoImage={setConvoImage}
                setItemHeights={setItemHeights}
                itemHeights={itemHeights}
                props={props}
                mdlConvoVisible={mdlConvoVisible}
                textSearchRef={textSearchRef}
                mdlCreateConvoVisible={mdlCreateConvoVisible}
                isTyping={isTyping}
                typingUserName={typingUserName}
                setConvoSubject={setConvoSubject}
                setConvos={setConvos}
                isOpenConvo={props?.isOpenConvo}
                setIsOpenConvo={setIsOpenConvo}
                setMdlCreateConvoVisible={setMdlCreateConvoVisible}
                toFK={toFK}
                searchInput={searchInput}
                filteredResults={filteredResults}
                showSearch={showSearch}
                handleExternalLink={handleExternalLink}
                externalLink={externalLink}
                showFilePicker={showFilePicker}
                handleCreateModel={handleCreateModel}
                searchItems={searchItems}
                handleSentToProfile={handleSentToProfile}
                handleSendMessage={handleSendMessage}
                setConvoLoading={setConvoLoading}
                isLoading={isLoading}
                handleSubject={handleSubject}
                subject={subject}
                setIsdelete={undefined}
                setUpdateConvoImage={undefined}
                handleMessage={handleMessage}
                handleRemoveItem={handleRemoveItem}
                getImageGalleryFromFilePickerCreateConvo={
                  getImageGalleryFromFilePickerCreateConvo
                }
                convoSubject={convoSubject}
                setMdlConvoVisible={setMdlConvoVisible}
                convoPost={convoPost}
                convo={convo}
                showConvoFilePicker={showConvoFilePicker}
                handleGetConvo={handleGetConvo}
                handleSendConvoMessage={handleSendConvoMessage}
                replyValue={replyValue}
                setreplyValue={setreplyValue}
                handleChatMessage={handleChatMessage}
                media={media}
                value={value}
                profile={profile}
                setValue={setValue}
                Loading={Loading}
                images={images}
                setImages={setImages}
                videoUrls={videoUrls}
                isOpenBox={isOpenBox}
                setIsOpenBox={setIsOpenBox}
                setExternalLink={setExternalLink}
                HandleAddOneDrive={HandleAddOneDrive}
                setShowExternalLinkInput={setShowExternalLinkInput}
                showExternalLinkInput={showExternalLinkInput}
                HandleAddDropBoxLink={HandleAddDropBoxLink}
                setIsOpenCemera={setIsOpenCemera}
                handleOpenPicker={handleOpenPicker}
                handleCamera={handleCamera}
                isOpenCemera={isOpenCemera}
                HandleAddExternalUrl={HandleAddExternalUrl}
                setvideos={setvideos}
                handleImages={handleImages}
                handleFilePicker={handleFilePicker}
                onRefresh={onRefresh}
                setSubject={setSubject}
                settoFK={settoFK}
                handlePublish={handlePublish}
                setIsHide={setIsHide}
                isHide={isHide}
                isImageProcessing={isImageProcessing}
                setMedia={setMedia}
                refConvoList={refConvoList}
                setShowConvoFilePicker={setShowConvoFilePicker}
                HandleEditSet={HandleEditSet}
                handleUpdatePost={handleUpdatePost}
                itemEdit={itemEdit}
                isYoutube={isYoutube}
                setIsYouTube={setIsYouTube}
                editingImage={editingImage}
                setSearchInput={setSearchInput}
                setShowSearch={setShowSearch}
                setEditingImage={setEditingImage}
                setConvoPost={setConvoPost}
                fetchloading={fetchloading}
                isHuddleChatTab={true}
              />
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <p>No Huddle data found</p>
              </Box>
            )}
          </Box>
        );
      case "Posts":
        return (
          <Box flex={1}>
            {isHuddleData?.id?.trim().length > 0 &&
            EntityData?.id?.trim().length > 0 ? (
              <AVFeeds
                page={"Profile"}
                isOwner={false}
                userInfo={EntityData}
                id={EntityData?.id}
                setisFeedCount={() => {}}
                isFollowing={false}
                isHuddlePostTab={true}
                {...props}
              />
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <p>No Huddle data found</p>
              </Box>
            )}
          </Box>
        );
      case "Files":
        return (
          <Box flex={1}>
            {isHuddleData?.id?.trim().length > 0 ? (
              <Createalbumpopup
                isDocumentPage={true}
                Parentdata={EntityData}
                isPhotoPage={false}
                albumlist={albumlist}
                isOwner={true}
                setalbumlist={setalbumlist}
                filesList={filesList}
                setfilesList={setfilesList}
                filesListany={undefined}
                editAccess={false}
              />
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <p>No Huddle data found</p>
              </Box>
            )}
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <AVBody
      type="huddlesList"
      setIshuddleData={setIshuddleData}
      setIsOpenConvo={setIsOpenConvo}
      setIsConvoData={setIsConvoData}
      setCreateType={setCreateType}
      createType={createType}
      setShowCreateNewPage={setShowCreateNewPage}
      showCreateNewPage={showCreateNewPage}
      setIsShowEdit={setIsShowEdit}
      {...props}
    >
      <Box
        bgColor={"AVColor.white"}
        borderRadius="8px"
        shadow={4}
        borderColor={"AVColor.buttonBg"}
        height="100%"
        display="flex"
        flexDirection="column"
        position="relative"
      >
        {/* Fixed Header */}
        <Box position="sticky" top="0" bg="white" borderTopRadius="8px">
          <HStack
            justifyContent="space-between"
            alignItems="center"
            px="6"
            py="4"
          >
            <Pressable
              onPress={() => {
                if (!EntityData || EntityData?.parent?.id?.trim().length === 0) {
                  return;
                }

                navigate(
                  `/teams/${EntityData?.parent?.id}/${EntityData?.parent?.region}`,
                  { state: { isCreate: false, team: EntityData?.parent } }
                );
              }}
            >
              <Text fontWeight={"400"} fontSize={"24px"}>
                {isHuddleData.title || "Huddle"}
              </Text>
            </Pressable>
            <Menu
              trigger={(triggerProps) => (
                <Pressable {...triggerProps}>
                  <Text fontSize="20px" color="gray.400">
                    •••
                  </Text>
                </Pressable>
              )}
            >
              <Menu.Item onPress={() => {} /*handleEdit*/}>Edit</Menu.Item>
              <Menu.Item onPress={() => {}}>Delete</Menu.Item>
            </Menu>
          </HStack>

          {/* Fixed Tabs */}
          <HStack px="6" borderBottomWidth="1" borderBottomColor="gray.100">
            {(["Chat", "Posts", "Files"] as TabType[]).map((tab) => (
              <Pressable key={tab} onPress={() => setActiveTab(tab)} mr="8">
                <Box>
                  <Text
                    color={activeTab === tab ? "black" : "gray.600"}
                    fontSize="16px"
                    fontWeight={500}
                    pb="3"
                    borderBottomWidth="2"
                    borderBottomColor={
                      activeTab === tab ? "AVColor.primary" : "transparent"
                    }
                  >
                    {tab}
                  </Text>
                </Box>
              </Pressable>
            ))}
          </HStack>
        </Box>

        {/* Scrollable Content */}
        <Box
          flex={1}
          overflow="auto"
          maxHeight="calc(100vh - 140px)" // Adjust this value based on your header height
          position="relative"
        >
          {renderTabContent()}
        </Box>

        {/* Edit Popup */}
        {showEditPopup && (
          <Box
            position="fixed"
            bgColor={"AVColor.overlay2"}
            top="0"
            right="0"
            bottom="0"
            left="0"
            zIndex={9999}
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={{ base: 3, md: 0 }}
          >
            <Box
              width={{ base: "100%", md: "600px" }}
              bg="white"
              borderRadius="8px"
              mt={{ base: 0, md: "12px" }}
              shadow="lg"
            >
              <VStack p={{ base: 4, md: 6 }} space={{ base: 4, md: 6 }}>
                <HStack mb={{ base: 2, md: 4 }}>
                  <Text
                    fontWeight={400}
                    fontSize={{ base: "24px", md: "30px" }}
                  >
                    Edit a Huddle
                  </Text>
                </HStack>

                <Box mb={{ base: 3, md: 6 }}>
                  <AVInputtext
                    type="text"
                    label=""
                    maxLength={50}
                    onChange={(e) => setEditTitle(e.target.value)}
                    value={editTitle}
                    width="100%"
                  />
                </Box>

                <Box mb={{ base: 3, md: 6 }}>
                  <AVInputtext
                    type="text"
                    label=""
                    maxLength={200}
                    onChange={(e) => setEditDescription(e.target.value)}
                    value={editDescription}
                    height={{ base: "50px", md: "60px" }}
                    minRows={2}
                    multiline={true}
                  />
                </Box>

                <HStack justifyContent="center" space={{ base: 3, md: 6 }}>
                  <Button
                    width={{ base: "30%", md: "20%" }}
                    height={{ base: "36px", md: "40px" }}
                    borderRadius="19px"
                    backgroundColor="#e5e5e5"
                    onPress={handleCancel}
                  >
                    <Text
                      color="rgba(0,0,0,0.5)"
                      fontWeight={500}
                      fontSize={{ base: "12px", md: "14px" }}
                    >
                      CANCEL
                    </Text>
                  </Button>
                  <Button
                    width={{ base: "30%", md: "22%" }}
                    height={{ base: "36px", md: "40px" }}
                    borderRadius="19px"
                    backgroundColor="AVColor.primary"
                    onPress={handleUpdate}
                  >
                    <Text
                      color="white"
                      fontWeight={500}
                      fontSize={{ base: "12px", md: "14px" }}
                    >
                      UPDATE
                    </Text>
                  </Button>
                </HStack>
              </VStack>
            </Box>
          </Box>
        )}
      </Box>
    </AVBody>
  );
};

export default AVHuddles;
