import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  VStack,
  ScrollView,
  HStack,
  Pressable,
  Input,
  Text,
  Button,
  Box,
  Divider,
} from 'native-base';
import { Dimensions } from 'react-native';
import { Person } from '../../AVCore/AVCore';
import Moment from 'moment';
import { setFollowersData } from '../../Redux/Actions/userActions';
import { SearchIcon } from '../../assets/icons/icon-assets';
import * as AVI from '../../AVCore/Interfaces/AV.Interfaces';
import * as AV from '../../AVCore/AVCore';
import { useLocation, useNavigate } from 'react-router-dom';
import AVBody from 'components/common/AVBody';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Loc } from '../../App/Loc';
import ProfilePic from '../common/ProfilePicture';
import { MoreHorizontalIcon, Profile } from '../../assets/icons/icon-assets';
import useFollowRequest from '../../Hooks/useFollowRequest';
import useAlerts from '../../Hooks/useAlerts';
import _ from 'lodash';
import AVConfirmationModal from '../common/AVConfirmationModal';


function ManageFollowings (props : any) {
  const dispatch = useDispatch();
  const user = useSelector((state : RootStateOrAny) => state?.user?.user[ 0 ]);
  const [ isMyFollowing, setIsMyFollowing ] = useState(true);
  const [ isBlockedList, setIsBlockedList ] = useState(false);
  const [ isFollowers, setIsFollowers ] = useState(false);
  const [ data, setdata ] = React.useState();
  const stateUser = useSelector((state) => state);
  const useralertList = useSelector((state : RootStateOrAny) => state?.alerts.alert[ 0 ]);
  const [ BlockedData, setIsBlockedData ] = useState();
  const followersdatalist = useSelector((state : RootStateOrAny) => state?.user?.followersdatalist);
  /*const followreqlist = useralertList?.filter(x => x?.actionTaken === undefined && x?.alertType !== "NewRequest" && (x?.data?.type === 'Follow' || x?.type === 'Follow'));*/

  /*   const uniqueArray = _.uniqBy(user?.requests, item => item?.source?.id);*/
  const [ isFollowerList, setIsFollowerList ] = useState<any>();
  const [ managerequestdata, setManageRequestdata ] = React.useState(user?.follows);
  const navigate = useNavigate();
  const [ followersLoader, setIsFollowersLoader ] = useState(false);
  const [ showCreateNewPage, setShowCreateNewPage ] = useState<any>(false);
  const [ createType, setCreateType ] = useState<any>("");
  const entitydatalist = useSelector((state : RootStateOrAny) => state.user.entitydatalist);
    const currentUser = useSelector( ( state : RootStateOrAny ) => state.user.user[ 0 ] );
    const [ isOpenConvo, setIsOpenConvo ] = useState( false );
    const [ isConvoData, setIsConvoData ] = useState( '' )



    React.useEffect(() => {
        async function fetchFollowers() {
            setIsBlockedUser(user?.blocked);
            setIsBlockedData(user?.blocked)
            setManageRequestdata(user?.follows);
        }
        fetchFollowers();
    }, [ user, user?.follows, followersdatalist ]);
    React.useEffect( () =>
    {
        const fetch = async () =>
        {
            await AV.AVEntity.getMyFollowers( user ).then( e =>
            {
                if ( e?.followers?.length !== requestdata?.length )
                {
                    const uniqueFollowReq = _.uniqBy( e.followers, item => item?.id )
                    setrequestdata( uniqueFollowReq?.reverse() )
                    setIsFollowerList( uniqueFollowReq?.reverse() )
                    dispatch( setFollowersData( uniqueFollowReq?.reverse() ) );
                }
            } );
        }
        if (isFollowers) {
            setIsFollowersLoader( true )
            fetch();
            setIsFollowersLoader(false);
        }
      
    }, [isFollowers])

  const dimensions = Dimensions.get('window');
  const isMobile = dimensions?.width <= 500 || false;

  const getPlaceholderText = () => {
    if (isMyFollowing) return Loc.currentLang.placeholderText.searchFollowings;
    if (isFollowers) return Loc.currentLang.menu.searchFollowers;
    if (isBlockedList) return Loc.currentLang.menu.search.replace(/\.*$/, '') + ' ' + Loc.currentLang.menu.blocked;
    return Loc.currentLang.placeholderText.searchFollowings;
  };


  const [ Confirmationtext, setConfirmationtext ] = React.useState("Are you sure ?");
  const [ showconfirmationPopup, setshowconfirmationPopup ] = React.useState(false);
  const [ selectType, setselectType ] = React.useState<any>();
  const [ searchInput, setSearchInput ] = useState( '' );
  const [ selectItem, setselectItem ] = React.useState(false);
    const searchfollowing = async ( val, isFollowers ) =>
    {
        setSearchInput(val)
        if (isFollowers) {
            setrequestdata( val?.trim()?.length > 0 ? 
                requestdata?.filter(
                    (x) =>
                        (x?.source?.title !== undefined &&
                            x?.source?.title?.toLocaleLowerCase().includes(val.toLocaleLowerCase())) ||
                        (x?.target?.title !== undefined &&
                            x?.target?.title?.toLocaleLowerCase().includes(val.toLocaleLowerCase())) ||
                        (x?.title !== undefined &&
                            x?.title?.toLocaleLowerCase().includes(val.toLocaleLowerCase()))
                ) : isFollowerList
            );
        }
        else if (isMyFollowing) {
            setManageRequestdata(
                user?.follows?.filter(
                    (x) =>
                        (x?.target?.title !== undefined &&
                            x?.target?.title?.toLocaleLowerCase().includes(val.toLocaleLowerCase())) ||
                        (x?.title !== undefined &&
                            x?.title?.toLocaleLowerCase().includes(val.toLocaleLowerCase())) ||
                        (x?.data !== undefined &&
                            x?.data?.toLocaleLowerCase().includes(val.toLocaleLowerCase()))
                )
            );
        }
         else {
            setIsBlockedUser(val?.trim()?.length > 0 ? 
            blockedUser?.filter(
                (x) =>
                    (x?.target?.title !== undefined &&
                        x?.target?.title?.toLocaleLowerCase().includes(val.toLocaleLowerCase())) ||
                    (x?.title !== undefined &&
                        x?.title?.toLocaleLowerCase().includes(val.toLocaleLowerCase())) ||
                    (x?.data !== undefined &&
                        x?.data?.toLocaleLowerCase().includes(val.toLocaleLowerCase())) 
                ) : BlockedData
        );
    }
    };


    useEffect( () =>
    {
        // Function to handle tab switch - clearing search input
        const handleTabSwitch = () =>
        {
            setSearchInput( '' ); // Reset search input when tab is switched
        };

        // Event listeners for visibility change
        window.addEventListener( 'visibilitychange', handleTabSwitch );

        // Clean up the event listener on component unmount
        return () =>
        {
            window.removeEventListener( 'visibilitychange', handleTabSwitch );
        };
    }, [] );
    const menuRefs = useRef(null);
    useOutsideAlerter(menuRefs);
    function useOutsideAlerter (ref) {
        React.useEffect(() => {
            function handleClickOutside (event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsMenu(false);
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ ref ]);
    }

  const {
    unfollowLoader, setunfollowLoader
  } = useFollowRequest();

  const handleconfirmation = async (item : any, type : any) => {
    setshowconfirmationPopup(true);
    setConfirmationtext(type === "UNFolloMe" ? Loc.currentLang.menu.unfollowmeConfirmText : type == "UNBlock" ? Loc.currentLang.menu.unblockConfirmText : Loc.currentLang.menu.blockConfirmText + " " + item?.title + "?");
    setselectType(type); setselectItem(item);
  }
  const handleUnFollow = async (item : any) => {
    setshowconfirmationPopup(true);
    setConfirmationtext(Loc.currentLang.menu.unfollowConfirmText + " " + item?.title + "?");
    setselectType("UNFollow"); setselectItem(item);
  }

  const {
    handleAlert,
    Loader, setLoader,
    requestdata, setrequestdata,
    blockedUser, setIsBlockedUser,
    isMenu, setIsMenu,
  } = useAlerts();
  const [ showEdit, setIsShowEdit ] = useState(false);
  return (
    <AVBody
          setShowCreateNewPage={ setShowCreateNewPage } showCreateNewPage={ showCreateNewPage } setCreateType={ setCreateType } createType={ createType } passEntity={ undefined } setIsShowEdit={ setIsShowEdit } setPassEntity={ undefined } isOpenConvo={ isOpenConvo }
          setIsOpenConvo={ setIsOpenConvo } setIsConvoData={ setIsConvoData } isConvoData={ isConvoData } setIshuddleData={undefined}
    >
      <VStack>
        <Box bgColor='#fff' shadow='1' height={ '150px' } borderRadius={ 10 }>
          <VStack padding={ '24px' }>
            <HStack alignItems={ 'center' } space={ 3 } flex={ 1 } justifyContent={ 'space-between' }>
              { isMobile ? "" : <Text fontSize={ '24px' } fontWeight={ 500 } text-overflow={ 'ellipsis' } numberOfLines={ 1 }>
                { Loc.currentLang.menu.hello },  <Text color={ 'AVColor.primary' } width={ '200px' } fontSize={ '24px' } fontWeight={ 500 } text-overflow={ 'ellipsis' } numberOfLines={ 1 }>{ Person.getLink(Person.self())?.title }!</Text>
              </Text>
              }
              <HStack alignItems={ 'center' } justifyContent={ 'flex-end' } right={ isMobile ? '30px' : '' } space={ 5 }>
                <Text fontSize={ [ 12, 14 ] } color='AVColor.textLite'>
                  { Moment().format('MMMM D, Y') }
                </Text>

                                <Input
                                    width={isMobile ? "200px" : "267px"}
                                    height={'34px'}
                                    overflow={'hidden'}
                                    borderWidth={ 0 }
                                    value={ searchInput }
                                    placeholder={ getPlaceholderText() }
                                    variant='AVSearchInput'
                                    placeholderTextColor='AVColor.textLite'
                                    backgroundColor={ '#F5F5F5' }
                                    borderRadius={ '22px' }
                                    fontSize={ '16px' }
                                    InputLeftElement={
                                        <SearchIcon ml='2' size='24px' color='#000' left={ '10px' } p={ '7px' } />
                                    }
                                    onChangeText={ (e) => searchfollowing(e, isFollowers) }
                                />
                            </HStack>
                        </HStack>
                    </VStack>
                    <HStack flexDirection={'row'} space={8} position={'absolute'} left={isMobile ? '15px' :'30px' } bottom={ '-8px' }>
                        <HStack
                            borderBottomColor={ 'AVColor.primary' }
                            pb={ 2 }
                        >
                            <Pressable
                                onPress={ () =>
                                {
                                    setIsMyFollowing( true ); setIsFollowers( false ); setIsBlockedList( false ); setSearchInput( '' );
                                   // setrequestdata( isFollowerList )
                                    setManageRequestdata( user?.follows )
                                    setIsBlockedUser( BlockedData ) } }
                            >
                                <Text fontSize={isMobile ? '14px' : '18px'} fontWeight={500} paddingBottom={'10px'} borderBottomWidth={isMyFollowing ? 4 : ""} borderBottomColor={'AVColor.primary'} color={isMyFollowing ? 'rgba(0, 0, 0, 0.85)' :'rgba(0, 0, 0, 0.50)'}>
                                    { Loc.currentLang.menu.followings }({ managerequestdata?.length })
                                </Text>
                            </Pressable>
                        </HStack>
                        <HStack
                            borderBottomColor={ 'AVColor.primary' }
                            pb={ 2 }
                        >
                            <Pressable
                                onPress={ () =>
                                {
                                    setIsFollowers( true ); setIsMyFollowing( false ); setIsBlockedList( false ); setSearchInput( '' );
                                   // setrequestdata( isFollowerList )
                                    setManageRequestdata( user?.follows )
                                    setIsBlockedUser( BlockedData ) } }
                            >
                                <Text fontSize={isMobile ? '14px' : '18px'} fontWeight={500} paddingBottom={'10px'} borderBottomWidth={isFollowers ? 4 : ""} borderBottomColor={'AVColor.primary'} color={isFollowers ? 'rgba(0, 0, 0, 0.85)' : 'rgba(0, 0, 0, 0.50)'}>
                                    { Loc.currentLang.menu.followers }({ requestdata?.length ? requestdata?.length : 0 })
                                </Text>
                            </Pressable>
                        </HStack>
                        <HStack
                            borderBottomColor={'AVColor.primary'}
                            pb={2}
                        >
                            <Pressable
                                onPress={ () =>
                                {
                                    setIsFollowers( false ); setIsMyFollowing( false ); setIsBlockedList( true ); setSearchInput( '' );
                                    //setrequestdata( isFollowerList )
                                    setManageRequestdata( user?.follows )
                                    setIsBlockedUser( BlockedData ) }}
                            >
                                <Text fontSize={isMobile ? '14px' : '18px'} fontWeight={500} paddingBottom={'10px'} borderBottomWidth={isBlockedList ? 4 : ""} borderBottomColor={'AVColor.primary'} color={isBlockedList ? 'rgba(0, 0, 0, 0.85)' : 'rgba(0, 0, 0, 0.50)'}>
                                    {Loc.currentLang.menu.blocked}({blockedUser?.length})
                                </Text>
                            </Pressable>
                        </HStack>
                    </HStack>
                </Box>
                <ScrollView>
                    {isMyFollowing && !isFollowers && !isBlockedList &&
                        <Box bgColor='#fff' shadow='1' borderRadius={10} marginTop={'20px'} marginBottom={'20px'}>
                            <Text fontSize={'24px'} paddingX={'24px'} paddingTop={'24px'} fontWeight={500}>{ Loc.currentLang.menu.followings }</Text>
                        {managerequestdata?.length > 0 &&
                        managerequestdata?.map((item) => (
                            <>
                                <Pressable
                                    height={'118px'}
                                    key={item.id}
                                    _hover={{ bgColor: 'gray.100' }}
                                    onPress={() => {
                                        item?.type == 'Person' 
                                            ? navigate( `/profile/${ item?.id }/${ item?.region }`, {
                                                state: {
                                                    user: {
                                                        id: item?.id,
                                                        pk: item?.pk,
                                                        region: item?.region,
                                                        type: item?.type,
                                                        name: item?.title,
                                                    },
                                                },
                                            })
                                            : item?.type == 'PlayerOrg'
                                                ? navigate(`/season/${item?.id}/${item?.region}`, { state: { isCreate: false, team: item } })
                                                : item?.type == 'Team' ? navigate( `/teams/${ item?.id }/${ item?.region }`, { state: { isCreate: false, team: item } } ) :
                                                    navigate( `/organizations/${ item?.id }/${ item?.region }/${ item?.type }`, {
                                                        state: { isCreate: false, orgType: item?.type, org: item },
                                                    } )
                                                  
                                    }}
                                >
                                    <Box padding={'24px'} height={'100%'}>
                                        <HStack space={'24px'}>
                                  { item && (<ProfilePic width={ "70px" } height={ "70px" } link={
                                    currentUser?.id == item?.id ?
                                      AV.AVEntity.getLink(currentUser) :
                                      entitydatalist?.find((x : any) => x?.id == item?.id) ?
                                        AV.AVEntity.getLink(entitydatalist?.find((x : any) => x?.id == item?.id)) : item } />)}
                                            <VStack justifyContent={'flex-start'} flex={1}>
                                                <Text fontWeight={500} ellipsizeMode='tail' textTransform={'capitalize'} fontSize={'18px'} marginBottom={'12px'} numberOfLines={1} width={'57%'}>
                                                    {item?.data ? item?.data : item?.season ? item?.season : item?.title}
                                                </Text>
                                                <Text color={'rgba(0, 0, 0, 0.50)'} fontSize={'16px'} ellipsizeMode='tail' textTransform={'capitalize'} fontWeight={400} numberOfLines={1}  width={'57%'}>
                                                    {item?.data ? item?.title : ''}
                                                </Text>
                                                {/*<Tooltip label={item.blurb} openDelay={500}>*/}
                                                {/*    <Text textTransform={'capitalize'} fontSize={'12px'}>{item.blurb}</Text>*/}
                                                {/*</Tooltip>*/}
                                            </VStack>
                                        </HStack>
                                    </Box>
                                    {isMyFollowing && (<Button onPress={() => handleUnFollow(item)} isLoadingText={isMobile ? '' : Loc.currentLang.menu.stopfollowingLoad} isLoading={(item === unfollowLoader) ? true : false} height={'40px'} width={isMobile ? '80px' : '198px'} position={'absolute'} top={'25px'} right={'40px'} rounded={'full'} bgColor={(item === unfollowLoader) ? 'AVColor.primary' : '#EEEEEE'}>
                                        <Text fontWeight={500} color='#00A7BC' fontSize={'16px'}>{isMobile ? 'Stop' : Loc.currentLang.menu.stopFollowing}</Text>
                                    </Button>)}
                                </Pressable>
                            <Divider />
                            </>
                            

                )) }
              { managerequestdata?.length < 1 && <HStack paddingBottom={ '14px' } justifyContent={ 'center' }> { Loc.currentLang.menu.noFollowingsFound }</HStack> }
            </Box> }
        </ScrollView>
        <ScrollView>
          { !isMyFollowing && isFollowers && !isBlockedList &&
            <Box bgColor='#fff' shadow='1' borderRadius={ 10 } marginTop={ '20px' } marginBottom={ '20px' } zIndex={ -2 }>
              <HStack space={ 5 }>
                <Text fontSize={ '24px' } paddingX={ '24px' } paddingTop={ '24px' } fontWeight={ 500 } zIndex={ -3 }>{ Loc.currentLang.menu.followers }</Text>
                { followersLoader && (<HStack paddingTop={ '35px' }>
                  <div className='loader'></div>
                </HStack>) }
              </HStack>
              { requestdata?.length > 0 &&
                requestdata?.map((item) => (
                  <Pressable
                    zIndex={ -2 }
                    height={ '76px' }
                    key={ item?.source?.id }
                    _hover={ { bgColor: 'gray.100', zIndex: -3 } }
                    onPress={ () => {
                      item?.source?.type == 'Club' || item?.source?.type == 'School'
                        ? navigate(`/organizations/${ item?.source?.id ? item?.source?.id : item?.target?.id }/${ item?.source?.region ? item?.source?.region : item?.target?.region }/${ item?.source?.type ? item?.source?.type : item?.target?.type }`, {
                          state: { isCreate: false, orgType: item?.type, org: item?.source ? item?.source : item?.target },
                        })
                          : item?.source?.type == 'PlayerOrg'
                          ? navigate(`/season/${ item?.source?.id ? item?.source?.id : item?.target?.id }/${ item?.source?.region ? item?.source?.region : item?.target?.region }`, { state: { isCreate: false, team: item?.source ? item?.source : item?.target } })
                          : item?.source?.type == 'Team' ? navigate(`/teams/${ item?.source?.id ? item?.source?.id : item?.target?.id }/${ item?.source?.region ? item?.source?.region : item?.target?.region }`, { state: { isCreate: false, team: item?.source ? item?.source : item?.target } }) :
                            navigate(`/profile/${ item?.source?.id ? item?.source?.id : item?.id }/${ item?.source?.region ? item?.source?.region : item?.region }`, {
                              state: {
                                user: {
                                  id: item?.source?.id ? item?.source?.id : item?.id,
                                  pk: item?.source?.pk ? item?.source?.pk : item?.pk,
                                  region: item?.source?.region ? item?.source?.region : item?.region,
                                  type: item?.source?.type ? item?.source?.type : item?.type,
                                  name: item?.source?.title ? item?.source?.title : item?.title,
                                },
                              },
                            });
                    } }
                  >
                    <Box padding={ '24px' } height={ '100%' } zIndex={ -2 }>
                      <HStack space={ '24px' } zIndex={ -2 }>
                        <VStack justifyContent={ 'flex-start' } flex={ 1 }>
                          <Text fontWeight={ 500 } ellipsizeMode='tail' textTransform={ 'capitalize' } fontSize={ '18px' } marginBottom={ '12px' } numberOfLines={ 1 } width={ '82%' }>
                            { item?.source?.title ? item?.source?.title : item?.title ? item?.title : item?.target?.title }
                          </Text>
                          {/* <Text textTransform={ 'capitalize' } marginLeft={ '18px' } position={ 'absolute' } top={ '40px' } fontSize={ '12px' } ellipsizeMode='tail' width={ '82%' } numberOfLines={ 1 }>{ item?.alertType === "NewRequestToStaff" ? Loc.currentLang.menu.followyour + "  " + item?.data?.target?.title : item?.type ? item?.type : item?.data?.type }  { Loc.currentLang.menu.request }</Text>      */ }
                        </VStack>
                      </HStack>

                      <Box position={ 'absolute' }
                        top={ '25px' }
                        right={ '40px' }>
                        <Pressable
                          onPress={ () => { setIsMenu(!isMenu); setdata(item) } }
                          rounded={ 'full' }
                          _hover={ { bgColor: '#EEEEEE' } }
                          zIndex={ 999 }
                        >
                          <MoreHorizontalIcon size='25px' color={ 'AVColor.black' } />
                        </Pressable>
                      </Box>
                      { (isMenu && data === item) && (<VStack
                        ref={ menuRefs }
                        position={ 'absolute' }
                        top={ '0px' }
                        right={ '75px' }
                        backgroundColor={ 'AVColor.white' }
                        shadow={ 1 }
                        borderRadius={ 10 }
                        minWidth={ '117px' }
                        zIndex={ 999 }
                      >
                        { (user?.follows?.filter(x => x?.id === item?.id)?.length == 0) &&
                          (<Button
                            paddingTop={ '5px' }
                            paddingBottom={ '0px' }
                            justifyContent={ 'flex-start' }
                            _hover={ { bgColor: '#EEEEEE', zIndex: -3 } }
                            isDisabled={ user?.myRequests?.filter((x : any) => x?.target?.id == item?.id)?.length > 0 ? true : false }
                            isLoading={ (item === Loader?.alert && Loader?.type === 'followback') ? true : false }
                            bgColor={ (item === Loader?.alert && Loader?.type === 'followback') ? '#32AE78' : 'AVColor.white' }
                            onPress={ () => handleAlert(item, 'followback') }
                            isLoadingText={ "followback..." }
                            zIndex={ 999 }
                          >
                            <Text zIndex={ 999 }>{ Loc.currentLang.follow.followback }</Text>
                          </Button>) }
                        <Button
                          paddingTop={ '5px' }
                          justifyContent={ 'flex-start' }
                          paddingBottom={ '0px' }
                          _hover={ { bgColor: '#EEEEEE' } }
                          bgColor={ (item === Loader?.alert && Loader?.type === 'unFollowMe') ? '#32AE78' : 'AVColor.white' }
                          onPress={ () => { handleconfirmation(item, 'UNFolloMe'); } }
                          isLoading={ (item === Loader?.alert && Loader?.type === 'unFollowMe') ? true : false }
                          isLoadingText='unFollowMe...'
                          zIndex={ 999 }
                        >
                          <Text zIndex={ 999 }>{ Loc.currentLang.follow.unfollowme }</Text>
                        </Button>
                        { (user?.blocked?.filter(x => x?.id === item?.id)?.length == 0) &&
                          (<Button
                            paddingTop={ '5px' }
                            justifyContent={ 'flex-start' }
                            paddingBottom={ '0px' }
                            _hover={ { bgColor: '#EEEEEE' } }
                            bgColor={ (item === Loader?.alert && Loader?.type === 'block') ? '#32AE78' : 'AVColor.white' }
                            onPress={ () => handleconfirmation(item, 'Block') }
                            isLoading={ (item === Loader?.alert && Loader?.type === 'block') ? true : false }
                            isLoadingText={ Loc.currentLang.menu.blockLoad }
                            zIndex={ 999 }
                          >
                            <Text zIndex={ 999 }>{ Loc.currentLang.follow.block }</Text>
                          </Button>) }
                      </VStack>) }
                    </Box>

                  </Pressable>

                )) }
              { (requestdata?.length < 1 || requestdata?.length == undefined) && <HStack paddingBottom={ '14px' } justifyContent={ 'center' }> { Loc.currentLang.menu.noFollowersFound }</HStack> }
            </Box> }
        </ScrollView>
        <ScrollView>
          { !isMyFollowing && isBlockedList && !isFollowers &&
            <Box bgColor='#fff' shadow='1' borderRadius={ 10 } marginTop={ '20px' } marginBottom={ '20px' }>
              <Text fontSize={ '24px' } paddingX={ '24px' } paddingTop={ '24px' } fontWeight={ 500 }>{ Loc.currentLang.menu.blocked }</Text>
              { blockedUser?.length > 0 &&
                blockedUser?.map((item) => (
                  <>
                    <Pressable
                      zIndex={ -2 }
                      height={ '118px' }
                      key={ item?.source?.id }
                      _hover={ { bgColor: '#EEEEEE' } }
                    >
                      <Box padding={ '24px' } height={ '100%' } zIndex={ -2 }>
                        <HStack space={ '24px' } zIndex={ -2 }>
                          <ProfilePic width={ "70px" } height={ "70px" } link={ item?.source ? item?.source : item?.target ? item?.target : item } />
                          <VStack justifyContent={ 'flex-start' } flex={ 1 }>
                            <Text fontWeight={ 500 } ellipsizeMode='tail' textTransform={ 'capitalize' } fontSize={ '18px' } marginBottom={ '12px' } numberOfLines={ 1 } width={ '82%' }>
                              { item?.source?.title ? item?.source?.title : item?.title ? item?.title : item?.target?.title }
                            </Text>
                            {/* <Text textTransform={'capitalize'} marginLeft={'18px'} position={'absolute'} top={'40px'} fontSize={'12px'} ellipsizeMode='tail' width={'82%'} numberOfLines={1}>{item?.alertType === "NewRequestToStaff" ? Loc.currentLang.menu.followyour + "  " + item?.data?.target?.title : item?.type ? item?.type : item?.data?.type}  {Loc.currentLang.menu.request}</Text>*/ }
                          </VStack>
                        </HStack>

                        <Box position={ 'absolute' }
                          top={ '24px' }
                          right={ '24px' }>
                          <Pressable
                            onPress={ () => { setIsMenu(!isMenu); setdata(item) } }
                            rounded={ 'full' }
                            zIndex={ 999 }
                          >
                            <MoreHorizontalIcon size='28px' color={ 'AVColor.black' } />
                          </Pressable>
                        </Box>
                        { (isMenu && data === item) && (<VStack
                          ref={ menuRefs }
                          position={ 'absolute' }
                          top={ '50px' }
                          right={ '30px' }
                          backgroundColor={ 'AVColor.white' }
                          shadow={ 1 }
                          borderRadius={ 10 }
                          minWidth={ '117px' }
                          zIndex={ 999 }
                        >
                          <Button
                            paddingY={ '10px' }
                            shadow={ 10 }
                            paddingLeft={ '22px' }
                            _hover={ { bgColor: 'gray.100' } }
                            bgColor={ (item === Loader?.alert && Loader?.type === 'unBlock') ? '#32AE78' : 'AVColor.white' }
                            onPress={ () => { handleconfirmation(item, 'UNBlock'); } }
                            isLoading={ (item === Loader?.alert && Loader?.type === 'unBlock') ? true : false }
                            isLoadingText='unBlock...'
                            zIndex={ 999 }
                          >
                            <Text zIndex={ 999 }>{ Loc.currentLang.follow.unBlock }</Text>
                          </Button>
                        </VStack>) }
                      </Box>

                    </Pressable>
                    <Divider />
                  </>
                )) }
              { blockedUser?.length < 1 && <HStack paddingBottom={ '14px' } justifyContent={ 'center' }> { Loc.currentLang.menu.noRecordsFound }</HStack> }
            </Box> }
        </ScrollView>
      </VStack>
      { showconfirmationPopup && <AVConfirmationModal showconfirmationPopup={ showconfirmationPopup } setshowconfirmationPopup={ setshowconfirmationPopup }
        Confirmationtext={ Confirmationtext } type={ selectType } item={ selectItem } /> }
    </AVBody>
  );
}

export default ManageFollowings;