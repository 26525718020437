//@ts-nocheck
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowDimension } from 'Utility/WindowDimensions';
import { Box, Button, Center, Image } from 'native-base';
import { FeedsLoader, Loader } from 'components/loaders';
import CreatePostpopup from 'Screens/Home/CreatePostpopup';
import Offline from 'react-offline';
import msg from "../../assets/images/create-message.png";
import { Loc } from "../../App/Loc";
import Card from "../../components/posts/Card";
import useFeeds from '../../Hooks/useFeeds';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import { store } from '../../Redux/store';
import { setFeedsScrollUpdate, setFeedsUpdate, setFeeds, setFetchProcess } from '../../Redux/Actions/feedsAction';
import useAlerts from "../../Hooks/useAlerts";

// Helper Hook to handle post permissions logic
const usePostPermissions = (userInfo, currentUser) => {
    const isMember = userInfo?.members?.some((x) => x?.id === currentUser?.id);
    const isPlayerOfEntity = userInfo?.players?.some((x) => x?.id === currentUser?.id);
    const isAllowMemberPost = isMember && userInfo?.preferences?.memberPost !== "N";
    const isAllowPlayerPost = isPlayerOfEntity && userInfo?.preferences?.playerPost !== "N";
    const visibilityPermission = isMember
        ? userInfo?.preferences?.memberPost
        : isPlayerOfEntity
            ? userInfo?.preferences?.playerPost
            : "F";
    return {
        isAllowMemberPost,
        isAllowPlayerPost,
        visibilityPermission,
    };
};

const AVFeeds = (props) => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.user[0]);
    const userFeeds = useSelector((state) => state.feeds.feeds[0]) ?? [];
    const { userInfo } = props;
    const [isLoadingNewPosts, setIsLoadingNewPosts] = useState(false);
    const isMemberOfEntity = userInfo?.members?.some((x) => x?.id === currentUser?.id);
    const isPlayerOfEntity = userInfo?.players?.some((x) => x?.id === currentUser?.id);
    const isstaffOfEntity = userInfo?.staff?.some((x) => x?.id === currentUser?.id);
    const isParticipantOfEntity = userInfo?.participants?.some((x) => x?.id === currentUser?.id);
    const isCurrentUser = userInfo?.id === currentUser?.id
    const isOwner = isstaffOfEntity || isCurrentUser || isParticipantOfEntity;
    const isOneOfEntity = isMemberOfEntity || isPlayerOfEntity || isstaffOfEntity || isCurrentUser;
    let IsVisible = userInfo?.id == '78b5b063-fd3e-4925-92bd-2a1888c6a46a' || userInfo?.id == 'b495d4e0-bf52-4690-8dc0-677db954aa7f' || userInfo?.id == 'b495d4e0-bf52-4690-8dc0-677db954aa71' || userInfo?.id == 'b495d4e0-bf52-4690-8dc0-677db954aa72' ? 'P' : isOwner ? 'B' : !isOneOfEntity && props?.page !== "Home" ? 'N' : props?.page == "Home" ? 'B' : props?.Postdata?.preferences?.memberPost ? props?.Postdata?.preferences?.memberPost : userInfo?.preferences?.memberPost;
    IsVisible = userInfo?.type == "Channel" ? "B" : IsVisible;
    console.log("IsVisible", IsVisible)
    const { isAllowMemberPost, isAllowPlayerPost, visibilityPermission } = usePostPermissions(userInfo, currentUser);
    const [showModal, setShowModal] = useState(false);
    const [processedAllPostsInRedux, setProcessedAllPostsInRedux] = useState(false);
    const [isFetchingNextPage, setFetchingNextPage] = useState(false);
    console.log("isFetchingNextPage:::::::::::::::::", isFetchingNextPage)
    const [feedChunks, setFeedChunks] = useState([]);
    const [feedsProcessed, setFeedsProcessed] = useState(0);
    const [hasMorePosts, setHasMorePosts] = useState(true);  // Track if there are more posts
    console.log("hasMorePosts:::::::::::::::::", hasMorePosts)
    const feedsScrollUpdate = useSelector((state: RootStateOrAny) => state.feeds.feedsScrollUpdate);
    const { handlerefetch } = useAlerts();
    const dimensions = useWindowDimension();
    const isMobile = dimensions?.width <= 500 || false;
    const fetchProcess = useSelector((state: any) => state.feeds.fetchProcess);

    const { fetchPosts, fetchNewPosts, processedAllPosts } = useFeeds(props);

    // Memoized Loader Component
    const MemoizedLoader = React.memo(() => (
        <Box m={5}>
            <Loader />
        </Box>
    ));

    // Memoized Card Component
    const MemoizedCard = React.memo(Card);

    // Process and chunk the userFeeds
    const processFeeds = useCallback(async () => {
        // Get the current feeds from Redux store
        const currentFeedsInRedux = store.getState().feeds.feeds[0] ?? [];

        // Sort the feeds in descending order based on createDate
        const sortedFeeds = currentFeedsInRedux.sort((a, b) => {
            // Ensure createDate is compared correctly as a date
            return new Date(b.createDate) - new Date(a.createDate);
        });

        const chunkSize = 50;
        const totalFeeds = sortedFeeds.length;
        const remainingFeeds = totalFeeds - feedsProcessed;

        // If there are more posts to process
        if (remainingFeeds > 0) {
            setFetchingNextPage(true);

            // Get the next chunk of posts based on `feedsProcessed`
            const nextChunk = sortedFeeds.slice(feedsProcessed, feedsProcessed + chunkSize);

            // If we have new posts to add
            if (nextChunk.length > 0) {
                setFeedChunks((prevChunks) => {
                    const updatedChunks = [...prevChunks, ...nextChunk];
                    return _.uniqBy(updatedChunks, 'id'); // Remove duplicates based on 'id'
                });
                setFeedsProcessed(feedsProcessed + nextChunk.length);
                setFetchingNextPage(false);
            }
        } else {
            setProcessedAllPostsInRedux(true); // All Redux data processed
        }
    }, [feedsProcessed]);

    // Effect to detect and handle new posts from Redux userFeeds
    // useEffect(() => {
    //     // Listen to changes in userFeeds (from Redux) and append new posts to the top
    //     const currentFeedsInRedux = store.getState().feeds.feeds[0] ?? [];

    //     // Sort the feeds in descending order based on createDate
    //     const sortedFeeds = currentFeedsInRedux.sort((a, b) => {
    //         return new Date(b.createDate) - new Date(a.createDate);
    //     });

    //     // Add new posts to the top (prepend them)
    //     if (sortedFeeds.length > feedChunks.length) {
    //         const newPosts = sortedFeeds.slice(0, sortedFeeds.length - feedChunks.length);
    //         setFeedChunks((prevChunks) => {
    //             const updatedChunks = [...newPosts, ...prevChunks];
    //             return _.uniqBy(updatedChunks, 'id'); // Remove duplicates based on 'id'
    //         });
    //     }
    // }, [userFeeds, showModal]);

    // Function to load more posts (triggering fetchNextPage if needed)
    const loadPosts = async () => {
        const currentFeedsInRedux = store.getState().feeds.feeds[0] ?? [];
        const fetchProcess = store.getState().feeds.fetchProcess[0];
        console.log("fetchProcess:::::::::::::::::", fetchProcess)
        if (!fetchProcess) { // todo: check if we need to fetch posts from server

            // Filter feeds where IsVisible is false
            const filteredFeeds = currentFeedsInRedux.filter(feed => !feed.IsVisible).slice(0, 20);

            if (filteredFeeds.length > 0) {
                if (filteredFeeds.length < 20) {
                    // Call fetchPosts in useFeeds without await
                    fetchPosts(); // This will run in the background
                }
                // Set the first 20 feeds as IsVisible = true
                filteredFeeds.forEach(feed => {
                    feed.IsVisible = true; // Update visibility
                });

                // Update feedChunks with the new filtered feeds
                setFeedChunks(prevChunks => {
                    const updatedChunks = [...prevChunks, ...filteredFeeds];
                    return _.uniqBy(updatedChunks, 'id'); // Remove duplicates based on 'id'
                } );
                if ( props.page === 'Profile' )
                {
                    const newPosts = await fetchPosts();
                    if ( newPosts?.length > 0 )
                    {
                        // Update feedChunks with the new posts
                        setFeedChunks( prevChunks =>
                        {
                            const updatedChunks = [ ...prevChunks, ...newPosts ];
                            return _.uniqBy( updatedChunks, 'id' ); // Remove duplicates based on 'id'
                        } );
                    }
                }
                return; // Exit the function after updating visibility
            } else {
                // If no filtered feeds, call fetchPosts in useFeeds
                const newPosts = await fetchPosts();
                if ( newPosts?.length > 0) {
                    // Update feedChunks with the new posts
                setFeedChunks(prevChunks => {
                    const updatedChunks = [...prevChunks, ...newPosts];
                    return _.uniqBy(updatedChunks, 'id'); // Remove duplicates based on 'id'
                });
                }
                if ( feedChunks?.length == 0 && newPosts?.length == 0 )
                {
                    loadPosts();
                }
            }
        } else {
            setHasMorePosts(false);
        }
    };

    // Function to load new posts
    const loadNewPosts = async () => {
        setIsLoadingNewPosts(true); // Show the loader

        // Fetch new posts using fetchPosts from useFeeds
        const newPosts = await fetchNewPosts(); // Fetch new posts

        // If new posts are returned, prepend them to the feedChunks
        if (newPosts?.length > 0) {
            setFeedChunks(prevChunks => {
                const updatedChunks = [...newPosts, ...prevChunks]; // Prepend new posts
                return _.uniqBy(updatedChunks, 'id'); // Remove duplicates based on 'id'
            });
        }
        initialLoad(); // reset the feeds
        setIsLoadingNewPosts(false); // Hide the loader
    };

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (feedsScrollUpdate || props?.isHuddlePostTab) {
                const currentUrl = window.location.href;
                scrollToTop()
                dispatch(setFeedsScrollUpdate(false));
            }
            return () => {
                isMounted = false; // Set the flag to false when the component is unmounted
                // Cancel subscriptions or perform any cleanup tasks here
            };
        }

    }, [feedsScrollUpdate]);

    // scrollToTop will trigger the loadNewPosts function
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // handlerefetch();
        loadNewPosts();
    };

    const initialLoad = () => {
        const currentFeedsInRedux = store.getState().feeds.feeds[0] ?? [];
        // Set all feeds as IsVisible = false
        const updatedFeeds = currentFeedsInRedux.map(feed => ({
            ...feed,
            IsVisible: false // Set IsVisible to false
        }));
        // Order feeds by createDate
        const sortedFeeds = updatedFeeds.sort((a, b) => new Date(b.createDate) - new Date(a.createDate));
        // Dispatch the updated feeds to Redux
        dispatch(setFeeds(sortedFeeds));

        dispatch(setFetchProcess(false));

        // Reset feed chunks and processed state whenever props change
        setFeedChunks([]);
        setFeedsProcessed(0);
        setProcessedAllPostsInRedux(false);
        setHasMorePosts(true); // Reset hasMorePosts

        // finally call loadPosts
        loadPosts();
    }

    // Effect to process userFeeds on load or when the processed count changes
    useEffect(() => {
        initialLoad();
    }, [props.userInfo, props.page, props.id]);


    // Filter posts if the page is Profile
    const filteredPosts = useMemo(() => {
        if (props?.page === 'Profile' && props?.id) {
            console.log('Render the posts for the occurence', props?.userInfo.series);
            console.log('Feeds Chunk..........', feedChunks.filter((post) => post?.owner?.id === props?.userInfo?.series ? props.userInfo?.series : props?.id));
            if (props?.userInfo?.series) {
                return feedChunks.filter((post) => post?.owner?.id == props?.userInfo?.series);
            }
            let AllFeeds = [ ...userFeeds, ...feedChunks ];
            AllFeeds = _.uniqBy( AllFeeds, 'id' );
            return AllFeeds.filter( ( post ) => post?.owner?.id === props?.id );
        }
        return feedChunks;
    }, [props?.page, props?.id, feedChunks]);

    console.log("filteredPosts length ", filteredPosts?.length)

   
    return (
        <Box>
            {!showModal && IsVisible !== 'N' && (
                <Offline>
                    {({ isOffline }) =>
                        !isOffline && (
                            <Button
                                bgColor="AVColor.primary"
                                borderRadius="full"
                                padding={3}
                                height="45px"
                                width="45px"
                                onPress={() => setShowModal(true)}
                                zIndex={1}
                                position="fixed"
                                bottom={isMobile ? '110px' : '10px'}
                                right={props?.isHuddlePostTab ? (props?.offW ? props.offW - 60 : 580) : undefined}
                                marginLeft={props?.isHuddlePostTab ? undefined : (props?.offW ? props?.offW - 60 : 100)}
                            >
                                <Image source={msg} alt="Create Post" size="25px" />
                            </Button>
                        )
                    }
                </Offline>
            )}

            {/* Skeleton Loader for new posts */}
            { isLoadingNewPosts && filteredPosts.length == 0 && (
                <Box m={5}>
                    {Array(5).fill(null).map((_, i) => <FeedsLoader key={i} />)}
                </Box>
            )}

            <div className="feed-container">
                {filteredPosts.length > 0 && (
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={loadPosts}
                        hasMore={hasMorePosts && !isFetchingNextPage} // Update hasMore logic
                    loader={
                        <Center key={0}>
                            <MemoizedLoader />
                        </Center>
                    }
                    useWindow={true}
                    threshold={200}
                >
                    <div className="posts-list">
                        {filteredPosts.length > 0 &&
                            filteredPosts.map((post, i) => (
                                <div key={`${post?.id}+${i}`}>
                                    <MemoizedCard
                                        key={`${post.id}+${i}`}
                                        iscommentAccess={props?.previewdata?.iscommentAccess}
                                        data={ post }
                                        setFeedChunks={ setFeedChunks }
                                        feedChunks={ feedChunks }
                                        previewdata={ userInfo }
                                        userInfo={props?.page === 'Home' ? currentUser : props?.previewdata?.userInfo}
                                    />
                                </div>
                            ))}
                    </div>
                </InfiniteScroll>
                )}

                {/* Loader for fetching next page */}
                {isFetchingNextPage && filteredPosts.length > 0 && (
                    <Center>
                        <Box m={5}>
                            <Loader />
                        </Box>
                    </Center>
                )}

                { ( filteredPosts.length == 0 && hasMorePosts ) && (
                    <FeedsLoader />
                ) }
                { ( filteredPosts.length == 0 && !hasMorePosts ) && (
                    <div style={ { marginBottom: '-1px', marginTop: '40px', textAlign: 'center', top: '4px', minHeight: '50px' } }>
                        { Loc.currentLang.post.noFeeds }
                    </div>
                ) }
            </div>

            {/* Modal to create a post */}
            {showModal && (
                <CreatePostpopup
                    visibilityPermission={IsVisible}
                    setShowCreatePost={setShowModal}
                    Postdata={ userInfo?.type == "Channel" ? userInfo : props?.Postdata }
                    setFeedChunks={setFeedChunks}
                    feedChunks={feedChunks}
                />
            )}
        </Box>
    );
};

export default React.memo(AVFeeds);
