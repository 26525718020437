import * as React from 'react';
import * as AV from '../AVCore/AVCore';
import { useEffect, useState } from 'react';
import { setIsConvoScreenOpen, setLoginLink, setUser, setuserisguardian, setLastFeedUpdate } from '../Redux/Actions/userActions';
import { setAlerts } from '../Redux/Actions/alertActions';
import { setConvo } from 'Redux/Actions/convoActions';
import { Router, routes } from '../Platform/Routes';
import { PageLoader } from 'components/loaders';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Config } from 'App/Config';
import Moment from 'moment';
import { Alert } from 'AVCore/Alert';
import { useLocation, useNavigate } from 'react-router-dom';
import useInitSSE from 'Hooks/useInitSSE';
import _ from 'lodash';
import ErrorHandlerPopup from '../components/common/ErrorHandlerPopup';
import { setEntityToken } from '../Redux/Actions/userActions';
import { SignalRService } from 'AVCore/SignalR';
import { store } from '../Redux/store';


function Main() {
    console.log("Main.tsx rendering...")
    const { CloseSSE } = useInitSSE();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [Unauthor, setUnauthor] = React.useState(false);
    const useralertList = useSelector((state: RootStateOrAny) => state?.alerts.alert[0]);
    const loginLink = useSelector((state: RootStateOrAny) => state.convo.loginLink);
    const entityToken = useSelector((state: RootStateOrAny) => state?.user.entityToken);
    const userisguardian = useSelector((state: RootStateOrAny) => state?.user.userisguardian);
    const convoval = useSelector((state: RootStateOrAny) => state.convo.convo[0]);
    const deviceFCMToken = useSelector((state: RootStateOrAny) => state.user.devicefcmtoken);
    const lastUpdateFeed = useSelector((state: RootStateOrAny) => state.user.lastUpdateFeed);
    const [isShowfollow, setisShowfollow] = React.useState(true);
    const [isLandingPage, setIsLandingPage] = React.useState(false);
    const [entityLink, setentityLink] = React.useState<any>('');
    const [lastfeed, setlastfeed] = useState<any>([]);
    const user = useSelector((state: RootStateOrAny) => state?.user?.user[0]);
    Moment.locale(user?.preferences?.lang);

    async function init() {
        location.state = '';
        await Config.init();
        await SignalRService.stopSignalRConnection();
        CloseSSE();
        let setConvoScreenOpen = { isOpen: false, convoId: undefined }
        dispatch(setIsConvoScreenOpen(setConvoScreenOpen));

        if (new URLSearchParams(window.location.search) !== null) {
            if (window.location.pathname === '/Invite' || window.location.pathname === 'Activate') {
                if (new URLSearchParams(window.location.search).get('id') !== null) {
                    let id = new URLSearchParams(window.location.search).get('id') || undefined;
                    let region = new URLSearchParams(window.location.search).get('region');
                    let token = new URLSearchParams(window.location.search).get('token') || undefined;
                    let type = new URLSearchParams(window.location.search).get('type') || undefined;
                    let fk: AV.IFK = {
                        id: id,
                        pk: id?.substr(-4),
                        region: region,
                        type: 'Person',
                    };
                    let tokenreq: AV.ITokenRequest = {
                        token: token, fk: fk, type: type
                    }
                    let actaivateperson: any = await AV.AVEntity.processToken(tokenreq);
                    if (actaivateperson?.id) {
                        navigate(`/profile/${actaivateperson?.id}/${actaivateperson?.region}`, {
                            state: {
                                user: {
                                    id: actaivateperson?.id,
                                    pk: actaivateperson?.pk,
                                    region: actaivateperson?.region,
                                    type: actaivateperson?.type,
                                    name: actaivateperson?.name,
                                },
                            },
                        });
                    }
                    else if (actaivateperson?.number === -2146233088) {
                        setUnauthor(true);
                    }
                    navigate('/');
                }
            } else if (window.location.pathname === '/Event') {
                if (new URLSearchParams(window.location.search).get('id') !== null) {
                    let id = new URLSearchParams(window.location.search).get('id');
                    let region = new URLSearchParams(window.location.search).get('region');
                    let fk: AV.IFK = { id: id, type: 'Event', region: region, pk: id?.substr(-4) };
                    navigate(`/Events/${fk?.id}/${fk?.region}`, { state: { eventdata: fk } });
                }
            } else if (window.location.pathname === '/Post') {
                if (new URLSearchParams(window.location.search).get('id') !== null) {
                    let id = new URLSearchParams(window.location.search).get('id');
                    let pk = new URLSearchParams(window.location.search).get('pk');
                    let region = new URLSearchParams(window.location.search).get('region');
                    let fk: AV.IFK = { id: id, pk: pk, region: region, type: 'Post' };
                    navigate('/', { state: { previewdata: fk } });
                }
            } else if (window.location.pathname === '/Convo') {
                if (new URLSearchParams(window.location.search).get('id') !== null) {
                    let id = new URLSearchParams(window.location.search).get('id');
                    let pk = new URLSearchParams(window.location.search).get('pk');
                    let region = new URLSearchParams(window.location.search).get('region');
                    let fk: AV.IFK = { id: id, pk: pk, region: region };
                    navigate('/', { state: { convovalue: fk } });
                }
            }
            else if (window.location.pathname?.startsWith('/Follow')) {
                let token = window.location.pathname.replace(/^\/Follow\//, '');
                let val: any = await AV.AVEntity.getTokenRequest(token, "Follow");
                let returnvalue = await val.json();
                setisShowfollow(true);
                setIsLandingPage(true);
                if (returnvalue?.id) {
                    setentityLink(returnvalue);
                    dispatch(setEntityToken(returnvalue));
                }
                if (token) {
                    let tokenreq: AV.ITokenProcessRequest = {
                        token: token, entity: returnvalue, type: "Follow"
                    }
                    let actaivateperson: any = await AV.AVEntity.processToken(tokenreq);
                    if (actaivateperson?.id) {
                        dispatch(setUser(actaivateperson));
                        handleNavigate(returnvalue);
                        /*navigate('/');*/
                    }
                    else if (actaivateperson?.number === -2146233088) {
                        setUnauthor(true);
                    }
                }
                else {
                    let val: any = await AV.AVEntity.getTokenRequest(token, 'Follow');
                    let returnvalue = await val.json();
                    setisShowfollow(true);
                    //setIsLandingPage(true);
                    if (returnvalue) {
                        setentityLink(returnvalue);
                        dispatch(setEntityToken(returnvalue));
                    }
                }
            }
            else if (window.location.pathname?.startsWith('/Join')) {
                let token = window.location.pathname.replace(/^\/Join\//, '');
                let val: any = await AV.AVEntity.getTokenRequest(token, 'Join');
                let returnvalue = await val.json();
                let tokenreq: AV.ITokenProcessRequest = {
                    token: token, entity: returnvalue, type: "Join"
                }
                let actaivateperson: any = await AV.AVEntity.processToken(tokenreq);
                if (actaivateperson?.id) {
                    dispatch(setUser(actaivateperson));
                    handleNavigate(returnvalue);
                }
                else if (actaivateperson?.number === -2146233088) {
                    setUnauthor(true);
                }
            }
            else if (window.location.pathname?.startsWith('/Invite')) {
                let token = window.location.pathname.replace(/^\/Invite\//, '');
                if (entityToken && entityToken?.length != 0) {
                    let val: any = await AV.AVEntity.getTokenRequest(token, 'Invite');
                    let returnvalue = await val.json();
                    let tokenreq: AV.ITokenProcessRequest = {
                        token: token, entity: returnvalue, type: "Invite"
                    }
                    let actaivateperson: any = await AV.AVEntity.processToken(tokenreq);
                    if (actaivateperson?.id) {
                        dispatch(setUser(actaivateperson));
                        handleNavigate(returnvalue);
                    }
                    else if (actaivateperson?.number === -2146233088) {
                        setUnauthor(true);
                    }
                }
            }
            else if (window.location.pathname?.startsWith('/AddPlayer')) {
                let token = window.location.pathname.replace(/^\/AddPlayer\//, '');
                let val: any = await AV.AVEntity.getTokenRequest(token, 'AddPlayer');
                let returnvalue = await val.json();
                let tokenreq: AV.ITokenProcessRequest = {
                    token: token, entity: returnvalue, type: "AddPlayer"
                }
                let actaivateperson: any = await AV.AVEntity.processToken(tokenreq);
                if (actaivateperson?.id) {
                    dispatch(setUser(actaivateperson));
                    handleNavigate(returnvalue);
                }
                else if (actaivateperson?.number === -2146233088) {
                    setUnauthor(true);
                }
            }

            else if (window.location.pathname?.startsWith('/AddStaff')) {
                let token = window.location.pathname.replace(/^\/AddStaff\//, '');
                let val: any = await AV.AVEntity.getTokenRequest(token, 'AddStaff');
                let returnvalue = await val.json();
                let tokenreq: AV.ITokenProcessRequest = {
                    token: token, entity: returnvalue, type: "AddStaff"
                }
                let actaivateperson: any = await AV.AVEntity.processToken(tokenreq);
                if (actaivateperson?.id) {
                    dispatch(setUser(actaivateperson));
                    handleNavigate(returnvalue);
                }
                else if (actaivateperson?.number === -2146233088) {
                    setUnauthor(true);
                }

            }
            else if (window.location.pathname?.startsWith('/Add')) {
                let token = window.location.pathname.replace(/^\/Add\//, '');
                let val: any = await AV.AVEntity.getTokenRequest(token, "Add");
                let returnvalue = await val.json();
                setisShowfollow(true);
                setIsLandingPage(true);
                if (returnvalue?.id) {
                    setentityLink(returnvalue);
                    dispatch(setEntityToken(returnvalue));
                }
                if (token) {
                    let val: any = await AV.AVEntity.getTokenRequest(token, 'Add');
                    let returnvalue = await val.json();
                    let tokenreq: AV.ITokenProcessRequest = {
                        token: token, entity: returnvalue, type: "Add"
                    }
                    let actaivateperson: any = await AV.AVEntity.processToken(tokenreq);
                    if (actaivateperson?.id) {
                        dispatch(setUser(actaivateperson));
                        handleNavigate(returnvalue);
                        /*navigate('/');*/
                    }
                    else if (actaivateperson?.number === -2146233088) {
                        setUnauthor(true);
                    }
                }
            }
            else if (window.location.pathname?.startsWith('/Activate')) {
                let token = window.location.pathname.replace(/^\/Activate\//, '');
                if (entityToken && entityToken?.length != 0) {
                    let activateType = userisguardian ? "Guardian" : "Player";
                    let tokenreq: AV.ITokenRequest = {
                        token: token, type: activateType
                    }
                    let actaivateperson = await AV.Person.activate(tokenreq);
                    dispatch(setuserisguardian(false));
                    if (actaivateperson?.id) {
                        navigate(`/profile/${actaivateperson?.id}/${actaivateperson?.region}`, {
                            state: {
                                user: {
                                    id: actaivateperson?.id,
                                    pk: actaivateperson?.pk,
                                    region: actaivateperson?.region,
                                    type: actaivateperson?.type,
                                    name: actaivateperson?.name,
                                },
                            },
                        });
                    }
                    else {
                        setUnauthor(true);
                    }
                    navigate('/');
                }
            }
            else if (window.toString().startsWith("/api") || window?.location?.pathname?.startsWith('/api')) {
                navigate('/');
            }
        }
        let person = await AV.Person.getSelf();
        if (person.fcm?.filter(x => x?.token === deviceFCMToken).length === 0 && deviceFCMToken !== '' && deviceFCMToken != undefined && deviceFCMToken?.length > 0) {
            AV.Person.addFCMToken(deviceFCMToken);
        }
        if (person?.wards !== undefined && person?.wards?.length > 0) dispatch(setuserisguardian(true));
        let alertsval = useralertList;
        let lastAlertDate = Alert._startDate == undefined ? new Date(0) : Alert._startDate;
        var alertList: any = await Alert.List(lastAlertDate);
        alertList = alertList?.alerts;
        if (alertsval)
            alertsval = [...alertsval, ...alertList]
        if (alertsval?.length > 0) {
            for (let i = 0; i < alertList?.length; i++) {
                let excitingalerts = alertsval?.filter((x) => x?.id === alertList[i]?.id);
                if (excitingalerts?.length === 0) alertsval.unshift(alertList[i]);
            }
        }
        alertsval = _.uniqBy(alertsval?.length > 0 ? alertsval : alertList, 'id');
        alertsval?.sort(function (a, b) {
            let c: any = new Date(b.date);
            let d: any = new Date(a.date);
            return c - d;
        });
        dispatch(setAlerts(alertsval));
        dispatch(setLoginLink(""));
        if (convoval?.length == 0 || convoval == undefined)
            await getConvo();
        if (person?.status === 'NewUser' || person?.status === 'Deleted' || person?.status === "Suspended") {
            navigate(`/profile/${person?.id}/${person?.region}`, {
                state: {
                    user: {
                        id: person?.id,
                        pk: person?.pk,
                        region: person?.region,
                        type: person?.type,
                        name: person?.name,
                    },
                },
            });
        }


        dispatch(setUser(person));

        setIsLoading(false);
    }

    const handleNavigate = async (item: any) => {
        await SignalRService.stopSignalRConnection();
        {
            item?.type == 'Event'
                ? navigate(`/Events/${item?.id}/${item?.region}`, { state: { team: item } })
                : item?.type == 'LeagueTeam'
                    ? navigate(`/season/${item?.id}/${item?.region}`, { state: { isCreate: false, team: item } }) :
                    item?.type == 'Team' ?
                        navigate(`/teams/${item?.id}/${item?.region}`, { state: { isCreate: false, team: item } }) :
                        (item?.id == 'b495d4e0-bf52-4690-8dc0-677db954aa72' || item?.id == 'b495d4e0-bf52-4690-8dc0-677db954aa71' || item?.id === 'b495d4e0-bf52-4690-8dc0-677db954aa7f') ?
                            navigate(`/teams/${item?.id}/${item?.region}`, {
                                state: { isCreate: false, team: item, type: 'Feedback' },
                            }) :
                            item?.type == 'Person' ?
                                navigate(`/profile/${item.id}/${item.region}`, {
                                    state: {
                                        user: {
                                            id: item?.id,
                                            pk: item?.pk,
                                            region: item?.region,
                                            type: item?.type,
                                            name: item?.title,
                                        },
                                    },
                                }) :
                                navigate(`/organizations/${item?.id}/${item?.region}/${item?.type}`, {
                                    state: { isCreate: false, orgType: item?.type, team: item },
                                })
        }
    }

    const getConvo = async () => {
        let convovalue: AV.IConversation[] = [];
        let convolist;
        let person: AV.IPerson = await AV.Person.getSelf();
        const chunkSize = 10; // Define the chunk size
        const convoChunks: AV.IConvoLink[][] = [];

        // Split person.convos into chunks of 20
        for (let i = 0; i < (person.convos?.length || 0); i += chunkSize) {
            if (person.convos) {
                const chunk = person.convos.slice(i, i + chunkSize);
                convoChunks.push(chunk);
            }
        }


        // Loop through each chunk and call AV.Feed.getFeeds
        for (const chunk of convoChunks) {
            let allPosts: Array<{ id: string, date: string, owner: AV.IFK, ownerType: string | null }> = []; // Array to hold all unique posts

            const feedParams = chunk.map(convo => ({
                feed: convo,
                checkArchive: false,
                startDate: new Date(0), // Adjust as needed
                forward: true
            }));

            try {
                const feedsResponse = await AV.Feed.getAllFeeds(feedParams) as AV.IConversation[];

                //Categorize the convo feeds

                var categorizedFeeds = categorizeConvoFeeds(feedsResponse);

                // from feedresponse, filter feeds not as dormant feeds from categorizedFeeds
                var filteredFeeds: AV.IConversation[] = [
                    ...categorizedFeeds.realtime,
                    ...categorizedFeeds.active,
                    ...categorizedFeeds.intermittent,
                    ...categorizedFeeds.inactive,
                ]

                // Combine all followIds, privateIds, and publicIds for each feed
                filteredFeeds.forEach(feed => {
                    const feedFK = AV.AVEntity.getFk(feed);
                    const currentEntityPostIds = _.uniqBy([
                        ...(feed?.followIds || []),
                        ...(feed?.privateIds || []),
                        ...(feed?.publicIds || [])
                    ], 'id');

                    currentEntityPostIds.forEach(postId => {
                        if (postId.id && postId.date) {
                            allPosts.push({
                                id: postId.id,
                                date: new Date(postId.date ?? new Date()).toISOString(),
                                owner: feedFK,
                                ownerType: feed.type || null // Assuming feed has a type property
                            });
                        } else {
                            console.warn('Post ID or date is missing:', postId);
                        }
                    });
                });

                // Call the common function to process grouped post IDs by owner
                const groupedPostIds = await groupPosts(allPosts);

                // Prepare posts to fetch based on grouped post IDs
                let postsToFetch: AV.IPostListParam[] = [];
                for (const ownerId in groupedPostIds) {
                    const { owner, postIds } = groupedPostIds[ownerId];
                    postsToFetch.push({ feed: owner, ids: postIds.map(post => post.id) });
                }
                if ( postsToFetch?.length > 0 )
                {
                    // Fetch all posts based on the combined list
                    try
                    {
                        const posts = await AV.Feed.getAllPosts( postsToFetch );

                        convolist = feedsResponse.map( feed => ( {
                            ...feed,
                            posts: posts.filter( post => post.owner?.id === feed.id )
                        } ) );

                        convovalue.push( convolist );

                    } catch ( error )
                    {
                        console.error( 'Error fetching posts:', error );
                    }
                }
            } catch (error) {
                console.error('Error fetching feeds:', error);
            }
        }

        dispatch(setConvo(convovalue.flat()));
    };

    const groupPosts = async (allPosts: { id: string; date: string; owner: AV.IFK; ownerType: string | null }[]) => {
        let groupedPostIds: { [key: string]: { owner: any; postIds: { id: string; date: string }[]; ownerType: string } } = {};
        let previousOwnerId = "";

        // Sort all posts by date in descending order
        allPosts.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

        allPosts.forEach(post => {
            const { owner, ownerType } = post;
            const ownerId = owner.id;

            // If the ownerId changes, create a new entry in groupedPostIds
            if (ownerId !== previousOwnerId) {
                if (ownerId && !groupedPostIds[ownerId]) {
                    groupedPostIds[ownerId] = { owner: owner, postIds: [], ownerType: ownerType || '' }; // Set owner to feedFK
                }
                if (ownerId && groupedPostIds[ownerId]) {
                    // Limit to top 20 posts for each owner
                    if (groupedPostIds[ownerId].postIds.length < 20) {
                        groupedPostIds[ownerId].postIds.push({ id: post.id, date: post.date });
                    }
                    previousOwnerId = ownerId; // Update the previousOwnerId
                }
            } else {
                // If the ownerId is the same, just add the postId to the existing owner
                if (ownerId && groupedPostIds[ownerId]) {
                    // Limit to top 20 posts for each owner
                    if (groupedPostIds[ownerId].postIds.length < 20) {
                        groupedPostIds[ownerId].postIds.push({ id: post.id, date: post.date });
                    }
                }
            }
        });

        console.log('Collected allPostIds:', groupedPostIds);
        return groupedPostIds;
    }

      // Function to categorize convo feeds based on lastUpdated
    const categorizeConvoFeeds = (feeds: AV.IConversation[]) => {
        const now = new Date();
        const categorizedFeeds = {
            realtime: [],
            active: [],
            intermittent: [],
            inactive: [],
            dormant: [],
        };

        feeds.forEach(feed => {
            if (feed && feed.id) { // Check if feed is defined and has an id
                let category = '';           
                const lastUpdated = new Date(feed.lastUpdated ?? new Date());
                const timeDiff = now.getTime() - lastUpdated.getTime(); // Calculate the time difference in milliseconds
                const hoursDiff = timeDiff / (1000 * 60 * 60); // Convert time difference to hours
                const daysDiff = timeDiff / (1000 * 60 * 60 * 24); // Convert time difference to days

                // Categorize feeds based on the time difference
                if (hoursDiff < 1) {
                    category = 'realtime';
                    (categorizedFeeds.realtime as AV.IConversation[]).push(feed); // Feeds updated within the last hour
                } else if (hoursDiff < 24) {
                    category = 'active';
                    (categorizedFeeds.active as AV.IConversation[]) .push(feed); // Feeds updated within the last 24 hours
                } else if (daysDiff < 7) {
                    category = 'intermittent';
                    (categorizedFeeds.intermittent as AV.IConversation[]) .push(feed); // Feeds updated within the last 7 days
                } else if (daysDiff < 30) {
                    category = 'inactive';
                    (categorizedFeeds.inactive as AV.IConversation[]) .push(feed); // Feeds updated within the last 30 days
                } else {
                    category = 'dormant';
                    (categorizedFeeds.dormant as AV.IConversation[]) .push(feed); // Feeds not updated for more than 30 days
                }
            } else {
                console.warn('Feed is undefined or missing ID:', feed);
            }
        });
        
        return categorizedFeeds;
    };

    useEffect(() => {
        setIsLoading(true);
        async function fetchData() {
            await init();
        }
        fetchData();
    }, [loginLink]);

    useEffect(() => {
        async function fetchData() {
            dispatch(setLastFeedUpdate(lastUpdateFeed));
        }
        fetchData();
    }, [lastUpdateFeed]);

    return <>{isLoading ? <PageLoader /> : Unauthor ? <ErrorHandlerPopup /> : <Router routes={routes} />}</>;
}

export default React.memo(Main);
